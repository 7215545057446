import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { Link, Typography } from '@mui/material';

import { useResetPasswordSendSMS } from './hook';
import { TextInput } from '../../components/forms/textInput';
import { Button } from '../../components/generic/Button';
import { AuthFormContainer } from '../components/styled';

export const ResetPasswordForm = ({
  successCallback,
}: {
  successCallback: ({ newPassword }: { newPassword: string }) => void;
}) => {
  const translate = useTranslate();
  const sendSMS = useResetPasswordSendSMS(successCallback);
  const { handleSubmit, control, formState } = useForm({
    defaultValues: { newPassword: '' },
  });
  const { isSubmitting } = formState;

  return (
    <>
      <form onSubmit={handleSubmit(sendSMS)}>
        <AuthFormContainer>
          <TextInput
            autoFocus
            control={control}
            name="newPassword"
            type="password"
            label={translate('auth.resetPassword.newPassword')}
            helperText={translate('auth.passwordConstraints')}
            rules={{
              required: true,
              minLength: 8,
              pattern: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            }}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            loading={isSubmitting}
            value={translate('auth.resetPassword.validateNew')}
            fullWidth
            sx={{ marginTop: '2rem' }}
          />
        </AuthFormContainer>
      </form>
      <Typography sx={{ textAlign: 'center', margin: '0.25rem' }}>
        <Link
          href="/#/login"
          sx={{
            fontSize: '0.75rem',
            cursor: 'pointer',
          }}
          underline="hover"
        >
          {translate('auth.backToLogin')}
        </Link>
      </Typography>
    </>
  );
};
