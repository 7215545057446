import {
  BooleanInput,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from 'react-admin';

import { FormProps } from '../../types';

export const CompanyPlanForm = (props: FormProps) => {
  const translate = useTranslate();
  return (
    <SimpleForm {...props}>
      <TextInput
        source="alias"
        validate={required()}
        variant="standard"
        label={translate('companyPlan.alias')}
      />
      <TextInput
        source="description"
        variant="standard"
        label={translate('common.description')}
      />
      <BooleanInput
        source="renewable"
        defaultValue={true}
        variant="standard"
        label={translate('companyPlan.renewable')}
      />
      <BooleanInput
        source="active"
        defaultValue={true}
        variant="standard"
        label={translate('common.active')}
      />
      <NumberInput
        label={translate('companyPlan.maxUsage')}
        source="maxUsage"
        defaultValue={-1}
        min={-1}
        step={1}
        variant="standard"
      />
      <ReferenceInput
        source="plan.id"
        reference="plans"
        label={translate('plan.name')}
      >
        <SelectInput
          optionText="name"
          variant="standard"
          validate={required()}
          label={translate('plan.name')}
        />
      </ReferenceInput>
    </SimpleForm>
  );
};
