import { Node, ReactFlowState } from 'reactflow';

export const getPreviousQuestionsForQuestionOrTemplateNode = (
  store: ReactFlowState,
  self: Node,
) => {
  const steps = store.getNodes().filter((node) => node.type === 'step');
  const selfStep = steps.find((step) => step.id === self?.parentId);
  const selfStepYPos =
    selfStep?.positionAbsolute.y + (Number(selfStep.style?.height) ?? 0) / 2;

  return store
    .getNodes()
    .filter(
      (node) =>
        node.type === 'question' &&
        node.id !== self.id &&
        node.positionAbsolute.y < self.positionAbsolute.y, // under the current question cannot be a previous question
    )
    .filter((node) => {
      if (node.parentId === self?.parentId) {
        return node.positionAbsolute.y < self.positionAbsolute.y;
      }
      const otherStep = steps.find((step) => step.id === node.parentId);
      const otherStepYPos =
        otherStep?.positionAbsolute.y +
        (Number(otherStep.style?.height) ?? 0) / 2;

      return otherStepYPos < selfStepYPos - 10;
    })
    .map((node) => node.data.question);
};

export const getPreviousQuestionsForStepNode = (
  store: ReactFlowState,
  self: Node,
) => {
  const steps = store.getNodes().filter((node) => node.type === 'step');
  const selfYPos =
    self?.positionAbsolute.y + (Number(self?.style?.height) ?? 0) / 2;

  return store
    .getNodes()
    .filter(
      (node) => node.type === 'question' && node.positionAbsolute.y < selfYPos, // under the current question cannot be a previous question
    )
    .filter((node) => {
      const otherStep = steps.find((step) => step.id === node.parentId);
      const otherStepYPos =
        otherStep?.positionAbsolute.y +
        (Number(otherStep.style?.height) ?? 0) / 2;

      return otherStepYPos - 10 < selfYPos;
    })
    .map((node) => node.data.question);
};
