import { useMemo } from 'react';
import { Identifier, useGetList, useTranslate } from 'react-admin';
import { useParams } from 'react-router';

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  useAllAvailabilities,
  useAllPreferences,
  usePlanningAttributions,
  usePlanningDetail,
} from '../../../hooks/plannings';
import { useByIds } from '../../../hooks/useByIds';
import { User } from '../../../types';
import { PlanningCounts, PlanningPreferences } from '../../../types/planning';
import {
  getAttributionsCount,
  implicatedUsers,
} from '../../../utils/planningUtils';

const fullName = (user: User) => `${user.firstName} ${user.lastName}`;

export const PlanningSummary = () => {
  const translate = useTranslate();
  const { planningId } = useParams();
  const { data: planning, isLoading } = usePlanningDetail(planningId);
  const { data: attributions = [], isLoading: isLoadingAttributions } =
    usePlanningAttributions(planningId);
  const { data: availabilities, isLoading: isLoadingAvailabilities } =
    useAllAvailabilities(planningId);
  const { data: staffUsers, isLoading: isLoadingStaffUsers } = useGetList<User>(
    'users',
    { pagination: { page: 1, perPage: 1000 } },
  );
  const { data: preferences, isLoading: isLoadingPreferences } =
    useAllPreferences(planningId);

  const staffUsersById = useByIds(staffUsers, String);
  const slotsById = useByIds(planning?.slots);
  const preferencesByStaffUserId = useMemo<
    Record<Identifier, PlanningPreferences>
  >(() => {
    if (!preferences) {
      return {};
    }
    return preferences.reduce(
      (acc, preference) => ({
        ...acc,
        [preference.staffUserId]: preference,
      }),
      {} as Record<Identifier, PlanningPreferences>,
    );
  }, [preferences]);

  const summary = useMemo<
    { staffUser: User; attribs: PlanningCounts }[]
  >(() => {
    if (!planning || !attributions || !availabilities || !staffUsers) {
      return [];
    }
    const implicatedUserIds = implicatedUsers({
      planning,
      availabilities,
      attributions,
    });
    const countByUser = getAttributionsCount(attributions, slotsById);

    return implicatedUserIds
      .map((userId) => ({
        staffUser: staffUsersById[userId],
        attribs: {
          count: countByUser[userId]?.count ?? 0,
          onCall: countByUser[userId]?.onCall ?? 0,
          weekend: countByUser[userId]?.weekend ?? 0,
          weekendNb: countByUser[userId]?.weekendNb ?? 0,
          nights: countByUser[userId]?.nights ?? 0,
          maxNightsPerWeek: countByUser[userId]?.maxNightsPerWeek ?? 0,
          maxCountPerWeek: countByUser[userId]?.maxCountPerWeek ?? 0,
          nightsPerWeek: countByUser[userId]?.nightsPerWeek ?? {},
          countsPerWeek: countByUser[userId]?.countsPerWeek ?? {},
          countsWeekend: countByUser[userId]?.countsWeekend ?? {},
        },
      }))
      .sort(({ staffUser: a }, { staffUser: b }) =>
        fullName(a).localeCompare(fullName(b)),
      );
  }, [
    planning,
    attributions,
    availabilities,
    staffUsers,
    slotsById,
    staffUsersById,
  ]);

  if (
    isLoading ||
    isLoadingAttributions ||
    !planning ||
    !attributions ||
    isLoadingStaffUsers ||
    !staffUsers ||
    isLoadingAvailabilities ||
    !availabilities ||
    isLoadingPreferences ||
    !preferences
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{translate('planning.summary.pro')}</TableCell>
          <TableCell>{translate('planning.summary.nbMin')}</TableCell>
          <TableCell>{translate('planning.summary.nbSlots')}</TableCell>
          <TableCell>{translate('planning.summary.nbOnCalls')}</TableCell>
          <TableCell>{translate('planning.summary.nbMaxPerWeek')}</TableCell>
          <TableCell>{translate('planning.summary.weekend')}</TableCell>
          <TableCell>{translate('planning.summary.maxNightPerWeek')}</TableCell>
          <TableCell>{translate('planning.summary.nbNight')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {summary.map(({ staffUser, attribs }) => {
          const userPreferences = preferencesByStaffUserId[staffUser.id];
          const maxCount = userPreferences?.maximumPerPlanning
            ? ` (${userPreferences?.maximumPerPlanning})`
            : '';
          const maxWeekend = userPreferences?.maximumSlotsOnWeekends
            ? ` (${userPreferences?.maximumSlotsOnWeekends})`
            : '';
          const maxNights = userPreferences?.maximumNightsPerWeek
            ? ` (${userPreferences?.maximumNightsPerWeek})`
            : '';
          const maxWeeks = userPreferences?.maximumPerWeek
            ? ` (${userPreferences?.maximumPerWeek})`
            : '';
          const weekendDisplay = attribs.weekend
            ? `${attribs.weekend} sur ${attribs.weekendNb} wkd`
            : '0';
          return (
            <TableRow key={staffUser.id}>
              <TableCell>{fullName(staffUser)}</TableCell>
              <TableCell>
                {userPreferences?.minimumSlotsWanted ?? 'N/A'}
              </TableCell>
              <TableCell>{`${attribs.count}${maxCount}`}</TableCell>
              <TableCell>{`${attribs.onCall}`}</TableCell>
              <TableCell>{`${attribs.maxCountPerWeek}${maxWeeks}`}</TableCell>
              <TableCell>{`${weekendDisplay}${maxWeekend}`}</TableCell>
              <TableCell>{`${attribs.maxNightsPerWeek}${maxNights}`}</TableCell>
              <TableCell>{`${attribs.nights}`}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
