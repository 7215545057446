import { useRecordContext } from 'react-admin';

import { Typography } from '@mui/material';

import { Consultation } from '../../types';

export const ConsultationMedia = () => {
  const record = useRecordContext<Consultation>();
  return (
    <Typography component="span">{record.phoneOnly ? '📞' : '🎥'}</Typography>
  );
};
