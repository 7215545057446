import { Create, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { AffiliationsForm } from './form';

export const AffiliationsCreate = () => {
  const location = useLocation();
  const translate = useTranslate();
  return (
    <Create
      resource="affiliations"
      title={translate('affiliations.create')}
      redirect={location?.state?.redirect || 'list'}
    >
      <AffiliationsForm
        defaultValues={{
          patientId: Number(location?.state?.record?.patientId ?? 0),
        }}
      />
    </Create>
  );
};
