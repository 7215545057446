import {
  BooleanInput,
  NumberInput,
  TextInput,
  SimpleForm,
  required,
  number,
  useTranslate,
} from 'react-admin';

import { integer } from '../../utils/validators';

export const PlanForm = (props) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <TextInput
        source="name"
        validate={required()}
        variant="standard"
        label={translate('common.name')}
      />
      <TextInput
        source="description"
        validate={required()}
        variant="standard"
        label={translate('common.description')}
      />
      <TextInput
        source="accessLevel"
        validate={required()}
        variant="standard"
        label={translate('plan.accessLevel')}
      />
      <NumberInput
        source="consultationsNumber"
        defaultValue={0}
        variant="standard"
        validate={[required(), number(), integer()]}
        label={translate('plan.consultationsNumber')}
      />
      <NumberInput
        source="monthsNumber"
        defaultValue={12}
        variant="standard"
        label={translate('plan.monthsNumber')}
      />
      <BooleanInput
        source="active"
        defaultValue={true}
        variant="standard"
        label={translate('common.active')}
      />
    </SimpleForm>
  );
};
