import { useMemo } from 'react';
import { useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';

import { useQuery } from './queryWrappers';
import { useTherapyDetails } from '../entities/consultation/components/therapy.hook';
import { ConsultationWithRelationIds, Discussion } from '../types';

export const useConsultation = () => {
  const { id } = useParams<'id'>();
  const { data: consultation, isLoading: loadingConsultation } =
    useGetOne<ConsultationWithRelationIds>(
      'consultations',
      {
        id: Number(id),
      },
      { enabled: Boolean(id) },
    );

  const { data: relatedDiscussion, isLoading: loadingDiscussion } =
    useQuery<Discussion>(
      ['childDiscussion', consultation?.childId],
      {
        url: `/api/discussions/fromKid/${consultation?.childId}`,
        method: 'GET',
      },
      { enabled: Boolean(consultation?.childId) },
    );

  const { data: therapy } = useTherapyDetails(
    consultation?.therapyItem?.therapySlug,
  );

  // form id -> get id from formAnswers
  // formAnswers id
  const { formAnswersId, formSlug } = useMemo(() => {
    const forms = therapy?.forms;
    if (!forms) {
      return { formSlug: undefined, formAnswersId: undefined };
    }
    return {
      formSlug: forms?.find((f) => f.type === 'onboarding')?.slug,
      formAnswersId: consultation?.therapyItem?.onboardingAnswersId,
    };
  }, [consultation, therapy]);

  return {
    consultation,
    therapy,
    formSlug,
    formAnswersId,
    relatedDiscussion,
    loading: loadingConsultation || loadingDiscussion,
  };
};
