import 'dayjs/locale/fr';
import {
  useRef,
  useCallback,
  ComponentProps,
  useEffect,
  useState,
  ElementRef,
} from 'react';
import { Identifier, useNotify } from 'react-admin';
import { View } from 'react-native';

import { AppUser } from '@boTypes/appUser';
import { DiscussionEvent, DiscussionEventType } from '@boTypes/discussionEvent';
import { ChatMessage } from '@boTypes/messages';
import {
  UnAttributedChatInput,
  UnCategorizedChatInput,
} from '@components/discussions/chat/AlternateChatInput';
import { useDeleteMessage, useMessageQuery } from '@hooks/discussionEvents';
import {
  useSubjectAttributions,
  useWaitingAttributions,
} from '@hooks/subjectAttribution';
import { useAttributionUI } from '@hooks/useAttributionUI';
import {
  IFormMessage,
  IMediaMessage,
  ISystemMessage,
  ITextMessage,
} from '@teammay/mayssenger';

import audioFile from '../../components/discussions/chat/assets/sounds/notification.mp3';
import { useSelector } from '../../store';
import { Discussion, Subject } from '../../types';
import { Socket } from '../../types/socket';
import { ChatInput } from '../discussions/chat/chatInput';
import { ContextMenu, MenuOptions } from '../discussions/chat/contextMenu';
import { ContextMenuType, MessageList } from '../discussions/chat/messageList';
import { ScrollToBottom } from '../discussions/chat/scrollToBottom';

const audio = new Audio(audioFile);

const messageToText = (message: ChatMessage) => {
  switch (message.type) {
    case 'text':
      return (message as ITextMessage).text;
    case 'system':
      return (message as ISystemMessage).text;
    case 'media':
      return (message as IMediaMessage).media[0].uri;
    default:
      return '';
  }
};

export const MayContextMenu = ({
  discussionId,
  readonly,
  ...props
}: {
  discussionId: Identifier;
  readonly: boolean;
} & ComponentProps<ContextMenuType>) => {
  const notify = useNotify();
  const { mutate: deleteMessage } = useDeleteMessage(discussionId);
  const handleClick = useCallback(
    (message: ChatMessage, option: MenuOptions) => {
      switch (option) {
        case MenuOptions.COPY:
          navigator.clipboard.writeText(messageToText(message));
          break;
        case MenuOptions.DELETE:
          deleteMessage(message.messageId, {
            onError: (error) => {
              notify('common.error.withArgs', {
                type: 'error',
                messageArgs: { error: error.message },
              });
            },
          });
          break;
        default:
          break;
      }
    },
    [deleteMessage, notify],
  );
  return (
    <ContextMenu readonly={readonly} {...props} onItemPressed={handleClick} />
  );
};

const Input = ({
  discussion,
  lastAppUserName,
  onSend,
}: {
  discussion: Discussion & {
    addDiscussionEvent: (arg: { event: DiscussionEvent }) => void;
    updateSubject: (arg0: { subject: Subject }) => void;
  };
  lastAppUserName: string;
  onSend?: (arg: { event: DiscussionEvent }) => void;
}) => {
  const attributionUI = useAttributionUI();
  const { data: waitingSubject } = useWaitingAttributions();
  const { data: attributions } = useSubjectAttributions();
  const staffUserId = useSelector((state) => state.user.userId);
  const staffIdForSubject =
    attributionUI && !discussion.lastSubject?.empty // if last subject is empty, we are in a revive case
      ? attributions?.staffUserFromSubjectId?.[discussion.lastSubjectId]
      : staffUserId; // inhibit log of attribution taken in the send button

  const handleSend = useCallback(
    (arg: { event: DiscussionEvent }) => {
      discussion.addDiscussionEvent(arg);
      onSend?.(arg);
    },
    [discussion, onSend],
  );
  if (discussion.lastSubject && !discussion.lastSubject.empty) {
    if (!discussion.lastSubject?.categoryId) {
      return (
        <UnCategorizedChatInput
          subject={discussion.lastSubject}
          onCategoryChanged={(subject) => discussion.updateSubject({ subject })}
        />
      );
    }
    if (attributionUI && waitingSubject?.includes(discussion.lastSubjectId)) {
      return <UnAttributedChatInput subject={discussion.lastSubject} />;
    }
  }

  // display chat input if last subject is empty => revive case
  // display chat input if category and staff attribution is set
  return (
    <ChatInput
      discussion={discussion}
      lastAppUserName={lastAppUserName}
      onSend={handleSend}
      staffIdForSubject={staffIdForSubject}
    />
  );
};

export const DiscussionEditChat = ({
  discussion,
  socket,
  onFormPress,
}: {
  discussion: Discussion & {
    addDiscussionEvent: (arg: { event: DiscussionEvent }) => void;
    updateSubject: (arg0: { subject: Subject }) => void;
  };
  socket?: Socket;
  onFormPress?: (message: Pick<IFormMessage, 'form' | 'formAnswers'>) => void;
}) => {
  const {
    messages,
    participants,
    fetchNextPage: getOlderMessages,
    hasNextPage: hasOlderMessages,
    isLoading,
    isFetchingNextPage: loadingPrevious,
  } = useMessageQuery(discussion, socket);

  const chatListRef = useRef<ElementRef<typeof MessageList>>(null);
  const lastMessage = useRef<ChatMessage>(null);
  const [lastAppUserName, setLastAppUserName] = useState<string>('');
  const userId = useSelector((state) => state.user.email);
  const handleScrollToBottom = useRef(() => {
    chatListRef.current.scrollToOffset({ offset: 0 });
  });
  useEffect(() => {
    if (messages?.length) {
      const lastAppUserEmail = messages.find(
        (msg) => !participants[msg.participantId]?.isMay,
      )?.participantId;
      setLastAppUserName(
        (discussion.appUsers as AppUser[])?.find(
          (au: AppUser) => au?.email === lastAppUserEmail,
        )?.firstName ?? '',
      );
      if (lastMessage.current) {
        if (messages[0].messageId === lastMessage.current.messageId) {
          return;
        }
        if (
          messages[0].participantId !== userId &&
          messages[0].type !== DiscussionEventType.SYSTEM &&
          messages[0].type !== DiscussionEventType.TYPING &&
          !participants[messages[0].participantId]?.isMay
        ) {
          lastMessage.current = messages[0];
          audio.play().catch(() => {});
        }
      } else {
        lastMessage.current = messages[0];
      }
    }
  }, [messages, discussion.appUsers, participants, userId]);

  const ChatContextMenu = useCallback(
    (props: ComponentProps<ContextMenuType>) => {
      return (
        <MayContextMenu
          readonly={false}
          {...props}
          discussionId={discussion?.id}
        />
      );
    },
    [discussion?.id],
  );

  return (
    <View style={{ flex: 1 }}>
      <MessageList
        ref={chatListRef}
        discussionId={discussion.id}
        messages={messages}
        participants={participants}
        isLoading={isLoading}
        isLoadingMore={loadingPrevious}
        hasMore={hasOlderMessages}
        onEndReached={getOlderMessages}
        scrollToBottomOffset={20}
        ContextMenu={ChatContextMenu}
        ScrollToBottom={ScrollToBottom}
        onFormPress={onFormPress}
      />
      <Input
        discussion={discussion}
        lastAppUserName={lastAppUserName}
        onSend={handleScrollToBottom.current}
      />
    </View>
  );
};
