import { Fragment } from 'react';
import {
  BooleanField,
  Datagrid,
  Edit,
  Identifier,
  List,
  TextField,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';

import { CreateButton } from '@components/generic/CreateButton';
import { EditButton } from '@components/generic/EditButton';
import { Box, Paper } from '@mui/material';

import { CompanyPlanForm } from './form';

const CompanyPlanCodeCreateButton = ({
  companyPlanId,
}: {
  companyPlanId: Identifier;
}) => {
  const record = { companyPlan: { id: companyPlanId } };
  const redirect = `/company-plans/${companyPlanId}`;

  return (
    <CreateButton
      ctaText="companyPlanCode.create"
      record={record}
      redirect={redirect}
    />
  );
};

const CompanyPlanCodesActions = ({
  companyPlanId,
}: {
  companyPlanId: Identifier;
}) => (
  <TopToolbar>
    <CompanyPlanCodeCreateButton companyPlanId={companyPlanId} />
  </TopToolbar>
);

const Empty = ({ companyPlanId }: { companyPlanId: Identifier }) => (
  <Box m={1}>
    <CompanyPlanCodeCreateButton companyPlanId={companyPlanId} />
  </Box>
);

export const CompanyPlanEdit = () => {
  const location = useLocation();
  const translate = useTranslate();
  const { id } = useParams<'id'>();

  return (
    <Paper sx={{ padding: '0 1rem' }} elevation={0}>
      <Edit
        title={<Fragment />}
        resource="company-plans"
        redirect={location?.state?.redirect || 'list'}
      >
        <CompanyPlanForm />
      </Edit>

      <List
        resource="company-plan-codes"
        exporter={false}
        perPage={50}
        empty={<Empty companyPlanId={id} />}
        filter={{ companyPlan: { id } }}
        actions={<CompanyPlanCodesActions companyPlanId={id} />}
        title={<Fragment />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="name" label={translate('common.name')} />
          <TextField source="code" label={translate('companyPlan.code')} />
          <BooleanField source="active" label={translate('common.active')} />
          <EditButton />
        </Datagrid>
      </List>
    </Paper>
  );
};
