import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useGetList, useNotify, useTranslate } from 'react-admin';
import { useParams } from 'react-router';

import { StaffUser } from '@boTypes/staffUser';
import { titleToPlanningJob } from '@boTypes/user';
import { Cancel, MoreTime } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { AdditionalHourDialogWithPlanning } from './addAdditionalHourDialog';
import { Loader } from '../../../components/Loader';
import {
  useAdditionalHoursMutations,
  usePlanningAdditionalHours,
} from '../../../hooks/plannings';
import { useByIds } from '../../../hooks/useByIds';
import { useSelector } from '../../../store';
import { User } from '../../../types';
import { Planning, PlanningAdditionalHours } from '../../../types/planning';

const getDefaultSelection = (additionalHours: PlanningAdditionalHours[]) =>
  additionalHours.reduce(
    (acc, additionalHour) => {
      acc[additionalHour.id] = false;
      return acc;
    },
    {} as Record<PlanningAdditionalHours['id'], boolean>,
  );

const notValidatedFirst = (
  a: PlanningAdditionalHours,
  b: PlanningAdditionalHours,
) => {
  if (a.validatedById && !b.validatedById) {
    return 1;
  }
  if (!a.validatedById && b.validatedById) {
    return -1;
  }
  return 0;
};

const sortByStaffUser = (
  a: PlanningAdditionalHours & { staffUser: StaffUser },
  b: PlanningAdditionalHours & { staffUser: StaffUser },
) => {
  if (!a.staffUser?.fullName || !b.staffUser?.fullName) {
    if (a.staffUser?.fullName) {
      return -1;
    }
    if (b.staffUser?.fullName) {
      return 1;
    }
    return 0;
  }
  return a.staffUser.fullName.localeCompare(b.staffUser.fullName);
};

const sortByDate = (a: PlanningAdditionalHours, b: PlanningAdditionalHours) => {
  if (a.start < b.start) {
    return 1;
  }
  if (a.start > b.start) {
    return -1;
  }
  return 0;
};

const sortAdditionalHours = (
  a: PlanningAdditionalHours & { staffUser: StaffUser },
  b: PlanningAdditionalHours & { staffUser: StaffUser },
) => {
  return notValidatedFirst(a, b) || sortByStaffUser(a, b) || sortByDate(a, b);
};

export const PlanningHoursValidation = ({ job }: { job?: Planning['job'] }) => {
  const translate = useTranslate();
  const { planningId } = useParams();
  const [loading, setLoading] = useState(false);
  const [addHoursDialogOpened, setAddHoursDialogOpened] = useState(false);
  const closeAddHoursDialog = useCallback(
    () => setAddHoursDialogOpened(false),
    [],
  );
  const notify = useNotify();
  const [selected, setSelected] = useState(
    {} as Record<PlanningAdditionalHours['id'], boolean>,
  );
  const {
    data: additionalHours = [],
    isLoading: isLoadingAdditionalHours,
    setCache: setAdditionalHours,
    refetch,
  } = usePlanningAdditionalHours(planningId);

  const { data: _staffUsers, isLoading: isLoadingStaffUsers } =
    useGetList<User>('users', { pagination: { page: 1, perPage: 1000 } });

  const staffUsers = useMemo(() => {
    return sortBy(
      _staffUsers?.filter(
        ({ jobTitle, active }) =>
          active && titleToPlanningJob[jobTitle] === job,
      ),
      (u) => u.fullName.toLowerCase(),
    );
  }, [_staffUsers, job]);

  const staffUsersById = useByIds(staffUsers, String);

  const sortedAdditionalHours = useMemo(
    () =>
      (additionalHours ?? [])
        .map((a) => ({ ...a, staffUser: staffUsersById[a.staffUserId] }))
        .sort(sortAdditionalHours),
    [additionalHours, staffUsersById],
  );

  const { updateAdditionalHourAsync, deleteAdditionalHour } =
    useAdditionalHoursMutations();
  const userId = useSelector((state) => state.user.userId);

  const validate = useCallback(() => {
    setLoading(true);
    const toUpdate = additionalHours.filter(({ id }) => selected[id]);
    const promises = toUpdate.map((additionalHour) =>
      updateAdditionalHourAsync({
        ...additionalHour,
        planningId,
        validatedById: selected[additionalHour.id] ? userId : -1,
      }),
    );
    Promise.all(promises)
      .then(() => {
        refetch();
        setLoading(false);
        setSelected(getDefaultSelection(additionalHours));
        notify('additionalHours.hoursValidated', { type: 'success' });
      })
      .catch(() => {
        notify('common.error.validate', { type: 'warning' });
        setLoading(false);
      });
  }, [
    additionalHours,
    notify,
    planningId,
    refetch,
    selected,
    updateAdditionalHourAsync,
    userId,
  ]);

  const invalidate = useCallback(() => {
    setLoading(true);
    const toUpdate = additionalHours.filter(({ id }) => selected[id]);
    const promises = toUpdate.map((additionalHour) =>
      updateAdditionalHourAsync({
        ...additionalHour,
        planningId,
        validatedById: -1,
      }),
    );
    Promise.all(promises)
      .then(() => {
        refetch();
        setLoading(false);
        setSelected(getDefaultSelection(additionalHours));
        notify('additionalHours.hoursInvalidated', { type: 'success' });
      })
      .catch(() => {
        notify('common.error.validate', { type: 'warning' });
        setLoading(false);
      });
  }, [
    additionalHours,
    notify,
    planningId,
    refetch,
    selected,
    updateAdditionalHourAsync,
  ]);

  const remove = useCallback(() => {
    setLoading(true);
    const toUpdate = additionalHours.filter(({ id }) => selected[id]);
    const promises = toUpdate.map((additionalHour) =>
      deleteAdditionalHour({
        planningId,
        id: additionalHour.id,
      }),
    );
    Promise.all(promises)
      .then(() => {
        refetch();
        setLoading(false);
        setSelected(getDefaultSelection(additionalHours));
        notify('additionalHours.hoursDeleted', { type: 'success' });
      })
      .catch(() => {
        notify('common.error.delete', { type: 'warning' });
        setLoading(false);
      });
  }, [
    additionalHours,
    deleteAdditionalHour,
    notify,
    planningId,
    refetch,
    selected,
  ]);

  if (isLoadingAdditionalHours || isLoadingStaffUsers) {
    return <Loader />;
  }

  const countSelected = Object.values(selected).filter(Boolean).length;

  return (
    <Box sx={{ height: 'calc(100% - 70px)' }}>
      <AdditionalHourDialogWithPlanning
        staffUsers={staffUsers}
        isLoadingStaffUsers={isLoadingStaffUsers}
        open={addHoursDialogOpened}
        onClose={closeAddHoursDialog}
        onCreated={(data) => {
          setAdditionalHours((previousAdditionalHours) => {
            return previousAdditionalHours
              ? [...previousAdditionalHours, data]
              : [data];
          });
          closeAddHoursDialog();
        }}
        planningId={planningId}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ paddingBottom: 1, paddingTop: 1 }}>
            {countSelected
              ? translate('additionalHours.selectedRows', {
                  smart_count: countSelected,
                })
              : translate('additionalHours.emptySelection')}
          </Typography>
          {Boolean(countSelected) && (
            <>
              <Button
                sx={{ mx: 1 }}
                variant="outlined"
                onClick={() => validate()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={'small'} />
                ) : (
                  translate('additionalHours.validateHours')
                )}
              </Button>
              <Button
                sx={{ mx: 1 }}
                variant="outlined"
                onClick={() => invalidate()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={'small'} />
                ) : (
                  translate('additionalHours.invalidateHours')
                )}
              </Button>
              <Button
                sx={{ mx: 1 }}
                variant="outlined"
                onClick={() => remove()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={'small'} />
                ) : (
                  translate('additionalHours.deleteHours')
                )}
              </Button>
              <Button
                sx={{ mx: 1 }}
                onClick={() =>
                  setSelected(getDefaultSelection(additionalHours))
                }
                color="inherit"
                disabled={loading}
                startIcon={<Cancel />}
              >
                {translate('additionalHours.cancelSelection')}
              </Button>
            </>
          )}
        </Box>

        <Button
          onClick={() => setAddHoursDialogOpened(true)}
          startIcon={<MoreTime />}
        >
          {translate('additionalHours.add')}
        </Button>
      </Box>
      <TableContainer
        sx={{ maxHeight: 'calc(100% - 30px)', position: 'relative' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={additionalHours.every(
                    (additionalHour) => selected[additionalHour.id],
                  )}
                  onChange={(_, checked) =>
                    setSelected(
                      additionalHours.reduce(
                        (acc, additionalHour) => {
                          acc[additionalHour.id] = checked;
                          return acc;
                        },
                        {} as Record<PlanningAdditionalHours['id'], boolean>,
                      ),
                    )
                  }
                  size="small"
                />
              </TableCell>
              <TableCell>{translate('common.pro')}</TableCell>
              <TableCell>{translate('common.day')}</TableCell>
              <TableCell>{translate('additionalHours.startHour')}</TableCell>
              <TableCell>{translate('additionalHours.duration')}</TableCell>
              <TableCell>{translate('additionalHours.comment')}</TableCell>
              <TableCell>{translate('additionalHours.validated')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAdditionalHours.map((additionalHour) => {
              return (
                <TableRow
                  key={`${additionalHour.id}-${selected[additionalHour.id]}`}
                >
                  <TableCell>
                    <Checkbox
                      checked={selected[additionalHour.id]}
                      onChange={(_, checked) =>
                        setSelected((prev) => ({
                          ...prev,
                          [additionalHour.id]: checked,
                        }))
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {staffUsersById[additionalHour.staffUserId]?.fullName}
                  </TableCell>
                  <TableCell>
                    {dayjs(additionalHour.start).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {dayjs(additionalHour.start).format('HH:mm')}
                  </TableCell>
                  <TableCell>
                    {dayjs(additionalHour.end).diff(
                      dayjs(additionalHour.start),
                      'minutes',
                    )}
                  </TableCell>
                  <TableCell>{additionalHour.comment || '-'}</TableCell>
                  <TableCell>
                    {additionalHour.validatedById ? '✅' : '❌'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
