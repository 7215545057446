import React, { useState, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const computeAppert = (weight: number) =>
  // Appert method: milk quantity = 250 + "weight in g" / 10
  250 + weight / 10;

export const AppertDialog = React.memo<Props>(({ open, setOpen }: Props) => {
  const [weight, setWeight] = useState(3000);
  const [nbBottles, setNbBottles] = useState(4);
  const [milk, setMilk] = useState(550);
  const [bottleQuantity, setBottleQuantity] = useState(137);
  const translate = useTranslate();

  const close = useCallback(() => setOpen(false), [setOpen]);

  const updateWeight = useCallback(
    (event) => {
      const newWeight = event.target.value;
      setWeight(newWeight);
      setMilk(computeAppert(newWeight));
    },
    [setMilk, setWeight],
  );

  const updateNbBottles = useCallback(
    (event) => {
      setNbBottles(event.target.value);
    },
    [setNbBottles],
  );

  useEffect(() => {
    setBottleQuantity(Math.round(milk / nbBottles));
  }, [setBottleQuantity, milk, nbBottles]);

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby={translate('tools.appert.title')}
      aria-describedby={translate('tools.appert.description')}
      maxWidth="md"
    >
      <DialogTitle onClose={close}>
        {translate('tools.appert.description')}
      </DialogTitle>
      <DialogContent>
        <div>
          <TextField
            label={translate('tools.appert.childWeight')}
            inputProps={{ type: 'number', step: 10 }}
            value={weight}
            onChange={updateWeight}
          />
        </div>
        <div>
          <TextField
            label={translate('tools.appert.babyBottles')}
            inputProps={{
              type: 'number',
              step: 1,
              min: 4,
              max: 8,
            }}
            value={nbBottles}
            onChange={updateNbBottles}
          />
        </div>
        <Typography sx={{ marginTop: '1rem' }}>
          {translate('tools.appert.quantityRecommended', { milk })}
        </Typography>
        <Typography>
          {translate('tools.appert.quantityInBottles', { bottleQuantity })}
        </Typography>

        <Typography variant="caption">
          {translate('tools.appert.formula')}
        </Typography>
      </DialogContent>
    </Dialog>
  );
});
