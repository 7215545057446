import cloneDeep from 'lodash/cloneDeep';

import { Condition, Question, ScoringRule } from '@teammay/form-core';

import { i18nProvider } from '../../../App';

export const copyCondition = ({
  id: _id,
  children,
  dataRules,
  questionRules,
  ...condition
}: Condition): Condition => {
  const conditionId = self.crypto.randomUUID();
  const newChildren = children.map((c) => copyCondition(c));
  const newDataRules = dataRules?.map((dr) => ({
    ...dr,
    id: self.crypto.randomUUID(),
    conditionId,
  }));
  const newQuestionRules = questionRules?.map((qr) => ({
    ...qr,
    id: self.crypto.randomUUID(),
    conditionId,
  }));
  return {
    ...cloneDeep(condition),
    id: conditionId,
    children: newChildren,
    dataRules: newDataRules,
    questionRules: newQuestionRules,
  };
};

export const copyScoringRule = (
  scoringRule: ScoringRule,
  questionId: string,
) => {
  return {
    ...scoringRule,
    id: self.crypto.randomUUID(),
    questionId,
  };
};

export const copyQuestion = (
  {
    id: _id,
    stepId: _stepId,
    order: _order,
    hideCondition,
    scoringRules,
    ...question
  }: Question,
  stepId: string,
): Question => {
  const questionId = self.crypto.randomUUID();
  const newHideCondition = hideCondition ? copyCondition(hideCondition) : null;
  const newScoringRules = scoringRules?.map((rule) =>
    copyScoringRule(rule, questionId),
  );
  return {
    ...cloneDeep(question),
    id: questionId,
    stepId,
    hideCondition: newHideCondition,
    scoringRules: newScoringRules,
    order: 0,
    title: `${question.title} (${i18nProvider.translate('forms.questions.copy.copy')})`,
    // @ts-expect-error conditionId is not in type but sometimes exist. Not a problem we override it just in case
    conditionId: question.conditionId ? newHideCondition?.id : null,
  };
};
