import { ResourceDefinition } from 'react-admin';

import {
  ChildCare,
  DateRange,
  FindInPage,
  LocalHospital,
  MenuBook,
  People,
  Videocam,
  Settings,
} from '@mui/icons-material';

import MidwifeChat from './assets/midwife_chat_icon';
import NurseChat from './assets/nurse_chat_icon';
import affiliations from './entities/affiliations';
import appBundles from './entities/appBundles';
import appBundlesTesters from './entities/appBundlesTesters';
import categories from './entities/categories';
import children from './entities/children';
import companies from './entities/companies';
import companyPlanCodes from './entities/companyPlanCodes';
import companyPlans from './entities/companyPlans';
import consultations from './entities/consultation';
import consultationCategories from './entities/consultationCategories';
import patients from './entities/patients';
import plans from './entities/plans';
import pregnancies from './entities/pregnancies';
import pricingProCustom from './entities/pricingProCustom';
import pricingPro from './entities/pricingProGeneric';
import scenarios from './entities/scenarios';
import users from './entities/users';

export const Resources = [
  {
    name: 'app-bundles',
    crud: appBundles,
  },
  {
    name: 'app-bundle-testers',
    crud: appBundlesTesters,
  },
  {
    name: 'consultations',
    icon: Videocam,
    options: { label: 'consultations.title' },
    crud: consultations,
  },
  {
    icon: DateRange,
    name: 'calendars',
    options: {
      label: 'calendars.title',
    },
  },
  {
    name: 'appUsers',
  },
  {
    name: 'patients',
    icon: People,
    options: { label: 'patients.title' },
    crud: patients,
  },
  {
    name: 'children',
    icon: ChildCare,
    options: { label: 'children.title' },
    crud: children,
  },
  {
    name: 'pregnancies',
    crud: pregnancies,
  },
  {
    name: 'users',
    icon: LocalHospital,
    options: { label: 'staffUser.title' },
    crud: users,
  },
  {
    name: 'scenarios',
    crud: scenarios,
  },
  {
    name: 'categories',
    crud: categories,
  },
  {
    name: 'consultation-categories',
    crud: consultationCategories,
  },
  { name: 'profiles' },
  { name: 'close' },
  { name: 'open' },
  { name: 'subjects' },
  { name: 'handovers' },
  { name: 'patient_profile' },
  { name: 'kid_profile' },
  { name: 'discussions/explorer' },
  {
    name: 'companies',
    crud: companies,
  },
  {
    name: 'pricing-pro-generic',
    crud: pricingPro,
  },
  {
    name: 'pricing-pro-custom',
    crud: pricingProCustom,
  },
  { name: 'company-plans', crud: companyPlans },
  { name: 'company-plan-codes', crud: companyPlanCodes },
  {
    name: 'plans',
    crud: plans,
  },
  { name: 'affiliations', crud: affiliations },
  { name: 'family' },
  { name: 'roles' },
  { name: 'medical-follow-ups' },
  { name: 'subscriptions' },
  {
    name: 'library',
    icon: MenuBook,
    options: { label: 'library.title' },
  },
  {
    name: 'subjects-explorer',
    icon: FindInPage,
    options: { label: 'subjectExplorer.title' },
  },
  {
    name: 'admin',
    icon: Settings,
    options: { label: 'admin.title' },
  },
];

export const additionalPath = [
  {
    name: 'nursesubjects',
    options: { label: 'nurseSubjects.title' },
  },
  {
    name: 'midwifesubjects',
    options: { label: 'midwifeSubjects.title' },
  },
];

export const customResources: ResourceDefinition[] = [
  {
    icon: NurseChat,
    name: 'nursesubjects',
    options: {
      label: 'nurseSubjects.title',
    },
  },
  {
    icon: MidwifeChat,
    name: 'midwifesubjects',
    options: {
      label: 'midwifeSubjects.title',
    },
  },
];
