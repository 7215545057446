import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useTranslate,
  TopToolbar,
  TextInput,
} from 'react-admin';

import { CreateButton } from '@components/generic/CreateButton';

import { i18nProvider } from '../../App';

const AppBundleTestersToolbar = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const getAppBundleTestersFilters = () => [
  <TextInput
    source="email"
    alwaysOn
    variant="standard"
    label={i18nProvider.translate('common.email')}
  />,
];

export const AppBundleTestersList = () => {
  const translate = useTranslate();
  return (
    <List
      perPage={10}
      sx={{ padding: '0 1rem' }}
      actions={<AppBundleTestersToolbar />}
      filters={getAppBundleTestersFilters()}
      filterDefaultValues={{}}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="appUserId" />
        <ReferenceField
          source="appUserId"
          reference="patients"
          label={translate('common.email')}
        >
          <TextField source="email" label={translate('common.email')} />
        </ReferenceField>
        <ReferenceField
          source="appUserId"
          reference="patients"
          label={translate('common.firstName')}
        >
          <TextField source="firstName" label={translate('common.firstName')} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
