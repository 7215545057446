import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const newGuideDisplaySlice = createSlice({
  name: 'newGuideDisplay',
  initialState: { display: false },
  reducers: {
    setDisplay(state, { payload: display }: PayloadAction<boolean>) {
      return { display };
    },
  },
});

export const { setDisplay } = newGuideDisplaySlice.actions;

export default newGuideDisplaySlice.reducer;
