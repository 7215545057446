import React from 'react';
import { Loading } from 'react-admin';
import Carousel from 'react-material-ui-carousel';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardContent,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import { GuideRenderer } from '@components/GuideRenderer';
import { guideTheme } from '@components/GuideRenderer/resources/guideTheme';
import { useHandleLongPress } from '@hooks/useHandleLongPress';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { FormControl, FormControlLabel, styled, Switch } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';

import { logContentView } from '../../analytics/events';
import { useGuideItem } from '../../hooks/cms';
import { useSelector } from '../../store';
import { setDisplay } from '../../store/newGuideDisplay';
import { COLORS } from '../../themes';
import { Guide } from '../../types';
import { SendIcon } from '../discussions/SendIcon';

export const WIDTH = 148;
export const HEIGHT = 96;

const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  '&:first-of-type': {
    padding: 0,
  },
  backgroundColor: COLORS.WHITE,
});

const StyledPaper = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  height: `calc(100vh - 96px)`,
  maxHeight: '600px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledImage = styled('img')({
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  alignSelf: 'center',
});

interface GuideCarouselProps {
  slug: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const guideWidth = 390;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: guideWidth,
  },
  rendererWrapper: {
    height: 780,
  },
});

const borderWidth = 5;

const mobileStyles = StyleSheet.create({
  mobileContainer: {
    alignItems: 'center',
    width: guideWidth + 2 * borderWidth,
    height: 800,
    borderRadius: 50,
    borderColor: 'black',
    borderWidth,
    borderStyle: 'solid',
    overflow: 'hidden',
    alignSelf: 'center',
    margin: 5,
    display: 'flex',
  },
});

export const GuideCarousel = ({ slug, open, setOpen }: GuideCarouselProps) => {
  const { data, isLoading } = useGuideItem(slug);
  const dispatch = useDispatch();

  const newDisplay = useSelector((state) => state.newGuideDisplay.display);

  const pages = React.useMemo(
    () => GuideRenderer.splitPages(data?.guideContent),
    [data?.guideContent],
  );
  const themeType = data?.theme ?? 'theme1';

  const RenderItem = React.useCallback(
    ({ item, index }: { item: string; index: number }) => {
      return (
        <View style={styles.rendererWrapper}>
          <GuideRenderer.Memo
            content={item}
            themeType={themeType}
            pageNumber={index + 1}
            pagesCount={pages.length}
            width={guideWidth}
          />
        </View>
      );
    },
    [pages.length, themeType],
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <FormControl
        component="fieldset"
        variant="standard"
        sx={{ padding: '0 1rem' }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={newDisplay}
              onChange={() => dispatch(setDisplay(!newDisplay))}
              name="newDisplay"
            />
          }
          label="Afficher la version markdown (WIP)"
        />
      </FormControl>
      <StyledDialogContent>
        {isLoading ? (
          <Loading />
        ) : newDisplay ? (
          <View style={mobileStyles.mobileContainer}>
            <FlatList
              style={[
                styles.container,
                {
                  backgroundColor: pages?.length
                    ? guideTheme[themeType].background
                    : undefined,
                },
              ]}
              data={pages}
              renderItem={RenderItem}
              horizontal
              pagingEnabled
              snapToInterval={guideWidth}
              decelerationRate="fast"
              showsHorizontalScrollIndicator={false}
              ListEmptyComponent={<Text style={{ padding: 50 }}>Empty</Text>}
            />
          </View>
        ) : (
          <Carousel
            autoPlay={false}
            className={'carousel'}
            navButtonsAlwaysVisible={data?.files.length > 1}
            indicatorContainerProps={{
              className: 'carouselIndicatorContainer',
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: COLORS.TEXT,
              },
            }}
            indicatorIconButtonProps={{
              style: {
                color: COLORS.GREY_LAYOUT,
              },
            }}
            animation="slide"
          >
            {(data?.files ?? []).map((file, index) => (
              <StyledPaper key={index}>
                <StyledImage src={file.url} />
              </StyledPaper>
            ))}
          </Carousel>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};

export const GuideItem = ({
  item,
  onSend,
  onLongPress,
}: {
  item: Guide;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
  ) => void;
  onLongPress?: () => void;
}) => {
  const { title } = item;
  const [open, setOpen] = React.useState(false);
  const onClick = () => {
    logContentView('guide', item.id);
    setOpen(true);
  };

  const itemInfo = {
    type: DiscussionEventType.GUIDE,
    content: item.slug,
  };
  const [handlePress, startClick] = useHandleLongPress(onClick, onLongPress);
  return (
    <CMSCard
      width={WIDTH}
      height={HEIGHT}
      elevation={0}
      sx={{ backgroundColor: COLORS.BLUE_DARK['500'] }}
    >
      {open ? (
        <GuideCarousel open={open} setOpen={setOpen} slug={item.slug} />
      ) : null}
      <CMSCardActionArea
        onClick={handlePress}
        onMouseDown={startClick}
        height={HEIGHT}
        sx={{ justifyContent: 'center', alignItems: 'center' }}
      >
        {item.sent && <CMSCardSentOverlay />}
        <CMSCardContent
          sx={{
            color: `${COLORS.WHITE_TEXT}`,
            backgroundColor: COLORS.BLUE_DARK['500'],
          }}
        >
          <CMSCardTitle variant="subtitle2" component="h2" clamp={4}>
            {title}
          </CMSCardTitle>
          {item.sent && (
            <DownloadDoneIcon
              sx={(theme) => ({
                position: 'absolute',
                left: theme.spacing(1),
                bottom: theme.spacing(0),
              })}
            />
          )}
        </CMSCardContent>
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0, position: 'relative', zIndex: 20 }}>
          <SendIcon
            onClick={() => onSend(itemInfo, DiscussionEventType.GUIDE, item.id)}
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};

export default GuideItem;
