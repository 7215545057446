import { useRef } from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { DialogButton } from '@components/DialogButton/DialogButton';
import { SwitchInput } from '@components/forms/switchInput';
import { Button } from '@components/generic/Button';
import { DialogTitle } from '@components/generic/Dialog';
import {
  FormSettingsUpdate,
  useFormDelete,
  useFormDetailUpdate,
  useFormUpdate,
} from '@hooks/form-builder';
import { useQueryClient } from '@hooks/queryWrappers';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from '@mui/material';

import { SelectInput } from '../../../components/forms/selectInput';
import { TextInput } from '../../../components/forms/textInput';

export const FormCreateForm = ({
  successCallback,
}: {
  successCallback: () => void;
}) => {
  const navigate = useNavigate();
  const defaultId = useRef(self.crypto.randomUUID()).current;
  const defaultFirstStep = useRef({
    id: self.crypto.randomUUID(),
    title: 'First Step',
    links: [],
    formId: defaultId,
  }).current;
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      id: defaultId,
      title: 'New Form',
      slug: 'new-form',
      version: '1.0.0',
      description: '',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      firstStep: defaultFirstStep,
      steps: [defaultFirstStep],
      status: 'draft' as const,
      illustration: null,
      questionCount: 0,
      sentInChat: false,
    },
  });

  const { mutateAsync: create } = useFormDetailUpdate({ isNew: true });

  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    data.questionCount = Number(data.questionCount);
    await create({ form: data, questions: [], templates: [] });
    successCallback();
    navigate(`/forms/${defaultId}`);
  });

  const translate = useTranslate();

  return (
    <>
      <DialogContent sx={{ minWidth: 500 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'stretch',
            justifyContent: 'center',
            p: 0,
          }}
        >
          <TextInput
            control={control}
            name="title"
            label={translate('forms.settings.titleField')}
          />
          <TextInput
            control={control}
            name="description"
            label={translate('forms.settings.description')}
          />
          <TextInput
            control={control}
            name="slug"
            label={translate('forms.settings.slug')}
          />
          <TextInput
            control={control}
            name="illustration"
            label={translate('forms.settings.illustration')}
          />
          <SwitchInput
            control={control}
            name="sentInChat"
            label={translate('forms.settings.sentInChat')}
          />
          <SelectInput
            control={control}
            name="status"
            label={translate('forms.settings.status')}
            variant={'outlined'}
          >
            {['draft', 'published', 'archived'].map((status) => (
              <MenuItem value={status} key={status}>
                {status}
              </MenuItem>
            ))}
          </SelectInput>
          <TextInput
            control={control}
            name="questionCount"
            type="number"
            label={translate('forms.settings.questionCount')}
            helperText={translate('forms.settings.questionCountHelper')}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mx: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          loading={isSubmitting}
          value={translate('common.save')}
          onClick={onSubmit}
        />
      </DialogActions>
    </>
  );
};

export const FormSettingsForm = ({
  form,
  onSave,
  onCancel,
  onDelete,
}: {
  form: FormSettingsUpdate;
  onSave: (form: FormSettingsUpdate) => Promise<any>;
  onCancel: () => any;
  onDelete: (formId: string) => any;
}) => {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: form,
  });

  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    if (Object.keys(data).some((key) => data[key] !== form[key])) {
      await onSave(data);
    } else {
      onCancel();
    }
  });

  const translate = useTranslate();

  return (
    <>
      <DialogContent sx={{ minWidth: 500 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'stretch',
            justifyContent: 'center',
            p: 0,
          }}
        >
          <TextInput
            control={control}
            name="title"
            label={translate('forms.settings.titleField')}
          />
          <TextInput
            control={control}
            name="description"
            label={translate('forms.settings.description')}
          />
          <TextInput
            control={control}
            name="slug"
            label={translate('forms.settings.slug')}
          />
          <TextInput
            control={control}
            name="illustration"
            label={translate('forms.settings.illustration')}
          />
          <SwitchInput
            control={control}
            name="sentInChat"
            label={translate('forms.settings.sentInChat')}
          />
          <SelectInput
            control={control}
            name="status"
            label={translate('forms.settings.status')}
            variant={'outlined'}
          >
            {['draft', 'published', 'archived'].map((status) => (
              <MenuItem value={status} key={status}>
                {status}
              </MenuItem>
            ))}
          </SelectInput>
          <TextInput
            control={control}
            name="questionCount"
            type="number"
            label={translate('forms.settings.questionCount')}
            helperText={translate('forms.settings.questionCountHelper')}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mx: 2,
        }}
      >
        <DialogButton
          title={translate('forms.settings.deleteTitle')}
          text={translate('forms.settings.deleteText')}
          disabled={!!isSubmitting}
          onClick={() => onDelete(form.id)}
          color="error"
        >
          {translate('common.delete')}
        </DialogButton>
        <Button
          variant="contained"
          color="primary"
          loading={isSubmitting}
          value={translate('common.save')}
          onClick={onSubmit}
        />
      </DialogActions>
    </>
  );
};

export const FormSettingsModal = ({
  form,
  onClose,
}: {
  form?: FormSettingsUpdate | null;
  onClose: () => void;
}) => {
  const translate = useTranslate();
  const { mutateAsync: update } = useFormUpdate();
  const { mutateAsync: onDelete } = useFormDelete();
  const queryClient = useQueryClient();

  return (
    <Dialog open={!!form} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('forms.edit.title')}
      </DialogTitle>
      {form && (
        <FormSettingsForm
          form={form}
          onSave={async ({
            id,
            description,
            illustration,
            slug,
            status,
            title,
            version,
            sentInChat,
            questionCount,
          }: FormSettingsUpdate) => {
            await update({
              id,
              description,
              illustration,
              slug,
              status,
              title,
              version,
              sentInChat,
              questionCount: Number(questionCount),
            });
            onClose();
          }}
          onCancel={() => onClose()}
          onDelete={() =>
            onDelete(form?.id, {
              onSuccess: () => {
                onClose();
                queryClient.invalidateQueries({ queryKey: ['forms'] });
              },
            })
          }
        />
      )}
    </Dialog>
  );
};

export const FormCreateModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const translate = useTranslate();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('forms.edit.title')}
      </DialogTitle>
      <FormCreateForm successCallback={onClose} />
    </Dialog>
  );
};
