import { useCMSQuery } from '@hooks/queryWrappers';

import { TherapyDetails } from './therapy.dto';
import { cleanupSession } from './therapy.utils';

export const useTherapyDetails = (slug: string) => {
  return useCMSQuery<TherapyDetails>(
    ['therapyDetails', slug],
    {
      url: `/therapy/inner/${slug}`,
      method: 'GET',
    },
    {
      enabled: Boolean(slug),
      select: (data) => {
        const res = { ...data };
        res.sessions = cleanupSession(data.sessions, data.defaultFlags ?? []);
        return res;
      },
    },
  );
};
