import { useContext, useMemo } from 'react';
import { useTranslate } from 'react-admin';

import { ATTRIBUTION_OPTIONS_ENUM } from '@boTypes/subject';
import { useSubjectAttributions } from '@hooks/subjectAttribution';
import { useActiveTab } from '@hooks/subjects';
import { useDiscussionContext } from '@hooks/useDiscussionContext';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { Tooltip, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';

import { AttributedFilter } from './AttributionFilters';
import { ColoredBadge } from './coloredBadge';
import { attributionFilters, getLongestWaitingTime } from './hooks';
import { StyledIconButton } from './styledIconButton';
import { DiscussionContext } from '../../common';
import { CountDiscussionContext } from '../../pages/subjects';
import { useSelector } from '../../store';
import { COLORS } from '../../themes';
import { Discussion } from '../../types';
import {
  AttributionFilter,
  DiscussionQuickFilter,
} from '../../types/discussion';
import { conditionalColor } from '../../utils/conditionalColor';

const NurseBadge = ColoredBadge(COLORS.BLUE['300']);
const ZenBadge = ColoredBadge(COLORS.GREEN['400']);
const UncategorizedBadge = ColoredBadge(COLORS.ORANGE);

const AttributionFilterDisplay: Record<
  ATTRIBUTION_OPTIONS_ENUM,
  (DiscussionQuickFilter | AttributionFilter)[]
> = {
  [ATTRIBUTION_OPTIONS_ENUM.MY_QUESTIONS]: [
    DiscussionQuickFilter.DOCTOR,
    DiscussionQuickFilter.ZEN,
    DiscussionQuickFilter.PRIORITY,
    AttributionFilter.ATTRIBUTED,
  ],
  [ATTRIBUTION_OPTIONS_ENUM.NOT_ATTRIBUTED]: [
    DiscussionQuickFilter.DOCTOR,
    DiscussionQuickFilter.UNCATEGORIZED_AND_ZEN,
    DiscussionQuickFilter.PRIORITY,
  ],
  [ATTRIBUTION_OPTIONS_ENUM.REVIVE]: [],
  [ATTRIBUTION_OPTIONS_ENUM.WITH_DOCTOR_INTERVENTION]: [
    DiscussionQuickFilter.PRIORITY,
  ],
  [ATTRIBUTION_OPTIONS_ENUM.CLOSED]: [],
};

const FilterWrapperBox = styled(Box)({
  height: '40px',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const FilterBlocks = styled(Box)({
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const waitingTimeColor = (waitingTime: number) => {
  if (waitingTime > 60) {
    return COLORS.PINK['500'];
  } else if (waitingTime > 30) {
    return COLORS.ORANGE;
  } else {
    return COLORS.GREY_TEXT_LIGHT;
  }
};

export const AttributionUIDiscussionMainFilters = ({
  discussions = [],
  allDiscussions = [],
  onFilterClick,
  filterValue,
  attribFilterValue,
  onAttribFilterClick,
}: {
  discussions: Discussion[];
  allDiscussions: Discussion[];
  filterValue: DiscussionQuickFilter;
  onFilterClick: (filter: DiscussionQuickFilter) => void;
  attribFilterValue: AttributionFilter;
  onAttribFilterClick: (filter: AttributionFilter) => void;
}) => {
  const discussionContext = useDiscussionContext();
  const translate = useTranslate();
  const userId = useSelector((state) => state.user?.userId);
  const { data } = useSubjectAttributions();

  const { nurseInterventionCount, uncategorizedCount, zenCount } = useContext(
    CountDiscussionContext,
  );

  const myObjects = useMemo(
    () =>
      discussions.filter(
        attributionFilters[AttributionFilter.MINE](
          Number(userId),
          data?.staffUserFromSubjectId ?? {},
        ),
      ),
    [data?.staffUserFromSubjectId, discussions, userId],
  );

  const { longestWaitingTime, longestWaitingTimeAgo } =
    getLongestWaitingTime(allDiscussions);

  const {
    longestWaitingTime: longestWaitingTimeMine,
    longestWaitingTimeAgo: longestWaitingTimeAgoMine,
  } = getLongestWaitingTime(myObjects);

  const color = waitingTimeColor(longestWaitingTime);
  const colorMine = waitingTimeColor(longestWaitingTimeMine);

  const tab = useActiveTab();
  const displayedFilters = AttributionFilterDisplay[tab] ?? [];

  return (
    <FilterWrapperBox>
      <FilterBlocks>
        {nurseInterventionCount > 0 && (
          <Tooltip title={translate('subject.midwifeQuestionsTooltip')}>
            <StyledIconButton
              onClick={() =>
                onFilterClick(
                  discussionContext === DiscussionContext.MIDWIFE
                    ? DiscussionQuickFilter.NURSE
                    : DiscussionQuickFilter.MIDWIFE,
                )
              }
            >
              <NurseBadge badgeContent={nurseInterventionCount}>
                {discussionContext === DiscussionContext.MIDWIFE ? (
                  <ChildCareIcon
                    sx={{
                      color: conditionalColor(
                        filterValue === DiscussionQuickFilter.NURSE,
                      ),
                    }}
                  />
                ) : (
                  <PregnantWomanIcon
                    sx={{
                      color: conditionalColor(
                        filterValue === DiscussionQuickFilter.MIDWIFE,
                      ),
                    }}
                  />
                )}
              </NurseBadge>
            </StyledIconButton>
          </Tooltip>
        )}
        {(Boolean(longestWaitingTime) || true) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: colorMine,
                fontSize: '0.625rem',
                marginLeft: '0.5rem',
              }}
            >
              {translate('common.waitingTimeMine')}
              {' : '}
              {longestWaitingTimeAgoMine || '-'}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: color, fontSize: '0.625rem', marginLeft: '0.5rem' }}
            >
              {translate('common.waitingTime')}
              {' : '}
              {longestWaitingTimeAgo || '-'}
            </Typography>
          </Box>
        )}
      </FilterBlocks>
      <FilterBlocks>
        {displayedFilters.includes(DiscussionQuickFilter.ZEN) && (
          <Tooltip
            title={
              <Box sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
                {translate('subject.zenTooltip')}
              </Box>
            }
          >
            <StyledIconButton
              onClick={() => onFilterClick(DiscussionQuickFilter.ZEN)}
            >
              <ZenBadge badgeContent={zenCount}>
                <SelfImprovementIcon
                  sx={{
                    color: conditionalColor(
                      filterValue === DiscussionQuickFilter.ZEN,
                    ),
                  }}
                />
              </ZenBadge>
            </StyledIconButton>
          </Tooltip>
        )}
        {displayedFilters.includes(
          DiscussionQuickFilter.UNCATEGORIZED_AND_ZEN,
        ) && (
          <Tooltip title={translate('subject.ongoingQuestions')}>
            <StyledIconButton
              sx={{ height: '2rem', width: '2rem' }}
              onClick={() =>
                onFilterClick(DiscussionQuickFilter.UNCATEGORIZED_AND_ZEN)
              }
            >
              <LabelOffIcon
                sx={{
                  color: conditionalColor(
                    filterValue === DiscussionQuickFilter.UNCATEGORIZED_AND_ZEN,
                  ),
                  fontSize: '1rem',
                  top: '0.25rem',
                  left: '0.25rem',
                  position: 'absolute',
                }}
              />
              <SelfImprovementIcon
                sx={{
                  color: conditionalColor(
                    filterValue === DiscussionQuickFilter.UNCATEGORIZED_AND_ZEN,
                  ),
                  fontSize: '1rem',
                  bottom: '0.25rem',
                  right: '0.25rem',
                  position: 'absolute',
                }}
              />
            </StyledIconButton>
          </Tooltip>
        )}
        {displayedFilters.includes(DiscussionQuickFilter.UNCATEGORIZED) && (
          <Tooltip title={translate('subject.noCategory')}>
            <StyledIconButton
              onClick={() => onFilterClick(DiscussionQuickFilter.UNCATEGORIZED)}
            >
              <UncategorizedBadge badgeContent={uncategorizedCount}>
                <LabelOffIcon
                  sx={{
                    color: conditionalColor(
                      filterValue === DiscussionQuickFilter.UNCATEGORIZED,
                    ),
                  }}
                />
              </UncategorizedBadge>
            </StyledIconButton>
          </Tooltip>
        )}
        {displayedFilters.includes(AttributionFilter.ATTRIBUTED) && (
          <AttributedFilter
            filterValue={attribFilterValue}
            onFilterClick={onAttribFilterClick}
            activatedFilter={AttributionFilter.MINE}
          />
        )}
      </FilterBlocks>
    </FilterWrapperBox>
  );
};
