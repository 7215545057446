import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Control, useForm, useWatch } from 'react-hook-form';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { Discussion } from '@boTypes/discussion';
import {
  CMS_CONTENT_TYPES,
  DiscussionEvent,
  DiscussionEventType,
} from '@boTypes/discussionEvent';
import { HandoverRevive } from '@boTypes/handover';
import { Slot } from '@boTypes/slot';
import { JobTitleToString } from '@boTypes/user';
import { usePostAndCloseRevive } from '@hooks/discussionEvents';
import { useMyCurrentSlot } from '@hooks/plannings';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SendIcon from '@mui/icons-material/Send';
import { Box } from '@mui/material';
import { dayjsTz } from '@utils/date';

import { ChatActions } from './chatActions';
import { useSendTypingEvent } from './typingIndicator';
import {
  logClickOnGreetings,
  logSentChatMessage,
  logTookSubjectFromUnattributed,
} from '../../../analytics/events';
import { i18nProvider } from '../../../App';
import { StartSessionButton } from '../../../Layout/SessionManagement/components';
import { useSelector } from '../../../store';
import { setDiscussionMessage } from '../../../store/chatMemory';
import { setMacroSuggestion } from '../../../store/macroSuggestion';
import { User as StoreUser } from '../../../store/user';
import { COLORS } from '../../../themes';
import { TextInput } from '../../forms/textInput';
import { PressableOpacity } from '../../PressableOpacity';

export const inputContainerHeight = 196;
interface FormValues {
  text: string;
  revive: HandoverRevive | null;
}

const getHourStringWithRangeLimit = (
  date?: Date,
  minRange: number = 8,
  maxRange: number = 22,
) => {
  if (!date) {
    return '';
  }
  const dateTz = dayjsTz(date);
  const hour = dateTz.hour();
  if (hour < minRange) {
    return '';
  } else if (hour >= maxRange) {
    return `${maxRange}h`;
  } else {
    const result = dateTz.format('HH[h]mm');
    // when hour ends with 00, we remove the minutes
    return result.endsWith('h00') ? result.slice(0, -2) : result;
  }
};

const getIntroductionMessage = (
  currentText: string,
  user: StoreUser,
  appUserName: string,
  slot?: Slot,
) => {
  const isEvening = dayjsTz().hour() >= 18;
  const endHour = getHourStringWithRangeLimit(slot?.end);
  const greetings = isEvening
    ? i18nProvider.translate('chat.greetingsNight')
    : i18nProvider.translate('chat.greetings');
  const firstName = appUserName
    ? ` ${appUserName[0].toUpperCase() + appUserName.slice(1).toLowerCase()}`
    : '';
  const greet = `${greetings}${firstName},`;
  return [
    currentText,
    greet,
    i18nProvider.translate('chat.iAm'),
    `${user.firstName} ${user.lastName},`,
    i18nProvider.translate(JobTitleToString[user.jobTitle]).toLowerCase(),
    i18nProvider.translate('chat.endIntroductionMessage'),
    endHour ? `${endHour}.\n` : '',
  ]
    .filter(Boolean)
    .join(' ')
    .trim();
};

const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: COLORS.GREY_LAYOUT,
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'stretch',
  },
  container: {
    width: '100%',
    flexDirection: 'column',
    height: inputContainerHeight,
    alignItems: 'stretch',
  },
  typingContainer: {
    width: '100%',
    height: 16,
  },
  sendButton: {
    alignItems: 'center',
    borderRadius: 18,
    height: 36,
    justifyContent: 'center',
    paddingTop: 1,
    width: 36,
    alignSelf: 'center',
  },
});

const SendButton = React.memo(
  ({
    onPress,
    disabled = false,
    isSending = false,
    control,
  }: {
    onPress: () => void;
    control: Control<FormValues>;
    disabled?: boolean;
    isSending?: boolean;
  }) => {
    const revive = useWatch<FormValues>({ control, name: 'revive' });
    const Icon = revive ? ScheduleSendIcon : SendIcon;
    return (
      <>
        <PressableOpacity
          disabled={disabled}
          onPress={onPress}
          style={styles.sendButton}
        >
          {isSending ? (
            <ActivityIndicator color={COLORS.GREY} />
          ) : (
            <Icon
              sx={{ color: disabled ? COLORS.GREY : COLORS.GREEN['500'] }}
            />
          )}
        </PressableOpacity>
      </>
    );
  },
);

export interface ChatInputProps {
  onSend?: (arg: { event: DiscussionEvent }) => void;
  discussion: Discussion;
  staffIdForSubject: number;
  lastAppUserName?: string;
}

const RawChatInput = ({
  onSend,
  discussion,
  staffIdForSubject,
  lastAppUserName,
}: ChatInputProps) => {
  const translate = useTranslate();
  const [contentDialogOpened, setContentDialogOpened] = useState(false);
  const staffUserId = useSelector((state) => state.user.userId);
  const chatInputRef = useRef(null);
  const dispatch = useDispatch();

  const { macroSuggestionId, macroId } = useSelector((state) => state.macro);

  const notify = useNotify();
  const chatMemory = useSelector((state) => state.chatMemory);
  const { control, setValue, handleSubmit, formState, getValues, reset } =
    useForm<FormValues>({
      defaultValues: { text: chatMemory?.[discussion?.id] ?? '' },
    });
  const { isSubmitting, isValid } = formState;

  const formText = getValues('text');

  useEffect(() => {
    const chatInput = chatInputRef.current?.children[0];
    if (chatInput) {
      chatInput.focus();
      chatInput.selectionStart === 0 &&
        chatInput.setSelectionRange(formText.length, formText.length);
    }
    if (formText === '') {
      dispatch(
        setMacroSuggestion({
          macroId: undefined,
          macroSuggestionId: undefined,
        }),
      );
    }
  }, [dispatch, formText, discussion?.id]);

  useEffect(() => {
    // Save current message in store to ease switch between discussions
    // This is only applied when we leave the discussion without sending it
    return () => {
      dispatch(
        setDiscussionMessage({
          discussionId: discussion?.id,
          message: getValues('text'),
        }),
      );
    };
  }, [dispatch, getValues, discussion?.id]);

  const postAndCloseRevive = usePostAndCloseRevive();

  const handleSend = useMemo(
    () =>
      handleSubmit(
        async ({ text, revive }: { text: string; revive: HandoverRevive }) => {
          try {
            dispatch(
              setMacroSuggestion({
                macroId: undefined,
                macroSuggestionId: undefined,
              }),
            );
            dispatch(
              setDiscussionMessage({
                discussionId: discussion?.id,
                message: '',
              }),
            );
            const discussionEvent = await postAndCloseRevive(
              {
                content: text,
                type: DiscussionEventType.TEXT,
                discussionId: discussion.id,
                macroSuggestionId,
                macroId,
              },
              revive,
            );

            reset({ text: '', revive: null });
            setTimeout(() => chatInputRef.current?.children[0]?.focus(), 0);

            logSentChatMessage({
              discussionId: discussion.id,
              subjectId: discussion.lastSubjectId,
              contentType: DiscussionEventType.TEXT,
            });
            if (staffIdForSubject !== staffUserId) {
              logTookSubjectFromUnattributed(discussion.lastSubjectId);
            }
            onSend?.({ event: discussionEvent });
          } catch (e) {
            notify('chat.sentError', {
              type: 'error',
              messageArgs: { error: e },
            });
          }
        },
      ),
    [
      chatInputRef,
      discussion.id,
      discussion.lastSubjectId,
      handleSubmit,
      notify,
      onSend,
      postAndCloseRevive,
      reset,
      staffIdForSubject,
      staffUserId,
      macroId,
      macroSuggestionId,
      dispatch,
    ],
  );

  const onContentSend = useCallback(
    async (input: { type: DiscussionEventType; content: string }) => {
      try {
        const discussionEvent = await postAndCloseRevive({
          discussionId: discussion.id,
          ...input,
        });
        chatInputRef.current?.children[0]?.focus();
        dispatch(
          setMacroSuggestion({
            macroId: undefined,
            macroSuggestionId: undefined,
          }),
        );
        logSentChatMessage({
          subjectId: discussion.lastSubjectId,
          discussionId: discussion.id,
          contentType: input.type,
          ...(CMS_CONTENT_TYPES.includes(input.type)
            ? { contentId: input.content }
            : {}),
        });
        onSend?.({ event: discussionEvent });
      } catch (e) {
        notify('chat.sentError', { type: 'error', messageArgs: { error: e } });
      }
    },
    [
      discussion.id,
      discussion.lastSubjectId,
      notify,
      onSend,
      postAndCloseRevive,
      chatInputRef,
      dispatch,
    ],
  );

  const handleSendLivi = useCallback(async () => {
    await onContentSend({
      type: DiscussionEventType.PARTNER,
      content: 'livi',
    });
  }, [onContentSend]);

  const user = useSelector((state) => state.user);
  const onEmojiClick = useCallback(
    (emoji: EmojiClickData) => {
      setOpenEmojiPicker(false);
      setValue('text', `${getValues().text}${emoji.emoji}`, {
        shouldValidate: true,
      });
      // focus on the input
      chatInputRef.current?.children[0]?.focus();
    },
    [getValues, setValue],
  );

  const slot = useMyCurrentSlot();

  const introduction = useCallback(
    ({ mode }: { mode: 'manual' | 'auto' } = { mode: 'manual' }) => {
      if (!user) {
        return;
      }
      logClickOnGreetings(mode || ('manual' as const));
      setValue(
        'text',
        getIntroductionMessage(
          getValues().text,
          user,
          (discussion.appUser
            ? discussion.appUser.firstName
            : lastAppUserName) || '',
          slot,
        ),
        { shouldValidate: true },
      );
      // focus on the input
      chatInputRef.current?.children[0]?.focus();
    },
    [getValues, setValue, user, discussion.appUser, slot, lastAppUserName],
  );

  const onCopyContent = useCallback(
    (text: string) => {
      setValue('text', `${getValues().text} ${text}`, { shouldValidate: true });
      // focus on the input
      setTimeout(() => chatInputRef.current?.children[0]?.focus(), 0);
    },
    [getValues, setValue, chatInputRef],
  );

  const setRevive = useCallback(
    (revive: HandoverRevive) => {
      // focus on the input
      setValue('revive', revive);
      setValue('text', revive.plannedText, { shouldValidate: true });
      chatInputRef.current?.children[0]?.focus();
    },
    [setValue, chatInputRef],
  );

  useEffect(() => {
    if (!isValid) {
      setValue('revive', null, { shouldValidate: true });
    }
  }, [isValid, setValue]);

  useEffect(() => {
    if (
      !getValues().text &&
      discussion?.id &&
      (!discussion?.kidId ||
        (discussion?.appUsers?.length > 0 && lastAppUserName)) &&
      !discussion?.lastSubject?.empty &&
      !discussion?.lastSubject?.mayAnswered
    ) {
      introduction({ mode: 'auto' });
    }
  }, [
    getValues,
    discussion?.id,
    discussion?.kidId,
    discussion?.lastSubject?.empty,
    discussion?.lastSubject?.mayAnswered,
    discussion?.appUsers,
    introduction,
    lastAppUserName,
  ]);
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);

  const text = useWatch({ control, name: 'text' });
  const hasText = Boolean(text);
  useSendTypingEvent(discussion?.id, hasText);

  return (
    <View style={styles.container}>
      <StartSessionButton variant="contained" from="ChatInput" />
      <View style={styles.inputContainer}>
        <ChatActions
          discussion={discussion}
          onContentSend={onContentSend}
          onContentCopy={onCopyContent}
          introduction={introduction}
          createLiviConsultationMessage={handleSendLivi}
          setRevive={setRevive}
          contentDialogOpened={contentDialogOpened}
          setContentDialogOpened={setContentDialogOpened}
          setOpenEmojiPicker={setOpenEmojiPicker}
        />
        <TextInput
          control={control}
          name="text"
          label={''}
          multiline
          autoFocus
          placeholder={translate('chat.placeholder')}
          sx={{
            margin: 0,
            width: '100%',
            padding: 0,
            paddingLeft: '0.5rem',
            height: '100%',
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              height: '100%',
              padding: 0,
              overflow: 'auto',
              alignItems: 'baseline',
            },
            inputProps: {
              style: {
                padding: 0,
                margin: 0,
                marginTop: '0.5rem',
                paddingRight: '0.5rem',
              },
              spellCheck: true,
              autoCorrect: 'on',
              autoCapitalize: 'sentences',
            },
            ref: chatInputRef,
          }}
          minRows={2}
          rules={{ required: true, minLength: 1 }}
          transform={(value) =>
            value && value.length === 1
              ? value.charAt(0).toUpperCase() + value.slice(1)
              : value
          }
          reportRequired={false}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column-reverse',
          }}
        >
          <Box
            sx={{
              flex: 3,
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'column',
            }}
          >
            <SendButton
              control={control}
              onPress={handleSend}
              disabled={isSubmitting || !isValid}
            />
          </Box>
        </Box>
      </View>
      <EmojiPicker
        open={openEmojiPicker}
        onEmojiClick={onEmojiClick}
        height={'calc(100% - 30px)'}
        width="calc(100% - 72px)"
        style={{ position: 'absolute', top: 30, left: 36 }}
        previewConfig={{
          showPreview: false,
        }}
        skinTonesDisabled={true}
      />
    </View>
  );
};

export const ChatInput = React.memo(RawChatInput);
