import { createContext, useContext } from 'react';

const TargetContext = createContext<{
  type: 'question' | 'template' | 'step';
  id: string;
}>({ type: 'question', id: '' });

export const useTarget = () => {
  return useContext(TargetContext);
};

export const TargetProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: {
    type: 'question' | 'template' | 'step';
    id: string;
  };
}) => {
  return (
    <TargetContext.Provider value={value}>{children}</TargetContext.Provider>
  );
};
