import { useTranslate } from 'react-admin';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';

import { Button } from '@components/generic/Button';
import { Row } from '@components/generic/Row';
import { usePrompt } from '@hooks/usePrompt';
import Delete from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import { Box, CircularProgress, Drawer } from '@mui/material';
import { Question, QuestionRule, Template } from '@teammay/form-core';

import { TargetProvider } from './TargetProvider';

export type EditComponent = React.ComponentType<{
  onBlur: () => void;
  onCancel: () => void;
}>;

export type EditWrapperProps<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
> = {
  content: Content;
  FormComponent: EditComponent;
  open: boolean;
  onCancel: () => void;
  onSave: (content: Content) => void;
  onChange?: (content: Content) => void;
  defaultValues?: Partial<Content>;
  onDelete?: () => void;
  targetObject: {
    type: 'question' | 'template' | 'step';
    id: string;
  };
};

function EditWrapperInner<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
>({
  content,
  onChange,
  onSave,
  onCancel,
  FormComponent,
  defaultValues = {},
  onDelete,
  targetObject,
}: Omit<EditWrapperProps<Content>, 'open'>) {
  const translate = useTranslate();
  const form = useForm<Content>({
    defaultValues: { ...defaultValues, ...content } as DefaultValues<Content>,
    mode: 'onTouched',
  });
  const { handleSubmit, reset, formState } = form;

  const { isLoading, isDirty } = formState;
  usePrompt(translate('generic.notSaved'), isDirty);

  const handleChange = handleSubmit((data: Content) => {
    onChange?.(data);
  });
  const submit = handleSubmit((data: Content) => {
    onSave(data);
  });

  return (
    <>
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          py: 2,
          px: 4,
        }}
      >
        <TargetProvider value={targetObject}>
          <FormProvider {...form}>
            <FormComponent onBlur={handleChange} onCancel={onCancel} />
            <Row>
              <Button
                variant={'contained'}
                size="small"
                color="primary"
                onClick={() => submit()}
                startIcon={
                  isLoading ? (
                    <CircularProgress size="small" />
                  ) : (
                    <SaveIcon sx={{ color: 'white' }} />
                  )
                }
                loading={isLoading}
              >
                {translate('generic.save')}
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 1,
                }}
              >
                {onDelete && (
                  <Button
                    variant="text"
                    size="small"
                    color="error"
                    onClick={onDelete}
                    startIcon={<Delete />}
                  >
                    {translate('common.delete')}
                  </Button>
                )}
                <Button
                  variant={'text'}
                  size="small"
                  color="inherit"
                  onClick={() => {
                    reset();
                  }}
                  startIcon={
                    isLoading ? (
                      <CircularProgress size="small" />
                    ) : (
                      <RestartAltIcon />
                    )
                  }
                  loading={isLoading}
                >
                  {translate('generic.reset')}
                </Button>
              </Box>
            </Row>
          </FormProvider>
        </TargetProvider>
      </Box>
    </>
  );
}

export function EditWrapper<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
>({ open, onCancel, ...props }: EditWrapperProps<Content>) {
  return (
    <Drawer
      open={open}
      anchor="left"
      onClose={(_) => {
        onCancel();
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          minWidth: '60%',
          maxWidth: '80%',
        },
      }}
      SlideProps={{
        unmountOnExit: true,
      }}
    >
      <EditWrapperInner onCancel={onCancel} {...props} />
    </Drawer>
  );
}
