import { useState } from 'react';

import { AuthCard } from './components/authCard';
import { LoginForm } from './LoginForm';
import { ResetForm } from './reset/ResetForm';

export const Auth = ({
  successCallback,
}: {
  successCallback: (data: any) => void;
}) => {
  const [resetPassword, setResetPassword] = useState<boolean>(false);

  return (
    <AuthCard>
      {resetPassword ? (
        <ResetForm reset={setResetPassword} />
      ) : (
        <LoginForm successCallback={successCallback} reset={setResetPassword} />
      )}
    </AuthCard>
  );
};
