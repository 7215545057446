import {
  Datagrid,
  List,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';

import { platformChoices, statusChoices } from '@boTypes/appBundle';
import { DateTimeAgoField } from '@components/fields';

import { i18nProvider } from '../../App';

const getAppBundlesFilters = () => [
  <SelectInput
    source="platform"
    alwaysOn
    variant="standard"
    label={i18nProvider.translate('admin.appBundles.platform')}
    choices={platformChoices}
  />,
  <TextInput
    source="runtimeVersion"
    alwaysOn
    variant="standard"
    label={i18nProvider.translate('admin.appBundles.runtimeVersion')}
  />,
  <SelectInput
    source="status"
    alwaysOn
    variant="standard"
    label={i18nProvider.translate('admin.appBundles.status')}
    choices={statusChoices()}
  />,
];

export const AppBundlesList = () => {
  const translate = useTranslate();
  return (
    <List
      filters={getAppBundlesFilters()}
      filterDefaultValues={{}}
      perPage={10}
      sx={{ padding: '0 1rem' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField
          source="runtimeVersion"
          label={translate('admin.appBundles.runtimeVersion')}
        />
        <TextField
          source="platform"
          label={translate('admin.appBundles.platform')}
        />
        <TextField
          source="status"
          label={translate('admin.appBundles.status')}
        />
        <DateTimeAgoField
          source="updatedAt"
          label={translate('common.updatedAt')}
        />
      </Datagrid>
    </List>
  );
};
