import { useCallback, useEffect, useState } from 'react';
import { Authenticated, useTranslate } from 'react-admin';
import { useParams } from 'react-router';
import { ReactFlowProvider } from 'reactflow';

import { useFormDetail, useFormDetailUpdate } from '@hooks/form-builder';
import { usePrompt } from '@hooks/usePrompt';
import { Box, CircularProgress } from '@mui/material';
import {
  Form,
  FormStep,
  Question,
  StepScreen,
  Template,
} from '@teammay/form-core';

import { Flow } from './components/Flow';
import { MobileView } from './components/MobileView';
import { AvailableDataSlugsProvider } from './hooks/useAvailableDataSlugs';
import { OnChangeFormArgs } from './types';

type FormCreate = Form & {
  createdAt?: string;
  updatedAt?: string;
};

const removeEmptyStepsCondition = (steps: FormStep[]) => {
  return steps.map((step) => {
    const _step = { ...step };
    _step.links = step.links.map((link) => {
      if (
        link.condition.dataRules?.length ||
        link.condition.questionRules?.length
      ) {
        return link;
      }
      return { ...link, condition: null, conditionId: null };
    });
    return _step;
  });
};

export const FormEdit = () => {
  const { slug } = useParams();

  const { data: formDetail } = useFormDetail(slug, {
    refetchOnWindowFocus: false,
  });

  const [form, setForm] = useState<FormCreate>(undefined);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selected, setSelected] = useState<StepScreen | null>(null);
  const { mutate, isPending } = useFormDetailUpdate();
  const [hasModification, setHasModification] = useState(false);

  const translate = useTranslate();
  usePrompt(translate('forms.edit.modified'), hasModification);

  useEffect(() => {
    if (formDetail) {
      setForm(formDetail.form);
      setQuestions(formDetail.questions);
      setTemplates(formDetail.templates);
    }
  }, [formDetail]);

  const onChange = useCallback((args: OnChangeFormArgs) => {
    const {
      deletedQuestion,
      updatedQuestion,
      deletedTemplates,
      updatedTemplate,
      updatedStep,
      addHideCondition,
      addQuestion,
      addTemplate,
      addScore,
      addStep,
      deleteStep,
      updatedLink,
    } = args;
    let mod = false;
    if (deletedQuestion) {
      mod = true;
      setQuestions((prev) => prev.filter((q) => q.id !== deletedQuestion));
    }
    if (updatedQuestion) {
      mod = true;
      setQuestions((prev) =>
        prev.map((q) => {
          if (q.id === updatedQuestion.id) {
            return Object.assign(q, updatedQuestion);
          } else {
            return q;
          }
        }),
      );
    }
    if (updatedStep) {
      mod = true;
      setForm((prev) =>
        Object.assign(prev, {
          steps: prev.steps.map((s) => {
            if (s.id === updatedStep.id) {
              return Object.assign(s, updatedStep);
            } else {
              return s;
            }
          }),
          firstStep:
            updatedStep.id === prev.firstStep.id ? updatedStep : prev.firstStep,
        }),
      );
    }
    if (deletedTemplates) {
      mod = true;
      setTemplates((prev) => prev.filter((t) => t.id !== deletedTemplates));
    }
    if (updatedTemplate) {
      mod = true;
      setTemplates((prev) =>
        prev.map((t) => {
          if (t.id === updatedTemplate.id) {
            return Object.assign(t, updatedTemplate);
          } else {
            return t;
          }
        }),
      );
    }
    if (addHideCondition) {
      mod = true;
      const { questionId, templateId, condition } = addHideCondition;
      setQuestions((prev) =>
        prev.map((q) => {
          if (q.id === questionId) {
            return {
              ...q,
              hideCondition: condition,
            };
          } else {
            return q;
          }
        }),
      );
      setTemplates((prev) =>
        prev.map((q) => {
          if (q.id === templateId) {
            return {
              ...q,
              hideCondition: condition,
            };
          } else {
            return q;
          }
        }),
      );
    }
    if (addQuestion) {
      mod = true;
      setQuestions((prev) => {
        return [...prev, addQuestion];
      });
    }
    if (addTemplate) {
      mod = true;
      setTemplates((prev) => {
        return [...prev, addTemplate];
      });
    }
    if (addScore) {
      mod = true;
      setQuestions((prev) => {
        return prev.map((q) => {
          if (q.id === addScore.questionId) {
            return Object.assign(q, { scoringRules: addScore.scoringRules });
          } else {
            return q;
          }
        });
      });
    }
    if (addStep) {
      mod = true;
      const { from, to, link } = addStep;
      setForm((prev) =>
        Object.assign(prev, {
          steps: prev.steps
            .map((s) => {
              if (s.id === from.id) {
                return Object.assign(s, {
                  links: [...s.links, link],
                });
              } else {
                return s;
              }
            })
            .concat(to),
        }),
      );
    }
    if (deleteStep) {
      mod = true;
      setForm((prev) =>
        Object.assign(prev, {
          steps: prev.steps
            .filter((s) => s.id !== deleteStep)
            .map((step) => {
              return {
                ...step,
                links: step.links.filter((l) => l.nextStepId !== deleteStep),
              };
            }),
        }),
      );
      setQuestions((prev) => prev.filter((q) => q.stepId !== deleteStep));
      setTemplates((prev) => prev.filter((t) => t.stepId !== deleteStep));
    }
    if (updatedLink) {
      mod = true;
      setForm((prev) =>
        Object.assign(prev, {
          steps: prev.steps.map((s) => {
            return {
              ...s,
              links: s.links.map((l) => {
                if (l.id === updatedLink.id) {
                  return updatedLink;
                } else {
                  return l;
                }
              }),
            };
          }),
        }),
      );
    }

    if (mod) {
      setHasModification(true);
    }
  }, []);

  const formForViewer = { ...form };
  if (form?.steps) {
    formForViewer.steps = removeEmptyStepsCondition(form.steps);
  }
  if (form?.firstStep) {
    formForViewer.firstStep = formForViewer.steps.find(
      (step) => step.id === form.firstStep.id,
    );
  }

  return (
    <AvailableDataSlugsProvider value={formDetail?.availableDataSlugs ?? []}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Box sx={{ width: '70%', height: '100%', flex: 7 }}>
          <ReactFlowProvider>
            {form ? (
              <Flow
                form={form}
                questions={questions}
                templates={templates}
                isLoading={isPending}
                onChange={onChange}
                onSave={(args) =>
                  mutate(args, {
                    onSuccess: () => {
                      setHasModification(false);
                    },
                  })
                }
                onSelectionChange={(type, id) => {
                  if (type === 'question') {
                    setSelected({
                      type,
                      content: questions.find((q) => q.id === id) || null,
                    });
                  } else if (type === 'template') {
                    setSelected({
                      type,
                      content: templates.find((q) => q.id === id) || null,
                    });
                  }
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </ReactFlowProvider>
        </Box>
        <MobileView
          sx={{
            width: '30%',
            height: '100%',
            flex: 3,
            backgroundColor: 'white',
            overflow: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
          selected={selected}
          form={formForViewer}
          templates={templates}
          questions={questions}
        />
      </Box>
    </AvailableDataSlugsProvider>
  );
};

export const Component = () => (
  <Authenticated requireAuth>
    <FormEdit />;
  </Authenticated>
);
