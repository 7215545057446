import dayjs from 'dayjs';

import { i18nProvider } from '../App';
import { Planning } from '../types/planning';
import { DayOfSlots, Slot, SlotCreate, SlotsCreate } from '../types/slot';

export const validateDayOfSlot = (template: DayOfSlots) => {
  const errors: string[] = [];
  const slots = template.slots.filter((slot) => {
    return slot.start && slot.end;
  });
  if (slots.length === 0) {
    errors.push(i18nProvider.translate('slots.atLeastOneRequired'));
  }
  if (slots.some((slot) => slot.start.isAfter(slot.end))) {
    errors.push(i18nProvider.translate('slots.startBeforeEnd'));
  }
  return errors;
};

export const dayOfSlotsToSlotCreates = (
  dayOfSlots: DayOfSlots,
): SlotCreate[] => {
  const day = dayOfSlots.day;
  return dayOfSlots.slots.map((templateSlot) => {
    return {
      ...templateSlot,
      start: day
        .clone()
        .hour(templateSlot.start.hour())
        .minute(templateSlot.start.minute())
        .set('seconds', 0)
        .toDate(),
      end: day
        .clone()
        .hour(templateSlot.end.hour())
        .minute(templateSlot.end.minute())
        .set('seconds', 0)
        .toDate(),
    };
  });
};

export const convertDayOfSlotsToSlotsCreate = (
  dayOfSlots: DayOfSlots[] = [],
): SlotsCreate => {
  return { slots: dayOfSlots.map(dayOfSlotsToSlotCreates).flat() };
};

export const convertSlotsToDayOfSlots = (slots: Slot[] = []): DayOfSlots[] => {
  const dayOfSlots = slots.reduce(
    (acc, slot) => {
      const start = dayjs(slot.start);
      const end = dayjs(slot.end);
      const day = start.format('YYYY-MM-DD');
      if (!acc[day]) {
        acc[day] = {
          isTemplate: false,
          day: start,
          slots: [],
        };
      }
      acc[day].slots.push({
        start,
        end,
        onCall: slot.onCall,
        isPriority: slot.isPriority,
        isTriage: slot.isTriage,
      });
      return acc;
    },
    {} as Record<string, DayOfSlots>,
  );

  return Object.keys(dayOfSlots)
    .sort()
    .map((key) => dayOfSlots[key]);
};

export const copyPreviousPlanningSlots = (
  previous: Planning,
  next: Planning,
) => {
  const dayOfWeekDifference =
    (dayjs(next.begin).day() - dayjs(previous.begin).day() + 7) % 7;

  const previousDayOfSlots = convertSlotsToDayOfSlots(previous.slots);
  const nextMonthDuration = dayjs(next.end).diff(dayjs(next.begin), 'day') + 1;
  let nextDayOfSlots = [] as DayOfSlots[];
  for (let dayOfMonth = 0; dayOfMonth < nextMonthDuration; dayOfMonth++) {
    let previousMonthCorrespondingDay = dayOfMonth + dayOfWeekDifference;
    while (previousMonthCorrespondingDay >= previousDayOfSlots.length) {
      previousMonthCorrespondingDay -= 7;
    }
    const previousDayOfSlot = previousDayOfSlots[previousMonthCorrespondingDay];

    const day = dayjs(next.begin).add(dayOfMonth, 'day');
    const deltaDay = day.diff(previousDayOfSlot.day.startOf('day'), 'day');
    const nextDayOfSlot = {
      ...previousDayOfSlot,
      day: dayjs(next.begin).add(dayOfMonth, 'day'),
      slots: previousDayOfSlot.slots.map((slot) => {
        return {
          ...slot,
          start: slot.start.add(deltaDay, 'day'),
          end: slot.end.add(deltaDay, 'day'),
        };
      }),
    };
    nextDayOfSlots.push(nextDayOfSlot);
  }

  return nextDayOfSlots;
};
