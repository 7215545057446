import {
  BooleanInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useTranslate,
} from 'react-admin';

import type { FormProps } from '../../types';

export const AffiliationsForm = (props: FormProps) => {
  const translate = useTranslate();
  return (
    <SimpleForm {...props}>
      <TextInput
        multiline
        label={translate('common.description')}
        source="description"
        validate={required()}
        variant="standard"
      />
      <BooleanInput source="active" defaultValue={true} variant="standard" />
      <ReferenceInput
        label={translate('companyPlan.companyPlan')}
        source="companyPlan.id"
        reference="company-plans"
      >
        <SelectInput
          label={translate('companyPlan.companyPlan')}
          optionText="alias"
          variant="standard"
          validate={required()}
        />
      </ReferenceInput>
    </SimpleForm>
  );
};
