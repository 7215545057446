import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { Row } from '@components/generic/Row';
import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Template } from '@teammay/form-core';

export type ImageTemplate = Omit<Template, 'id' | 'createdAt' | 'updatedAt'>;

export const ImageTemplateEdit = ({
  onBlur,
  onCancel,
}: {
  onBlur: () => void;
  onCancel: () => void;
}) => {
  const translate = useTranslate();
  const { control } = useFormContext<ImageTemplate>();

  return (
    <>
      <Row>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {translate('forms.template.formTitle')}
        </Typography>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Row>
      <TextInput
        control={control}
        name="title"
        onBlur={onBlur}
        label={translate('common.title')}
      />
      <TextInput
        control={control}
        name="description"
        onBlur={onBlur}
        label={translate('common.description')}
      />
      <TextInput
        control={control}
        name="image"
        onBlur={onBlur}
        label={translate('common.image')}
      />
      <TextInput
        control={control}
        name={'customCTALabel'}
        label={translate('forms.questions.label')}
        onBlur={onBlur}
      />
    </>
  );
};
