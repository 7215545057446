import {
  DateField,
  Edit,
  Labeled,
  SaveButton,
  SelectField,
  SimpleForm,
  TextInput,
  TextField,
  Toolbar,
  useTranslate,
  SelectInput,
} from 'react-admin';

import { platformChoices, statusChoices } from '@boTypes/appBundle';
import { Grid } from '@mui/material';

export const MyToolbar = () => {
  const translate = useTranslate();
  return (
    <Toolbar>
      <SaveButton label={translate('common.save')} />
    </Toolbar>
  );
};

export const AppBundleEdit = () => {
  const translate = useTranslate();
  return (
    <Edit resource="app-bundles">
      <SimpleForm toolbar={<MyToolbar />}>
        <TextInput
          source="description"
          label={translate('common.description')}
        />
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: 2 }}>
            <Labeled
              source="runtimeVersion"
              label={translate('admin.appBundles.runtimeVersion')}
            >
              <TextField
                source="runtimeVersion"
                label={translate('admin.appBundles.runtimeVersion')}
              />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <SelectField
              source="platform"
              label={translate('admin.appBundles.platform')}
              choices={[platformChoices]}
            />
          </Grid>
        </Grid>
        <SelectInput
          source="status"
          label={translate('admin.appBundles.status')}
          choices={statusChoices()}
        />
        <TextInput source="key" label={translate('admin.appBundles.s3Key')} />
        <Grid container>
          <Grid item xs={6}>
            <Labeled source="createdAt" label={translate('common.createdAt')}>
              <DateField source="createdAt" label="" />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled source="updatedAt" label={translate('common.updatedAt')}>
              <DateField source="updatedAt" label="" />
            </Labeled>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
