import dayjs from 'dayjs';

import { i18nProvider } from '../App';

export const getAge = (date: string | Date, reference?: string | Date) => {
  let now = reference ? dayjs(reference) : dayjs();
  let birthday = dayjs(date);
  const years = now.diff(birthday, 'years');
  birthday = birthday.add(years, 'years');
  const months = now.diff(birthday, 'months');
  birthday = birthday.add(months, 'months');
  const days = now.diff(birthday, 'days');
  return [
    years && i18nProvider.translate('common.yearCount', { smart_count: years }),
    months &&
      years <= 5 &&
      i18nProvider.translate('common.monthCount', { smart_count: months }),
    days &&
      years < 1 &&
      i18nProvider.translate('common.dayCount', { smart_count: days }),
    !years && !months && !days && i18nProvider.translate('common.birth'),
  ]
    .filter(Boolean)
    .join(' ');
};

export const getAgeShort = (date: string | Date, reference?: string | Date) => {
  let now = reference ? dayjs(reference) : dayjs();
  let birthday = dayjs(date);
  const years = now.diff(birthday, 'years');
  birthday = birthday.add(years, 'years');
  const months = now.diff(birthday, 'months');
  birthday = birthday.add(months, 'months');
  const days = now.diff(birthday, 'days');
  return [
    years && i18nProvider.translate('common.yearCount', { smart_count: years }),
    months &&
      years <= 5 &&
      i18nProvider.translate('common.monthShortCount', { months }),
    days &&
      years < 1 &&
      i18nProvider.translate('common.dayShortCount', { days }),
    !years && !months && !days && i18nProvider.translate('common.birth'),
  ]
    .filter(Boolean)
    .join(' ');
};

export const getAgeComplete = (date: string | Date, locales = 'fr') => {
  const _birthDate = new Date(date);
  const birthDate = _birthDate.toLocaleDateString(locales);
  const age = getAge(date);
  return `${age} (${birthDate})`;
};

export const isToday = (date: string | Date) => {
  return dayjs(date).isSame(dayjs(), 'day');
};
