import {
  BooleanInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import type { FormProps } from '../../types';

export const CompanyForm = (props: FormProps) => {
  const translate = useTranslate();
  return (
    <SimpleForm {...props}>
      <TextInput
        source="name"
        validate={required()}
        variant="standard"
        label={translate('common.name')}
      />
      <BooleanInput
        source="active"
        defaultValue={true}
        variant="standard"
        label={translate('common.active')}
      />
    </SimpleForm>
  );
};
