import { Box, type BoxProps } from '@mui/material';

export const Row = ({ sx, ...props }: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...sx,
      }}
      {...props}
    />
  );
};
