import {
  BooleanInput,
  DateInput,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from 'react-admin';

import { FormProps } from '../../types';

export const CompanyPlanCodeForm = (props: FormProps) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <TextInput
        label={translate('common.name')}
        source="name"
        validate={required()}
        variant="standard"
      />
      <TextInput
        label={translate('companyPlanCode.code')}
        source="code"
        validate={required()}
        variant="standard"
      />
      <BooleanInput
        label={translate('common.active')}
        source="active"
        defaultValue={true}
        variant="standard"
      />
      <DateInput
        label={translate('companyPlanCode.expiresAt')}
        source="expiresAt"
        validate={required()}
        variant="standard"
      />
      <NumberInput
        label={translate('companyPlanCode.maxUsage')}
        source="maxUsage"
        variant="standard"
        defaultValue={-1}
      />
      {!props.record?.companyPlan && (
        <ReferenceInput
          source="companyPlan.id"
          reference="company-plans"
          variant="standard"
        >
          <SelectInput
            label={translate('companyPlan.companyPlan')}
            optionText="alias"
            variant="standard"
            validate={required()}
          />
        </ReferenceInput>
      )}
    </SimpleForm>
  );
};
