import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useNodes } from 'reactflow';

import { SelectInput } from '@components/forms/selectInput';
import { Button } from '@components/generic/Button';
import { Row } from '@components/generic/Row';
import { Add, Close } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import { BooleanOperator, Condition, Operator } from '@teammay/form-core';

import { DataRuleEdit } from './DataRuleEdit';
import { QuestionRuleEdit } from './QuestionRuleEdit';
import { useAvailableDataSlugs } from '../../hooks/useAvailableDataSlugs';
import { QuestionNodeType } from '../../types';

export type ConditionForm = Condition;

const ConditionEdit = ({
  onBlur,
  onCancel,
  title,
  reversedTitle,
  defaultReversedLogic = true,
}: {
  onBlur: () => void;
  onCancel: () => void;
  title: string;
  reversedTitle: string;
  defaultReversedLogic?: boolean;
}) => {
  const { control } = useFormContext<ConditionForm>();
  const translate = useTranslate();

  const {
    fields: questionRules,
    append: appendQuestionRules,
    remove: removeQuestionRules,
  } = useFieldArray({
    control,
    name: 'questionRules',
  });
  const {
    fields: dataRules,
    append: appendDataRules,
    remove: removeDataRules,
  } = useFieldArray({
    control,
    name: 'dataRules',
  });
  const booleanOperator = useWatch({ control, name: 'booleanOperator' });
  const availableDataSlugs = useAvailableDataSlugs();
  const questions = (
    useNodes().filter((node) => node.type === 'question') as QuestionNodeType[]
  ).map((node) => node.data.question);

  const [reversedLogic, setReversedLogic] = useState(defaultReversedLogic);

  return (
    <>
      <Row>
        <Button
          onClick={() => setReversedLogic((prev) => !prev)}
          variant={reversedLogic ? 'outlined' : 'contained'}
          color={reversedLogic ? 'primary' : 'primary'}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold' }}
            noWrap
            color={reversedLogic ? 'primary' : 'white'}
          >
            {reversedLogic ? reversedTitle : title}
          </Typography>
        </Button>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Row>
      <SelectInput
        variant="outlined"
        control={control}
        name={`booleanOperator`}
        label={translate('forms.conditions.booleanOperator')}
        defaultValue={booleanOperator || BooleanOperator.AND}
      >
        <MenuItem key={BooleanOperator.AND} value={BooleanOperator.AND}>
          {reversedLogic ? BooleanOperator.OR : BooleanOperator.AND}
        </MenuItem>
        <MenuItem key={BooleanOperator.OR} value={BooleanOperator.OR}>
          {reversedLogic ? BooleanOperator.AND : BooleanOperator.OR}
        </MenuItem>
      </SelectInput>
      <Typography
        variant="subtitle2"
        color="inherit"
        sx={{ fontWeight: 'bold' }}
        noWrap
      >
        {translate('forms.conditions.questionRules.title')}
      </Typography>
      {questionRules.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.25rem',
          }}
        >
          <QuestionRuleEdit
            field={field}
            index={index}
            onBlur={onBlur}
            reversedLogic={reversedLogic}
          />
          <IconButton onClick={() => removeQuestionRules(index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        sx={{ width: 'max-content' }}
        size="small"
        variant="outlined"
        startIcon={<Add />}
        onClick={() => {
          appendQuestionRules({
            id: self.crypto.randomUUID(),
            expectedValue: '',
            questionId: questions[0].id,
            operator: Operator.ALWAYS_TRUE,
          });
          onBlur();
        }}
      >
        {translate('forms.conditions.questionRules.add')}
      </Button>
      <Typography
        variant="subtitle2"
        color="inherit"
        sx={{ fontWeight: 'bold' }}
        noWrap
      >
        {translate('forms.conditions.dataRules.title')}
      </Typography>
      {dataRules.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.25rem',
          }}
        >
          <DataRuleEdit
            field={field}
            index={index}
            onBlur={onBlur}
            reversedLogic={reversedLogic}
          />
          <IconButton onClick={() => removeDataRules(index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        size="small"
        startIcon={<Add />}
        sx={{ width: 'max-content' }}
        onClick={() => {
          appendDataRules({
            id: self.crypto.randomUUID(),
            expectedValue: '',
            dataSlug: availableDataSlugs[0] ?? '',
            operator: Operator.ALWAYS_TRUE,
          });
          onBlur();
        }}
      >
        {translate('forms.conditions.dataRules.add')}
      </Button>
    </>
  );
};

export const DisplayConditionEdit = (props: {
  onBlur: () => void;
  onCancel: () => void;
}) => {
  const translate = useTranslate();
  return (
    <ConditionEdit
      {...props}
      defaultReversedLogic={false}
      title={translate('forms.conditions.displayTitle')}
      reversedTitle={translate('forms.conditions.hideTitle')}
    />
  );
};

export const HideConditionEdit = (props: {
  onBlur: () => void;
  onCancel: () => void;
}) => {
  const translate = useTranslate();
  return (
    <ConditionEdit
      {...props}
      defaultReversedLogic={true}
      title={translate('forms.conditions.hideTitle')}
      reversedTitle={translate('forms.conditions.displayTitle')}
    />
  );
};
