import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { Box, CardActions } from '@mui/material';

import { OTPCodeInput } from './components/SMSCodeInput';
import { AuthFormContainer } from './components/styled';
import { CheckboxInput } from '../components/forms/checkBoxInput';
import { Button } from '../components/generic/Button';

const OTPNumInputs = 4;

export const TwoFA = ({
  onSubmit,
  withTrusted = false,
}: {
  onSubmit: (twoFACode: string, trustedDevice?: boolean) => Promise<void>;
  withTrusted?: boolean;
}) => {
  const { handleSubmit, control, formState } = useForm<{
    twoFaCode: string;
    trustedDevice: boolean;
  }>({ defaultValues: { trustedDevice: false } });
  const { isSubmitting } = formState;
  const translate = useTranslate();

  const onFormSubmit = handleSubmit(({ twoFaCode, trustedDevice }) => {
    onSubmit(twoFaCode, trustedDevice);
  });

  return (
    <form onSubmit={onFormSubmit}>
      <AuthFormContainer>
        <OTPCodeInput
          name="twoFaCode"
          control={control}
          rules={{ required: true }}
          disabled={isSubmitting}
          numInputs={OTPNumInputs}
          onPaste={() => {
            onFormSubmit();
          }}
        />
        {withTrusted && (
          <Box>
            <CheckboxInput
              name="trustedDevice"
              control={control}
              disabled={isSubmitting}
              label={translate('auth.trustDevice')}
            />
          </Box>
        )}
        <CardActions
          sx={{
            padding: 0,
            marginTop: '1rem',
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={isSubmitting}
            value={translate('common.validate')}
            fullWidth
          />
        </CardActions>
      </AuthFormContainer>
    </form>
  );
};
