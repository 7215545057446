import merge from 'lodash/merge';
import React from 'react';
import { defaultTheme, RaThemeOptions } from 'react-admin';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

const BLUE = {
  100: '#def6fc',
  200: '#a8e1f1',
  300: '#79cae1',
  400: '#52b0cb',
  500: '#3293ae',
  600: '#2D859D',
  700: '#1F5B6B',
};

const BLUE_DARK = {
  100: '#DBF3FF',
  200: '#7E9DD3',
  300: '#5f5fc4',
  400: '#283593',
  500: '#001064',
};

const PINK = {
  100: '#F7DADA',
  200: '#F2A5A3',
  300: '#FF9191',
  400: '#FF7878',
  500: '#FF6060',
};

const GREEN = {
  100: '#EAEFED',
  200: '#DFE8E8',
  300: '#d8e5e1',
  400: '#9db4b4',
  500: '#6A9493',
  600: '#5F8584',
  700: '#506769',
  800: '#365150',
};

const YELLOW = {
  100: '#FAFAD2',
  200: '#F6E3AC',
  300: '#F2D88C',
  400: '#F4CB56',
  500: '#FFD700',
};

export const COLORS = {
  // Logo colors - primary colors from design system
  SAND: '#F4E7D7',
  ORANGE: '#F17338',

  // GRADIENTS
  GREEN,
  BLUE,
  BLUE_DARK,
  YELLOW,
  PINK,

  // Others
  TEXT: '#3e3e3e',
  GREY_TEXT: '#717171',
  GREY_TEXT_LIGHT: '#bbbbbb',
  WHITE: '#0000000A',
  WHITE_TEXT: '#f5ffff',
  BACKGROUND: '#FDFDFC',
  STAFF_BUBBLE_BACKGROUP: '#fdf3f3',
  APP_USER_BUBBLE_BACKGROUP: '#f0f0e0',

  // GREY
  GREY: '#D0D0D0',
  GREY_LAYOUT: '#ededed',
};

export enum MobileColors {
  // All colors of design model
  white100 = '#FFFFFF',
  black500 = '#000000',
  yellow100 = '#FDFBF4',
  yellow200 = '#FEF4A3',
  yellow300 = '#F6E3AC',
  yellow400 = '#F2D88C',
  yellow500 = '#F4CB56',
  grey300 = '#F3F2F2',
  grey400 = '#E7E7E7',
  grey500 = '#D0D0D0',
  grey600 = '#A6A6A6',
  grey700 = '#8D8D8D',
  pink200 = '#F7DADA',
  pink400 = '#EEC2C0',
  pink500 = '#f2a5a3',
  pink600 = '#F3827F',
  pink700 = '#FF5555',
  green500 = '#66C859',
  blue500 = '#4A9BB5',
  blue600 = '#40859C',
  blue700 = '#356F82',
  blue800 = '#2B5A69',
  ferra400 = '#DD9871',
  ferra700 = '#7D5260',
  duck100 = '#EAEFED',
  duck200 = '#DFE8E8',
  duck300 = '#d8e5e1',
  duck400 = '#9db4b4',
  duck500 = '#6A9493',
  duck700 = '#506769',
  duck800 = '#365150',
  duck900 = '#2F4544',

  // transparent variable to add after a design color
  transparent100 = 'FF',
  transparent95 = 'F2',
  transparent90 = 'E6',
  transparent85 = 'D9',
  transparent80 = 'CC',
  transparent75 = 'BF',
  transparent70 = 'B3',
  transparent65 = 'A6',
  transparent60 = '99',
  transparent55 = '8C',
  transparent50 = '80',
  transparent45 = '73',
  transparent40 = '66',
  transparent35 = '59',
  transparent30 = '4D',
  transparent25 = '40',
  transparent20 = '33',
  transparent15 = '26',
  transparent10 = '1A',
  transparent5 = '0D',
  transparent0 = '00',
}

export const authTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: COLORS.GREEN['500'],
    },
    secondary: {
      main: COLORS.GREEN['300'],
    },
    error: {
      main: COLORS.PINK['500'],
    },
    info: {
      main: COLORS.YELLOW['200'],
    },
    warning: {
      main: COLORS.YELLOW['400'],
    },
    success: {
      main: COLORS.GREEN['800'],
    },
  },
  typography: {
    fontFamily: 'Manrope, sans-serif',
    h6: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '& hover': {
            color: COLORS.GREEN['800'],
          },
        },
      },
    },
  },
};

const palette = merge({}, defaultTheme.palette, {
  primary: {
    main: COLORS.GREEN['500'],
  },
  secondary: {
    main: COLORS.GREEN['300'],
  },
  error: {
    main: COLORS.PINK['500'],
  },
  info: {
    main: COLORS.YELLOW['200'],
  },
  warning: {
    main: COLORS.YELLOW['400'],
  },
  success: {
    main: COLORS.GREEN['800'],
  },
});

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

const colorTheme: RaThemeOptions = {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: COLORS.TEXT,
        },
      },
    },
  },
};

const rawTheme: RaThemeOptions = {
  sidebar: {
    width: 200, // The default value is 240
    closedWidth: 50, // The default value is 55
  },
  palette,
  typography: {
    fontFamily: 'Manrope, sans-serif',
    h6: {
      fontSize: '1.05rem',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: COLORS.TEXT,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '280px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          MuiButton: {
            root: {
              marginLeft: '0.5rem !important',
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    // To allow custom color in data-grid rows
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
  },
};

export const mayTheme = merge({}, defaultTheme, rawTheme, colorTheme);

export const mayDatagridTheme = merge({}, defaultTheme, rawTheme);

const opacity = {
  5: '0D',
  7: '12',
  10: '1A',
  15: '26',
  20: '33',
  25: '40',
  30: '4D',
  35: '59',
  40: '66',
  45: '73',
  50: '80',
  55: '8C',
  60: '99',
  65: 'A6',
  70: 'B3',
  75: 'BF',
  80: 'CC',
  85: 'D9',
  90: 'E6',
  95: 'F2',
} as const;

const primary = {
  spruce: '#073735',
  spruce95: '#13413F',
  spruce90: '#204B49',
  spruce75: '#456967',
  spruce50: '#839B9A',
  spruce25: '#C1CDCC',
  spruce10: '#E6EBEB',
  spruce5: '#F3F5F5',
  spruce2: '#FAFBFB',
} as const;

export const secondary = {
  lilac: '#AB8BE0',
  wisteria: '#DDCAFC',
  wisteria40: '#F1EAFE',
  southSea: '#41B7AC',
  aquamarine: '#AADEDA',
  aquamarine40: '#DDF2F0',
  mandarin: '#EFA962',
  apricot: '#FFCA94',
  apricot40: '#FFEAD4',
  white: '#FFFFFF',
  cream: '#F8F2E9',
} as const;

const ui = {
  spruceTransparent7: `${primary.spruce}${opacity[7]}`,
  whiteTransparent20: `${secondary.white}${opacity[20]}`,
  whiteTransparent50: `${secondary.white}${opacity[50]}`,
  background: '#F9F4EF',
  coral: '#E87374',
  straw: '#EED996',
  hazel: '#7B5A2D',
} as const;

// Tokens

const semanticTokens = {
  surface: {
    primary: secondary.white,
    primaryInverse: primary.spruce,
    secondary: ui.background,
    subtle: ui.spruceTransparent7,
    subtleInverse: ui.whiteTransparent20,
    danger: ui.coral,
    accentLilacContrast: secondary.lilac,
    accentLilac: secondary.wisteria,
    accentLilacSubtle: secondary.wisteria40,
    accentSeaContrast: secondary.southSea,
    accentSea: secondary.aquamarine,
    accentSeaSubtle: secondary.aquamarine40,
    accentMandarinContrast: secondary.mandarin,
    accentMandarin: secondary.apricot,
    accentMandarinSubtle: secondary.apricot40,
  },
  content: {
    primary: primary.spruce,
    primaryInverse: secondary.white,
    secondary: primary.spruce50,
    secondaryInverse: ui.whiteTransparent50,
    danger: ui.coral,
    subtle: primary.spruce25,
    accentLilac: secondary.lilac,
    accentSea: secondary.southSea,
    accentMandarin: secondary.mandarin,
  },
} as const;

export const colorTokens = {
  ...semanticTokens,
  primitives: {
    opacity,
    straw: ui.straw,
    hazel: ui.hazel,
  },
} as const;
