import { useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';

import { useMutation } from '@hooks/queryWrappers';

interface Reset {
  email?: string;
}

export const useResetPasswordSendMail = () => {
  const notify = useNotify();
  const { mutateAsync: send } = useMutation<any, any, Reset>(
    ['send_email'],
    (data: Reset) => ({
      method: 'POST',
      url: '/api/auth/send_email',
      data,
    }),
  );
  const sendEmail = async (auth: Reset) => {
    try {
      await send(auth);
      notify('auth.forms.success.email_sent', { type: 'success' });
    } catch {
      notify('auth.forms.errors.invalid_credentials', { type: 'error' });
    }
  };

  return sendEmail;
};

export const useResetPasswordSendSMS = (
  successCallback: ({ newPassword }: { newPassword: string }) => void,
) => {
  const notify = useNotify();
  const { mutateAsync } = useMutation<any, any, { token: string; id: string }>(
    ['send_sms_reset'],
    (data) => ({
      method: 'POST',
      url: `/api/auth/send_sms_reset`,
      data,
    }),
  );
  const { token, id } = useParams();
  const sendSMS = async ({ newPassword }: { newPassword: string }) => {
    try {
      await mutateAsync({ token, id });
      successCallback({ newPassword });
    } catch {
      notify('auth.forms.errors.invalid_credentials');
    }
  };

  return sendSMS;
};

export const useResetPasswordResetSteps = () => {
  const [smsSent, setSmsSent] = useState<boolean>(false);
  const [pwd, setPwd] = useState<string>('');
  const notify = useNotify();
  const redirect = useRedirect();
  const { id, token } = useParams();
  const { mutateAsync: finalizeReset } = useMutation<any, any, string>(
    ['reset_password', id, token],
    (pwdResetCode: string) => ({
      method: 'POST',
      url: `/api/auth/reset_password`,
      data: {
        pwdResetCode,
        id,
        token,
        newPassword: pwd,
      },
    }),
  );
  const handleSubmit = async (pwdResetCode: string) => {
    try {
      await finalizeReset(pwdResetCode);
      notify('auth.forms.success.password_reset');
      redirect('/login');
    } catch {
      notify('auth.forms.errors.invalid_credentials');
    }
  };

  const successCallback = ({ newPassword }: { newPassword: string }) => {
    setPwd(newPassword);
    setSmsSent(true);
  };

  return { handleSubmit, successCallback, smsSent };
};
