import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';
import {
  createContainer,
  Point,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { VictoryThemeDefinition } from 'victory-core';

import { getChartScale, getDataOnScale, getVerticalLineData } from './utils';
import { MobileColors as Colors } from '../../themes';
import { formatTZ } from '../../utils/date';

const theme: VictoryThemeDefinition = {
  // VictoryAxis
  axis: {
    style: {
      axis: {
        stroke: Colors.grey400,
        strokeWidth: 1,
      },
      axisLabel: {
        // fontFamily: Fonts.type.regular,
        // fontSize: Fonts.size.intermediary,
        fill: Colors.grey500,
        padding: 8,
      },
      grid: { stroke: 'none' },
      ticks: { stroke: 'none' },
      tickLabels: {
        fill: 'none',
        // fontFamily: Fonts.type.regular,
        // fontSize: Fonts.size.intermediary,
        padding: 8,
      },
    },
  },
  // VictoryLine
  line: {
    style: {
      data: { stroke: Colors.grey700, strokeWidth: 2 },
      labels: {
        fill: Colors.grey500,
        verticalAnchor: 'middle',
        // fontFamily: Fonts.type.regular,
        // fontSize: Fonts.size.intermediary,
      },
    },
  },
  // VictoryScatter
  scatter: {
    style: {
      data: {
        fill: Colors.duck800,
      },
      labels: {
        fill: Colors.duck800,
        verticalAnchor: 'middle',
        // fontFamily: Fonts.type.semiBold,
        // fontSize: Fonts.size.intermediary,
      },
    },
  },
};

const VictoryZoomVoronoiContainer = createContainer(
  'zoom',
  'voronoi',
) as React.ComponentType<any>;

const ignoredVoronoi = ['plottedLine'];
const xDomain: [number, number] = [0, 9.5];
const emptyLabels = () => '';

const getChartStartDate = (initialDate: Date | string) => {
  return dayjs(initialDate).subtract(3, 'weeks').toDate();
};

export const LineCharts = ({
  data = [],
  initialDate: initialDateProp,
  heightMargin = 100,
  height,
  width,
  pregnancy,
}: {
  data?: { date: Date | string; value: number }[];
  initialDate: Date;
  heightMargin?: number;
  height: number;
  width?: number;
  pregnancy?: boolean;
}) => {
  const [selected, setSelected] = useState<number>();
  const translate = useTranslate();
  const initialDate = useMemo(() => {
    return getChartStartDate(initialDateProp);
  }, [initialDateProp]);

  const plottedData = useMemo(
    () => getDataOnScale(data, initialDate, 'month'),
    [data, initialDate],
  );
  const yScale = useMemo<[number, number]>(
    () => getChartScale(plottedData, height, heightMargin),
    [plottedData, height, heightMargin],
  );

  const xScale = useMemo<[number, number]>(
    () =>
      pregnancy ? xDomain : [0, Math.max(...plottedData.map((d) => d.x)) + 0.5],
    [pregnancy, plottedData],
  );
  const endDate = useMemo(() => {
    return new Date(Math.max(...data.map((d) => new Date(d.date).getTime())));
  }, [data]);

  const MyPoint = useCallback(
    (props) => {
      if (props.index === 0) {
        return <Point {...props} size={5} style={{ fill: Colors.pink500 }} />;
      }
      return props.index === selected ? (
        <Point
          {...props}
          size={6}
          // svg style does not work with stylesheet
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            fill: Colors.white100,
            stroke: Colors.duck800,
            strokeWidth: 2,
          }}
        />
      ) : (
        <Point {...props} size={5} />
      );
    },
    [selected],
  );

  const DataLabel = useCallback(
    (props) => {
      if (props.index === 0) {
        const dy = props.data[1]?.y > props.data[0]?.y ? 12 : -12;
        return (
          <VictoryLabel
            {...props}
            dy={dy}
            // svg style does not work with stylesheet
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              fill: Colors.pink500,
              verticalAnchor: 'middle',
              // fontFamily: Fonts.type.semiBold,
              // fontSize: Fonts.size.intermediary,
            }}
          />
        );
      } else if (props.index === selected) {
        let dy = -12;
        let dx = 0;
        if (props.index === props.data.length - 1) {
          dy =
            props.data[props.data.length - 2]?.y >
            props.data[props.data.length - 1]?.y
              ? 12
              : -12;
        } else {
          if (
            // local min
            props.data[props.index - 1]?.y > props.data[props.index]?.y &&
            props.data[props.index]?.y < props.data[props.index + 1]?.y
          ) {
            dy = 12;
          } else if (
            // local max
            props.data[props.index - 1]?.y < props.data[props.index]?.y &&
            props.data[props.index]?.y > props.data[props.index + 1]?.y
          ) {
            dy = -12;
          } else if (
            // decreasing
            props.data[props.index - 1]?.y > props.data[props.index]?.y
          ) {
            // upper right
            dx = 12;
            dy = -12;
          } else if (
            // increasing
            props.data[props.index - 1]?.y < props.data[props.index]?.y
          ) {
            if (props.x < 100) {
              // next to left border => bottom right
              dx = 12;
              dy = 12;
            } else {
              // in the right part => upper left
              dx = -12;
              dy = -12;
            }
          }
        }
        return <VictoryLabel {...props} dx={dx} dy={dy} />;
      }
      return null;
    },
    [selected],
  );

  return (
    <VictoryChart
      height={height}
      width={width}
      domain={{ x: xScale, y: yScale }}
      theme={theme}
      containerComponent={
        <VictoryZoomVoronoiContainer
          zoomDimension="x"
          voronoiBlacklist={ignoredVoronoi}
          onActivated={(points) => {
            setSelected(points[0].eventKey);
          }}
          labels={emptyLabels}
        />
      }
    >
      <VictoryLine
        data={getVerticalLineData(initialDate, yScale, height)}
        style={{
          data: {
            strokeDasharray: '5,5',
            stroke: Colors.grey500,
            strokeWidth: 1,
          },
        }}
        labels={['', 'Auj.']}
        labelComponent={
          <VictoryLabel
            backgroundStyle={{ fill: Colors.white100 }}
            backgroundPadding={{ top: 4, bottom: 4 }}
          />
        }
      />

      <VictoryLine
        name={'plottedLine'}
        animate={{
          duration: 2000,
          onLoad: { duration: 1000 },
        }}
        style={{
          labels: { fill: Colors.grey500 },
        }}
        data={plottedData}
      />
      <VictoryAxis
        dependentAxis
        domain={yScale}
        standalone={false}
        label={translate('tools.weight.title')}
      />
      <VictoryAxis
        domain={xDomain}
        style={{ tickLabels: { fill: Colors.grey500 } }}
        tickValues={[0, xScale[1] - 0.5]}
        tickFormat={
          pregnancy
            ? (_, index) =>
                index === 0
                  ? translate('tools.weight.start')
                  : translate('tools.weight.dueDate')
            : (_, index) =>
                index === 0
                  ? formatTZ(initialDate, 'MMM YY')
                  : formatTZ(endDate, 'MMM YY')
        }
      />
      <VictoryScatter
        animate={{
          duration: 2000,
          onLoad: { duration: 1000 },
        }}
        dataComponent={<MyPoint />}
        data={plottedData}
        labels={({ datum }) => `${datum.y.toFixed(1)} kg`}
        labelComponent={<DataLabel dy={-16} />}
      />
    </VictoryChart>
  );
};
