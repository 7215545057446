import { Create, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { CompanyPlanForm } from './form';

export const CompanyPlanCreate = () => {
  const location = useLocation();
  const translate = useTranslate();

  return (
    <Create
      resource="company-plans"
      title={translate('companyPlan.create')}
      redirect={location?.state?.redirect || 'list'}
      sx={{ padding: '1rem' }}
    >
      <CompanyPlanForm />
    </Create>
  );
};
