import { useTranslate } from 'ra-core';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Tooltip } from '@mui/material';

export const WarningTile = ({ invalidFields }: { invalidFields: string[] }) => {
  const translate = useTranslate();

  if (!invalidFields?.length) {
    return null;
  }
  return (
    <Tooltip
      title={translate('forms.errors.invalid_fields', {
        invalidFields: invalidFields.join(', '),
      })}
    >
      <WarningAmberIcon color="warning" sx={{ fontSize: '1rem' }} />
    </Tooltip>
  );
};
