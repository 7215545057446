import { i18nProvider } from '../App';

export enum AppBundlePlatform {
  IOS = 'ios',
  ANDROID = 'android',
}

export enum AppBundleStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TESTING = 'testing',
}

export const platformChoices = [
  { id: AppBundlePlatform.IOS, name: 'iOS' },
  { id: AppBundlePlatform.ANDROID, name: 'Android' },
];

export const statusChoices = () => [
  {
    id: AppBundleStatus.ACTIVE,
    name: i18nProvider.translate('admin.appBundles.active'),
  },
  {
    id: AppBundleStatus.INACTIVE,
    name: i18nProvider.translate('admin.appBundles.inactive'),
  },
  {
    id: AppBundleStatus.TESTING,
    name: i18nProvider.translate('admin.appBundles.testing'),
  },
];

export type AppBundle = {
  id: number;
  runtimeVersion: string;
  description?: string;
  platform: AppBundlePlatform;
  key: string;
  status: AppBundleStatus;
  createdAt: string;
  updatedAt: string;
};
