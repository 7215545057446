import dayjs from 'dayjs';
import { VictoryTheme } from 'victory';
import { VictoryThemeDefinition } from 'victory-core';

import omsCurves from './omsCurves';
import { OMSCurve, OMSDataTypes, orderedOMSCurve } from './types';

const getDaysToExtract = (
  initialDay: Date,
  maximum = 10,
  intervalDuration = 'month' as 'month' | 'week',
) => {
  const start = dayjs(initialDay);
  let result = [0] as number[];
  let lastMonthDay = 0;
  let correspondingMonth = [0];
  let monthCount = 0;
  while (monthCount < maximum) {
    monthCount += 1;
    const current = start.add(monthCount, intervalDuration);
    const monthDay = current.diff(start, 'day');
    result.push(lastMonthDay + Math.round((monthDay - lastMonthDay) / 2));
    result.push(monthDay);
    const previousMonth = correspondingMonth[correspondingMonth.length - 1];
    if (intervalDuration === 'month') {
      correspondingMonth.push(previousMonth + 0.5);
    } else {
      correspondingMonth.push(previousMonth + 4 / 7);
    }
    correspondingMonth.push(previousMonth + 1);
    lastMonthDay = monthDay;
  }
  return [result, correspondingMonth];
};

interface StackDatasetY {
  _stack: number;
  _y: number;
  _y0: number;
  _y1: number;
  y: number;
}

interface StackDataset extends StackDatasetY {
  x: number;
  _x1: number;
  group: number;
}

const getOMSLimits = (
  extractedDays: number[],
  omsData: Record<OMSCurve, number[]>,
) => {
  return extractedDays.reduce(
    (acc, day) => {
      orderedOMSCurve.forEach((key, index) => {
        const y =
          omsData[key][day] -
          (index > 0 ? omsData[orderedOMSCurve[index - 1]][day] : 0);
        acc[key].push({
          _stack: index,
          _y: y,
          _y0: index > 0 ? omsData[orderedOMSCurve[index - 1]][day] : 0,
          _y1: omsData[key][day],
          y,
        });
      });
      return acc;
    },
    {
      SD3neg: [] as StackDatasetY[],
      SD2neg: [] as StackDatasetY[],
      SD1neg: [] as StackDatasetY[],
      SD0: [] as StackDatasetY[],
      SD1: [] as StackDatasetY[],
      SD2: [] as StackDatasetY[],
      SD3: [] as StackDatasetY[],
    } as Record<OMSCurve, StackDatasetY[]>,
  );
};

export const getOMSReferenceData = (
  dataType: OMSDataTypes,
  initialDate: Date,
  maximumDuration = 6,
  intervalType = 'month' as 'month' | 'week',
) => {
  const [extractedDays, months] = getDaysToExtract(
    initialDate,
    maximumDuration,
    intervalType,
  );
  const limits = getOMSLimits(extractedDays, omsCurves[dataType]);
  return Object.keys(limits).reduce(
    (acc, key) => {
      acc[key] = months.map((value, index) => ({
        x: value,
        _x1: value,
        ...limits[key][index],
        group: index,
      }));
      return acc;
    },
    {} as Record<OMSCurve, StackDataset[]>,
  );
};

const getRelativeIndex = (integerDates: number[], date: number) => {
  const index = integerDates.findIndex((item) => item >= date);
  if (index <= 0) {
    return 0;
  }
  return (
    index -
    1 +
    (date - integerDates[index - 1]) /
      (integerDates[index] - integerDates[index - 1])
  );
};

export const getDataOnScale = (
  data: { date: Date | string; value: number }[],
  initialDate: Date,
  intervalType = 'month' as 'month' | 'week',
) => {
  const maxTime = Math.max(
    initialDate.getTime(),
    ...(data.map((item) => new Date(item.date).getTime()) ?? []),
  );

  const initialDay = dayjs(initialDate);

  const maxAbscissa = dayjs(maxTime).diff(dayjs(initialDate), intervalType) + 2;
  const integerDates = Array(maxAbscissa)
    .fill(0)
    .map((_, index) => initialDay.add(index, intervalType).toDate().getTime());

  return data.map(({ date, value }) => ({
    date,
    x: getRelativeIndex(integerDates, new Date(date).getTime()),
    y: value,
  }));
};

export const getVerticalLineData = (
  initialDate: Date,
  yScale: [number, number],
  height: number,
  preciseDate?: Date,
  usedScale?: 'month' | 'week',
) => {
  const date = preciseDate ? new Date(preciseDate) : new Date();

  const labelHeight = ((yScale[1] - yScale[0]) * 5) / height;

  return getDataOnScale(
    [
      { date, value: yScale[0] },
      { date, value: yScale[1] - labelHeight },
    ],
    initialDate,
    usedScale,
  );
};

export const getChartScale = (
  plottedData: { y: number }[],
  height: number,
  heightMargin: number,
  zeroCentered = false,
): [number, number] => {
  if (plottedData.length === 0) {
    return [-1, 1];
  }

  let min = Math.min(...plottedData.map(({ y }) => y));
  let max = Math.max(...plottedData.map(({ y }) => y));

  if (zeroCentered) {
    min = Math.min(min, 0);
    max = Math.max(max, 0);
  }

  const dataRange = max - min;
  const availableHeight = height - heightMargin;
  const scaleRange = (dataRange * height) / availableHeight;
  const scaleMargin = Math.max(scaleRange - dataRange, 1);

  return [min - scaleMargin / 2, max + scaleMargin / 2];
};

export const ChartTheme: VictoryThemeDefinition = {
  ...VictoryTheme.material,
  axis: {
    ...VictoryTheme.material.axis,
    style: {
      ...(VictoryTheme.material.axis?.style ?? {}),
      grid: {
        stroke: 'none',
      },
    },
  },
};

export function getYDomain(
  xDomain: [number, number],
  data: { x: number; y: number }[],
  referenceData: { [key in OMSCurve]: { x: number; y: number }[] },
): [number, number] {
  let [minX, maxX] = xDomain;

  const minRef = referenceData.SD3neg.find((d) => d.x >= minX)?.y ?? 0;
  let maxRefIndex = referenceData.SD3neg.findIndex((d) => d.x > maxX);
  if (maxRefIndex < 0) {
    maxRefIndex = referenceData.SD3neg.length - 1;
  } else if (maxRefIndex > 0) {
    maxRefIndex -= 1;
  }

  const maxRef = Object.values(OMSCurve).reduce((acc, key) => {
    return acc + referenceData[key][maxRefIndex].y;
  }, 0);

  const filteredData = data.filter((d) => d.x >= minX && d.x <= maxX);
  const yValues = filteredData.map((d) => d.y);
  return [Math.min(...yValues, minRef), Math.max(...yValues, maxRef)];
}
