import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { useMutation } from '@hooks/queryWrappers';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { logAddedFollowUp } from '../../analytics/events';
import { usePrompt } from '../../hooks/usePrompt';
import { MedicalFollowUp } from '../../types';
import { MedicalFollowUpUpdate } from '../../types/medicalFollowUp';
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog';
import { DialogButton } from '../DialogButton/DialogButton';
import { TextInput } from '../forms/textInput';
import { Button } from '../generic/Button';

export const FollowUpForm = ({
  followUp,
  familyId,
  onClose,
}:
  | {
      followUp?: undefined | null;
      familyId: string;
      onClose: () => void;
    }
  | {
      followUp: MedicalFollowUp;
      familyId?: undefined | null;
      onClose: () => void;
    }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const { handleSubmit, control, formState } = useForm<MedicalFollowUpUpdate>({
    defaultValues: {
      content: followUp?.content || '',
    },
  });
  const { isDirty, isSubmitting } = formState;

  const protectedOnClose = () => {
    if (isDirty) {
      setOpen(true);
      return;
    }
    onClose();
  };

  usePrompt(translate('common.usePrompt'), isDirty);

  const { mutateAsync: createFollowUp } = useMutation<
    MedicalFollowUp,
    AxiosError,
    MedicalFollowUpUpdate
  >(['createOrUpdateFollowUp', familyId], (updates: MedicalFollowUpUpdate) => ({
    method: familyId ? 'post' : 'put',
    url: familyId
      ? `/api/medical-follow-ups`
      : `/api/medical-follow-ups/${followUp.id}`,
    data: familyId ? { ...updates, familyId } : updates,
  }));
  const onSubmit = useMemo(
    () =>
      handleSubmit(async (_data: MedicalFollowUpUpdate) => {
        try {
          await createFollowUp(_data);
          if (familyId) {
            notify('generic.createSuccess', { type: 'success' });
          } else {
            notify('generic.updateSuccess', { type: 'success' });
          }
          onClose();
          logAddedFollowUp(familyId);
        } catch (error: any) {
          notify(`Erreur : ${error.message}`, { type: 'error' });
        }
      }),
    [createFollowUp, familyId, handleSubmit, notify, onClose],
  );

  const { mutate: deleteFollowUp, isPending: isDeleting } = useMutation<
    MedicalFollowUp,
    AxiosError,
    MedicalFollowUp
  >(
    ['deleteFollowUp', followUp?.id],
    () => ({
      method: 'delete',
      url: `/api/medical-follow-ups/${followUp.id}`,
    }),
    {
      onSuccess() {
        onClose();
      },
      onError(error) {
        notify('common.error.withArgs', {
          type: 'error',
          messageArgs: { error: error.message },
        });
      },
    },
  );

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <ConfirmDialog
        title={translate('common.confirmClose')}
        text={translate('common.confirmCloseText')}
        onClick={onClose}
        onClose={() => setOpen(false)}
        open={open}
      />
      <form
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flex: 1,
          overflow: 'auto',
        }}
        onSubmit={onSubmit}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">
              {familyId
                ? translate('followUp.createTitle')
                : translate('followUp.edit')}
            </Typography>
            <CloseIcon onClick={protectedOnClose} />
          </Box>
          <Box
            sx={{
              flex: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
          >
            <TextInput
              control={control}
              name={'content'}
              label={translate('followUp.observations')}
              rules={{ required: false }}
              sx={{ minWidth: 0 }}
              multiline
              autoFocus
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flex: 0,
              justifyContent: 'flex-end',
            }}
          >
            <Button
              sx={{ flex: 1, margin: '1rem 0 0 0' }}
              variant="contained"
              type="submit"
              color="primary"
              loading={isSubmitting || isDeleting}
              value={
                familyId
                  ? translate('common.create')
                  : translate('common.update')
              }
              fullWidth
            />
            {!familyId && (
              <DialogButton
                size="small"
                title={translate('followUp.suppressTitle')}
                text={translate('followUp.suppressText')}
                cancelText={translate('followUp.cancel')}
                confirmText={translate('followUp.confirm')}
                onClick={() => deleteFollowUp(followUp)}
                sx={{ flex: 1, marginLeft: 1 }}
                variant="contained"
                color="primary"
                disabled={isSubmitting || isDeleting}
                value={translate('common.delete')}
                // @ts-ignore
                component={Button}
                fullWidth
                style={{ marginTop: '1rem' }}
              />
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};
