import React, { useCallback, useState, ReactElement } from 'react';
import { useLogout, useTranslate } from 'react-admin';

import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { ListItemIcon, MenuItem, MenuItemProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ConfirmationDialog } from './components/ConfirmationDialog';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  minWidth: theme.spacing(5),
}));

interface Props {
  className?: string;
  redirectTo?: string;
  icon?: ReactElement;
}

/**
 * Logout button component, to be passed to the Admin component
 *
 * Used for the Logout Menu item in the sidebar
 */
export const LogoutButton = React.forwardRef<
  HTMLLIElement,
  Props & MenuItemProps<'li', { button?: true }>
>((props, ref) => {
  const { redirectTo, icon, ...rest } = props;

  const logout = useLogout();
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    logout(undefined, redirectTo ?? '/login');
  }, [logout, redirectTo]);

  const toggle = () => {
    setOpen(!open);
  };

  const translate = useTranslate();

  return (
    <StyledMenuItem onClick={toggle} ref={ref} {...rest}>
      <ConfirmationDialog open={open} onClose={handleClick} />
      <ListItemIcon sx={(theme) => ({ minWidth: theme.spacing(5) })}>
        {icon || <ExitIcon />}
      </ListItemIcon>
      <div>{translate('auth.logout')}</div>
    </StyledMenuItem>
  );
});
