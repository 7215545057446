import { useTranslate } from 'react-admin';

import { Auth } from './Auth';
import { AuthCard } from './components/authCard';
import { useLoginSteps } from './hooks/login';
import { TwoFA } from './TwoFA';

export const Login = () => {
  const translate = useTranslate();
  const { smsSent, handleLoginFA, successCallback } = useLoginSteps();

  if (smsSent) {
    return (
      <AuthCard hint={translate('auth.login.2FA')}>
        <TwoFA onSubmit={handleLoginFA} withTrusted={true} />
      </AuthCard>
    );
  }

  return <Auth successCallback={successCallback} />;
};
