import { usePermissions } from 'react-admin';

import { Roles } from '@boTypes/user';
import { useQuery } from '@hooks/queryWrappers';
import { useDiscussionContext } from '@hooks/useDiscussionContext';

import { DiscussionContext } from '../../common';

export type HandoverGeneration = {
  problem: string;
  observations: string;
  advices: string;
};

export const useHandoverGeneration = (subjectId: number) => {
  const discussionContext = useDiscussionContext();
  const { permissions } = usePermissions();

  const accessFeature =
    permissions?.includes(Roles.ADMIN) ||
    permissions?.includes(Roles.HEALTH_PRO);

  const { data: handoverGeneration, isLoading } = useQuery<
    HandoverGeneration,
    any,
    HandoverGeneration
  >(
    ['handoverGeneration', subjectId],
    () => ({
      method: 'POST',
      url: '/api/handover-generation',
      data: { subjectId },
    }),
    {
      enabled:
        !!subjectId &&
        discussionContext === DiscussionContext.NURSE &&
        accessFeature,
    },
  );

  return { handoverGeneration, isLoading };
};
