import { useTranslate } from 'react-admin';

import { Button, DialogTitle, DialogActions, Dialog } from '@mui/material';

export const ConfirmationDialog = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const translate = useTranslate();

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {translate('auth.confirmationDialog.title')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          {translate('auth.confirmationDialog.disconnect')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
