/* eslint camelcase: "off" */
import { RaRecord } from 'react-admin';

import { Category } from './category';
import { PlanningJob } from './planning';
import { COLORS } from '../themes';

export enum Roles {
  ADMIN = 'admin',
  SUPERUSER = 'superuser',
  STAFF_MANAGER = 'staff-manager',
  HEALTH_PRO = 'health-pro',
  AVAILABILITIES = 'availabilities',
  BO_PUNCTUAL_HELPERS = 'bo-punctual-helpers',
  FORM_EDITOR = 'form-editor',
  AFFILIATIONS_MANAGER = 'affiliations-manager',
  CODE_PUSH_MANAGER = 'code-push-manager',
}

// These values are used in the backend (sic...)
export enum JobTitle {
  ADMIN = 'Admin',
  GENERAL_PRACTITIONER = 'Médecin généraliste',
  PEDIATRICIAN = 'Pédiatre',
  CHILD_PSYCHIATRIST = 'Pédo-psychiatre',
  NUTRITIONIST_DOCTOR = 'Médecin-nutritionniste',
  MIDWIFE = 'Sage-femme',
  NURSERY_NURSE = 'Infirmière puéricultrice',
  PSYCHOLOGIST = 'Psychologue',
}

export const JobTitleToString = {
  [JobTitle.ADMIN]: 'staffUser.job.admin',
  [JobTitle.GENERAL_PRACTITIONER]: 'staffUser.job.generalPractitioner',
  [JobTitle.PEDIATRICIAN]: 'staffUser.job.pediatrician',
  [JobTitle.CHILD_PSYCHIATRIST]: 'staffUser.job.childPsychiatrist',
  [JobTitle.NUTRITIONIST_DOCTOR]: 'staffUser.job.nutritionistDoctor',
  [JobTitle.MIDWIFE]: 'staffUser.job.midwife',
  [JobTitle.NURSERY_NURSE]: 'staffUser.job.nurseryNurse',
  [JobTitle.PSYCHOLOGIST]: 'staffUser.job.psychologist',
};

export const healthProJobs = Object.values(JobTitle).filter(
  (job) => job !== JobTitle.ADMIN,
);

export const doctorJobs: JobTitle[] = Object.values(JobTitle).filter(
  (job) =>
    job !== JobTitle.ADMIN &&
    job !== JobTitle.MIDWIFE &&
    job !== JobTitle.NURSERY_NURSE,
);

export const isDoctorJob = (
  jobTitle: JobTitle,
): jobTitle is (typeof doctorJobs)[number] => doctorJobs.includes(jobTitle);

const titleToNumber: Record<JobTitle, number> = {
  [JobTitle.GENERAL_PRACTITIONER]: 3,
  [JobTitle.PEDIATRICIAN]: 3,
  [JobTitle.CHILD_PSYCHIATRIST]: 3,
  [JobTitle.NUTRITIONIST_DOCTOR]: 3,
  [JobTitle.MIDWIFE]: 2,
  [JobTitle.NURSERY_NURSE]: 1,
  [JobTitle.PSYCHOLOGIST]: 3,
  [JobTitle.ADMIN]: 4,
};

export const titleToPlanningJob: Record<JobTitle, PlanningJob | 'all'> = {
  [JobTitle.GENERAL_PRACTITIONER]: PlanningJob.DOCTOR,
  [JobTitle.PEDIATRICIAN]: PlanningJob.DOCTOR,
  [JobTitle.CHILD_PSYCHIATRIST]: PlanningJob.DOCTOR,
  [JobTitle.NUTRITIONIST_DOCTOR]: PlanningJob.DOCTOR,
  [JobTitle.MIDWIFE]: PlanningJob.MIDWIFE,
  [JobTitle.NURSERY_NURSE]: PlanningJob.NURSE,
  [JobTitle.PSYCHOLOGIST]: PlanningJob.DOCTOR,
  [JobTitle.ADMIN]: 'all',
};

export const nurseDiscussionJobs = [
  JobTitle.GENERAL_PRACTITIONER,
  JobTitle.PEDIATRICIAN,
  JobTitle.CHILD_PSYCHIATRIST,
  JobTitle.NUTRITIONIST_DOCTOR,
  JobTitle.PSYCHOLOGIST,
  JobTitle.NURSERY_NURSE,
];
export const midwifeDiscussionJobs = [JobTitle.MIDWIFE];

export const planningJobToTitle: Record<PlanningJob, JobTitle[]> = Object.keys(
  titleToPlanningJob,
).reduce(
  (acc, jobTitle) => {
    const planningJob = titleToPlanningJob[jobTitle];
    if (planningJob !== 'all') {
      acc[planningJob] = acc[planningJob] || [];
      acc[planningJob].push(jobTitle);
    }
    return acc;
  },
  {} as Record<PlanningJob, JobTitle[]>,
);

export const titleToColor: Record<JobTitle, string> = {
  [JobTitle.GENERAL_PRACTITIONER]: COLORS.BLUE[200],
  [JobTitle.PEDIATRICIAN]: COLORS.BLUE[200],
  [JobTitle.CHILD_PSYCHIATRIST]: COLORS.BLUE[200],
  [JobTitle.NUTRITIONIST_DOCTOR]: COLORS.BLUE[200],
  [JobTitle.MIDWIFE]: COLORS.PINK[200],
  [JobTitle.NURSERY_NURSE]: COLORS.GREEN[200],
  [JobTitle.PSYCHOLOGIST]: COLORS.BLUE[200],
  [JobTitle.ADMIN]: COLORS.GREY[200],
};

export const jobTitleSort = (a: JobTitle, b: JobTitle) =>
  titleToNumber[a] === titleToNumber[b]
    ? 0
    : titleToNumber[a] > titleToNumber[b]
      ? 1
      : -1;
export interface User extends RaRecord {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: JobTitle;
  expertiseAreaIds?: Category['id'][];
  comfortAreaIds?: Category['id'][];
  avatar?: string;
  [key: string]: any;
}

export interface DiscussionUserMapping {
  [id: string]: string[];
}
