import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { Identifier, useNotify } from 'react-admin';

import { HandoverRevive } from '@boTypes/handover';
import { titleToPlanningJob } from '@boTypes/user';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useQueryWithUpdater,
  UseQueryOptions,
} from '@hooks/queryWrappers';

import { useByIds } from './useByIds';
import { useSelector } from '../store';
import {
  Planning,
  PlanningAdditionalHours,
  PlanningAttribution,
  PlanningAvailabilitiesCreate,
  PlanningCondensedAvailabilities,
  PlanningDetail,
  PlanningJob,
  PlanningPreferences,
  PlanningStatus,
  PlanningUpdateStatus,
  UserInvoiceData,
  UserPlanningAvailabilities,
} from '../types/planning';
import { Slot, SlotCreate, SlotsCreate } from '../types/slot';

const getFilter = ({
  status,
  job,
  date,
}: {
  status: PlanningStatus | 'all';
  job: PlanningJob | 'all';
  date?: Date;
}) => {
  let hasFilters = false;
  const filters = {} as {
    status?: PlanningStatus | 'all';
    job?: PlanningJob | 'all';
    date?: Date;
  };
  if (status && status !== 'all') {
    filters.status = status;
    hasFilters = true;
  }
  if (job && job !== 'all') {
    filters.job = job;
    hasFilters = true;
  }
  if (date) {
    filters.date = date;
    hasFilters = true;
  }
  return hasFilters ? encodeURIComponent(JSON.stringify(filters)) : undefined;
};

export const planningRequest = ({ status, job, date }) => {
  const filter = getFilter({
    status,
    job,
    date,
  });
  return {
    url: `/api/planning${filter ? `?filter=${filter}` : ''}`,
    method: 'get',
  };
};

export const createAdditionalHourRequest = (
  planningId: string,
  data: Omit<PlanningAdditionalHours, 'id'>,
) => ({
  method: 'POST',
  url: `/api/planning/${planningId}/additionalHours`,
  data,
});

export const usePlannings = (
  {
    status,
    job,
    date,
  }: {
    status?: PlanningStatus | 'all';
    job?: PlanningJob | 'all';
    date?: Date;
  },
  options?: { enabled: boolean },
) => {
  return useQuery<Planning[], AxiosError>(
    ['planningList', status, job, date],
    () => planningRequest({ status, job, date }),
    options,
  );
};

export const usePlanning = (
  planningId: string,
  options: Omit<
    UseQueryOptions<Planning, AxiosError>,
    'queryFn' | 'queryKey'
  > = {},
) => {
  return useQuery<Planning, AxiosError>(
    ['plannings', planningId],
    () => ({
      url: `/api/planning/${planningId}`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};

export const usePlanningInvoices = (
  planningId: string,
  options: Omit<
    UseQueryOptions<Record<string, UserInvoiceData>, AxiosError>,
    'queryFn' | 'queryKey'
  > = {},
) => {
  return useQuery<Record<string, UserInvoiceData>, AxiosError>(
    ['invoices', planningId],
    () => ({
      url: `/api/billing/invoices/${planningId}`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};

export const useSendSlots = (planningId: string) => {
  return useMutation<Planning, any, SlotsCreate>(
    ['plannings', planningId],
    (args) => ({
      url: `/api/planning/${planningId}/slots`,
      method: 'post',
      data: args,
    }),
  );
};
export const useDeletePlanning = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, unknown, string>(
    ['planningDelete'],
    (id: string) => ({
      url: `/api/planning/${id}`,
      method: 'delete',
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['planningList'] });
      },
    },
  );
};

export const useUpdatePlanningStatus = (planning: Planning) => {
  const queryClient = useQueryClient();
  return useMutation<Planning, unknown, PlanningUpdateStatus>(
    ['planningStatus', planning.id],
    (data) => ({
      method: 'put',
      url: `/api/planning/${planning.id}`,
      data,
    }),
    {
      onSuccess: (data: Planning) => {
        // get all planning list keys
        const modifiedKeys = queryClient
          .getQueriesData<Planning[]>({ queryKey: ['planningList'] })
          .filter(([_, plannings = []]) =>
            plannings.some((p) => p.id === planning.id),
          )
          .map(([key]) => key);

        modifiedKeys.forEach((key) => {
          queryClient.setQueryData<Planning[]>(
            key,
            (previousList: Planning[]) => {
              return previousList.map((p) => {
                if (p.id === planning.id) {
                  return {
                    ...p,
                    ...data,
                  };
                }
                return p;
              });
            },
          );
        });
      },
    },
  );
};

export const usePlanningDetail = (
  planningId: string,
  options?: UseQueryOptions<PlanningDetail, AxiosError>,
) => {
  return useQueryWithUpdater<PlanningDetail, AxiosError>(
    ['planningDetail', planningId],
    () => ({
      url: `/api/planning/${planningId}/detail`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};

export const useAttributions = (
  filters: {
    begin: string;
    end: string;
    job?: PlanningJob[];
    staffUserId?: Identifier;
  },
  options?: Omit<
    UseQueryOptions<(PlanningAttribution & { slot?: Slot })[], AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQueryWithUpdater<
    (PlanningAttribution & { slot?: Slot })[],
    AxiosError
  >(
    ['intervalAttributions', filters],
    () => ({
      url: '/api/planning/attributions/interval',
      method: 'get',
      params: {
        filter: JSON.stringify(filters),
      },
    }),
    options,
  );
};

export const useFutureRevives = () =>
  useQuery<HandoverRevive[]>(['futureRevives'], () => ({
    url: '/api/handoverRevives/allNext',
    method: 'get',
  }));

export const useIntervalAttributions = (
  jobs?: PlanningJob[],
  options?: Omit<
    UseQueryOptions<PlanningAttribution[], AxiosError>,
    'queryKey' | 'queryFn'
  >,
  range?: [string, string],
) => {
  const [beginDate, endDate] = range;
  return useAttributions(
    { begin: beginDate, end: endDate, job: jobs },
    options,
  );
};

export const useIntervalAttributionsAndUpdaters = ({
  slotsById,
  userId,
  jobs,
  options,
  range,
}: {
  slotsById: Record<Slot['id'], Slot>;
  userId: number;
  date?: Date;
  jobs?: PlanningJob[];
  options?: UseQueryOptions<PlanningAttribution[], AxiosError>;
  range?: [string, string];
}) => {
  const queryRes = useIntervalAttributions(jobs, options, range);
  const { createAttribution, deleteAttribution } = useAttributionMutations();
  const onLeaveAttribution = useCallback(
    (
      slotId: string,
      { onSuccess, onError, onSettled } = {} as {
        onSuccess?: () => void;
        onError?: () => void;
        onSettled?: () => void;
      },
    ) => {
      const slot = slotsById[slotId];
      deleteAttribution(
        {
          planningId: slot.planningId,
          slotId: Number(slotId),
          staffUserId: userId,
        },
        {
          onError,
          onSettled,
          onSuccess() {
            queryRes.setCache((previousAttributions) => {
              return previousAttributions?.filter(
                ({ slotId: attSlotId }) => `${attSlotId}` !== `${slotId}`,
              );
            });

            if (onSuccess) {
              onSuccess();
            }
          },
        },
      );
    },
    [deleteAttribution, queryRes, slotsById, userId],
  );

  const onRequestAttribution = useCallback(
    (
      slotId: string,
      { onSuccess, onError, onSettled } = {} as {
        onSuccess?: () => void;
        onError?: () => void;
        onSettled?: () => void;
      },
    ) => {
      const slot = slotsById[slotId];
      createAttribution(
        {
          planningId: slot.planningId,
          slotId: Number(slotId),
          staffUserId: userId,
        },
        {
          onError,
          onSettled,
          onSuccess(attribution) {
            queryRes.setCache((previousAttributions) => {
              const next = previousAttributions.filter(
                ({ staffUserId, slotId: attSlotId }) =>
                  staffUserId !== userId ||
                  Number(slotId) !== Number(attSlotId),
              );
              next.push(attribution);
              return next;
            });
            if (onSuccess) {
              onSuccess();
            }
          },
        },
      );
    },
    [createAttribution, queryRes, slotsById, userId],
  );

  return { ...queryRes, onLeaveAttribution, onRequestAttribution };
};

export const useIntervalAdditionalHours = (
  jobs?: PlanningJob[],
  range?: [string, string],
) => {
  const [beginDate, endDate] = range;
  return useQueryWithUpdater<PlanningAdditionalHours[], AxiosError>(
    ['intervalAdditionalHours', beginDate, endDate, ...(jobs ?? [])],
    () => ({
      url: `/api/planning/additionalHours/interval?filter=${encodeURIComponent(
        JSON.stringify({ begin: beginDate, end: endDate, job: jobs }),
      )}`,
      method: 'get',
    }),
  );
};

export const useIntervalSlots = (
  jobs?: PlanningJob[],
  options?: Omit<UseQueryOptions<Slot[], AxiosError>, 'queryKey' | 'queryFn'>,
  range?: [string, string],
) => {
  const [beginDate, endDate] = range;
  return useQueryWithUpdater<Slot[], AxiosError>(
    ['slots', beginDate, endDate, ...(jobs ?? [])],
    () => ({
      url: `/api/planning/slots/interval?filter=${encodeURIComponent(
        JSON.stringify({ begin: beginDate, end: endDate, job: jobs }),
      )}`,
      method: 'get',
    }),
    options,
  );
};

export const useCondensedAvailabilities = (
  planningId: string,
  options?: UseQueryOptions<
    Record<Identifier, PlanningCondensedAvailabilities>,
    AxiosError
  >,
) => {
  return useQuery<
    Record<Identifier, PlanningCondensedAvailabilities>,
    AxiosError
  >(
    ['planningCondensedAvailabilities', planningId],
    () => ({
      url: `/api/planning/${planningId}/availabilities`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};

export const useAllPreferences = (
  planningId: string,
  options?: UseQueryOptions<PlanningPreferences[], AxiosError>,
) => {
  return useQuery<PlanningPreferences[], AxiosError>(
    ['allPlanningPreferences', planningId],
    () => ({
      url: `/api/planning/${planningId}/preferences/all`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};

export const useAllAvailabilities = (
  planningId: string,
  options?: UseQueryOptions<UserPlanningAvailabilities[], AxiosError>,
) => {
  return useQuery<UserPlanningAvailabilities[], AxiosError>(
    ['allPlanningAvailabilities', planningId],
    () => ({
      url: `/api/planning/${planningId}/availabilities/all`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};

export const usePlanningAttributions = (
  planningId: string,
  options?: { enabled: boolean },
) => {
  return useQueryWithUpdater<PlanningAttribution[], AxiosError>(
    ['planningAttributions', planningId],
    () => ({
      url: `/api/planning/${planningId}/attributions`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};

export const usePlanningAdditionalHours = (
  planningId: string,
  options?: { enabled: boolean },
) => {
  return useQueryWithUpdater<PlanningAdditionalHours[], AxiosError>(
    ['planningAdditionalHours', planningId],
    () => ({
      url: `/api/planning/${planningId}/additionalHours`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};

export const useSetAvailabilities = (
  planningId: string,
  options: { onSuccess?: () => void } = {},
) => {
  const queryClient = useQueryClient();
  const notify = useNotify();
  return useMutation<PlanningDetail, unknown, PlanningAvailabilitiesCreate>(
    ['planningDetail', planningId],
    (data) => ({
      method: 'post',
      url: `/api/planning/${planningId}/availabilities`,
      data,
    }),
    {
      onSuccess: (data: PlanningDetail) => {
        queryClient.setQueryData<PlanningDetail>(
          ['planningDetail', planningId],
          data,
        );
        options?.onSuccess?.();
        notify('common.successfulUpdate', { type: 'success' });
      },
      onError: () => {
        notify('common.genericError', { type: 'warning' });
      },
    },
  );
};

export const usePlanningPreferences = <T = PlanningPreferences>(
  planningId: string,
  options?: UseQueryOptions<PlanningPreferences, unknown, T>,
) => {
  return useQuery<PlanningPreferences, unknown, T>(
    ['planningPreferences', planningId],
    () => ({
      url: `/api/planning/${planningId}/preferences`,
      method: 'get',
    }),
    {
      enabled: !!planningId,
      ...options,
    },
  );
};
export const useSetPlanningPreferences = (planningId: string) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  return useMutation<PlanningPreferences, any, PlanningPreferences>(
    ['setPlanningPreferences', planningId],
    (data) => ({
      url: `/api/planning/${planningId}/preferences`,
      method: 'put',
      data,
    }),
    {
      onSuccess: (data) => {
        notify('common.successfulUpdate', { type: 'success' });
        queryClient.setQueryData<PlanningPreferences>(
          ['planningPreferences', planningId],
          data,
        );
      },
      onError: () => {
        notify('common.error.generic', { type: 'warning' });
      },
    },
  );
};

export const useAttributionMutations = () => {
  const notify = useNotify();
  const { mutate: createAttribution } = useMutation<
    PlanningAttribution,
    any,
    PlanningAttribution & { planningId: string }
  >(['createAttribution'], ({ planningId, ...data }) => ({
    method: 'PUT',
    url: `/api/planning/${planningId}/attribution`,
    data,
  }));

  const { mutate: deleteAttribution } = useMutation<
    HandoverRevive[],
    any,
    Omit<PlanningAttribution, 'onCallActivated'> & { planningId: string }
  >(
    ['deleteAttribution'],
    ({ planningId, ...data }) => ({
      method: 'DELETE',
      url: `/api/planning/${planningId}/attribution`,
      data,
    }),
    {
      onSuccess: (revives) => {
        if (revives.length) {
          notify('revives.pendingRevivesBeforeSlotCancel', {
            messageArgs: {
              smart_count: revives.length,
            },
            type: 'warning',
          });
        }
      },
    },
  );

  return { createAttribution, deleteAttribution };
};

export const useAdditionalHoursMutations = () => {
  const queryClient = useQueryClient();
  const { mutate: createAdditionalHour } = useMutation<
    PlanningAdditionalHours,
    any,
    Omit<PlanningAdditionalHours, 'id'> & { planningId: string }
  >(['createAdditionalHour'], ({ planningId, ...data }) =>
    createAdditionalHourRequest(planningId, data),
  );

  const {
    mutate: updateAdditionalHour,
    mutateAsync: updateAdditionalHourAsync,
  } = useMutation<
    PlanningAdditionalHours,
    any,
    PlanningAdditionalHours & { planningId: string }
  >(['updateAdditionalHour'], ({ id, planningId, ...data }) => ({
    method: 'PUT',
    url: `/api/planning/${planningId}/additionalHours/${id}`,
    data,
  }));

  const { mutate: deleteAdditionalHour } = useMutation<
    { id: number },
    any,
    { id: Identifier; planningId: string }
  >(
    ['deleteAdditionalHour'],
    ({ id, planningId }) => ({
      method: 'DELETE',
      url: `/api/planning/${planningId}/additionalHours/${id}`,
    }),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData<PlanningAdditionalHours[]>(
          { queryKey: ['intervalAdditionalHours'] },
          (previousData) => {
            return previousData?.filter((d) => d.id !== data.id);
          },
        );
      },
    },
  );

  return {
    createAdditionalHour,
    updateAdditionalHour,
    updateAdditionalHourAsync,
    deleteAdditionalHour,
  };
};

export const usePlanningMutations = (planningId: string) => {
  const { mutate: deleteSlot } = useMutation<{}, any, string>(
    ['deleteSlot', planningId],
    (slotId: string) => ({
      method: 'DELETE',
      url: `/api/planning/${planningId}/slot/${slotId}`,
    }),
  );

  const { mutate: createSlot } = useMutation<Slot, any, SlotCreate>(
    ['createSlot', planningId],
    (data) => ({
      method: 'POST',
      url: `/api/planning/${planningId}/slot`,
      data,
    }),
  );

  const { mutate: updateSlot } = useMutation<
    Slot,
    any,
    SlotCreate & { slotId: number }
  >(['updateSlot', planningId], ({ slotId, ...data }) => ({
    method: 'PUT',
    url: `/api/planning/${planningId}/slot/${slotId}`,
    data,
  }));

  const {
    createAttribution: rawCreateAttribution,
    deleteAttribution: rawDeleteAttribution,
  } = useAttributionMutations();
  const createAttribution = useCallback(
    (
      data: PlanningAttribution,
      options: Parameters<typeof rawCreateAttribution>[1],
    ) => rawCreateAttribution({ ...data, planningId }, options),
    [planningId, rawCreateAttribution],
  );
  const deleteAttribution = useCallback(
    (
      data: Omit<PlanningAttribution, 'onCallActivated'>,
      options: Parameters<typeof rawDeleteAttribution>[1],
    ) => rawDeleteAttribution({ ...data, planningId }, options),
    [planningId, rawDeleteAttribution],
  );

  const {
    createAdditionalHour: rawCreateAdditionalHour,
    updateAdditionalHour: rawUpdateAdditionalHour,
    deleteAdditionalHour: rawDeleteAdditionalHour,
  } = useAdditionalHoursMutations();
  const createAdditionalHour = useCallback(
    (
      data: Omit<PlanningAdditionalHours, 'id'>,
      options: Parameters<typeof rawCreateAdditionalHour>[1],
    ) => rawCreateAdditionalHour({ ...data, planningId }, options),
    [planningId, rawCreateAdditionalHour],
  );
  const updateAdditionalHour = useCallback(
    (
      data: PlanningAdditionalHours,
      options: Parameters<typeof rawUpdateAdditionalHour>[1],
    ) => rawUpdateAdditionalHour({ ...data, planningId }, options),
    [planningId, rawUpdateAdditionalHour],
  );
  const deleteAdditionalHour = useCallback(
    (id: Identifier, options: Parameters<typeof rawDeleteAdditionalHour>[1]) =>
      rawDeleteAdditionalHour({ id, planningId }, options),
    [planningId, rawDeleteAdditionalHour],
  );

  return {
    deleteSlot,
    createSlot,
    updateSlot,
    createAttribution,
    deleteAttribution,
    createAdditionalHour,
    deleteAdditionalHour,
    updateAdditionalHour,
  };
};

const slotMargin = 1000 * 60 * 15;
export const useMyCurrentSlot = () => {
  const userId = useSelector((state) => state.user?.userId);
  const planningJob = useSelector((state) =>
    state.user?.jobTitle ? titleToPlanningJob[state.user.jobTitle] : undefined,
  );
  const range = useMemo<[string, string]>(() => {
    return [
      dayjs().startOf('day').toISOString(),
      dayjs().endOf('day').toISOString(),
    ];
  }, []);
  const { data: slots } = useIntervalSlots(
    [planningJob as PlanningJob],
    {
      enabled: !!planningJob && planningJob !== 'all',
    },
    range,
  );
  const slotsById = useByIds(slots);
  const { data: attributions } = useIntervalAttributions(
    [planningJob as PlanningJob],
    {
      enabled: !!planningJob && planningJob !== 'all',
    },
    range,
  );

  return useMemo(() => {
    const now = Date.now();

    return attributions
      ?.filter(
        ({ staffUserId, slotId }) =>
          staffUserId === userId && slotsById[Number(slotId)],
      )
      .map(({ slotId }) => slotsById[Number(slotId)])
      .filter(
        ({ start, end }) =>
          now > new Date(start).getTime() - slotMargin &&
          now < new Date(end).getTime() + slotMargin,
      )[0];
  }, [attributions, slotsById, userId]);
};
