import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const librarySearchSlice = createSlice({
  name: 'librarySearch',
  initialState: '' as string,
  reducers: {
    setReduxSearch(
      _state,
      { payload: { search } }: PayloadAction<{ search: string }>,
    ) {
      return search ?? '';
    },
  },
});

export const { setReduxSearch } = librarySearchSlice.actions;

export default librarySearchSlice.reducer;
