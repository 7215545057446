import { i18nProvider } from '../App';

export const CANCELED = 'Annulé';
export const CLOSED = 'Clôturé';
export const TO_DO = 'A traiter';
export const MINE = 'Mes consultations';

export const consultationTabs = () => [
  { id: TO_DO, name: i18nProvider.translate('consultations.tabs.todo') },
  { id: CLOSED, name: i18nProvider.translate('consultations.tabs.closed') },
  { id: CANCELED, name: i18nProvider.translate('consultations.tabs.canceled') },
  { id: MINE, name: i18nProvider.translate('consultations.tabs.mine') },
];
