import {
  DateInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import { FormProps } from '../../types';
import { sexChoices } from '../common';

export const ChildrenForm = (props: FormProps) => {
  const translate = useTranslate();
  return (
    <SimpleForm {...props}>
      <TextInput
        label={translate('common.firstName')}
        source="firstName"
        validate={required()}
        variant="standard"
      />
      <TextInput
        label={translate('common.name')}
        source="lastName"
        variant="standard"
      />
      <SelectInput
        label={translate('common.sex')}
        source="sex"
        choices={sexChoices()}
        validate={required()}
        variant="standard"
      />
      <DateInput
        label={translate('common.birthDay')}
        source="birthDay"
        validate={required()}
        variant="standard"
      />
    </SimpleForm>
  );
};
