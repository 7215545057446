import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  ReferenceField,
  ReferenceInput,
  TextField,
  useTranslate,
} from 'react-admin';

import { GoToDiscussionField } from '@components/fields/RelatedDiscussionURLField';

import { List } from '../../components/generic/List';

const PregnanciesFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <ReferenceInput source="appUser" reference="appUsers" alwaysOn>
        <AutocompleteInput
          variant="standard"
          optionText={(record) =>
            record?.name ? `${record.name} (${record.email})` : '---'
          }
          label={translate('common.parent')}
        />
      </ReferenceInput>
    </Filter>
  );
};

export const PregnanciesList = () => {
  const translate = useTranslate();

  return (
    <List
      filters={<PregnanciesFilter />}
      sx={{ minHeight: '100%', padding: '0 1rem' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <DateField
          locales="fr-FR"
          source="expectedEnd"
          label={translate('common.expectedEnd')}
        />
        <ReferenceField
          source="appUserId"
          reference="appUsers"
          label={translate('common.parent')}
        >
          <TextField source="email" />
        </ReferenceField>
        <BooleanField source="active" label={translate('common.active')} />
        <BooleanField
          source="interrupted"
          label={translate('pregnancy.interrupted')}
        />
        <BooleanField source="born" label={translate('common.born')} />
        <BooleanField source="multiple" label={translate('common.multiple')} />
        <GoToDiscussionField />
        <EditButton />
      </Datagrid>
    </List>
  );
};
