import { useTranslate } from 'react-admin';

import { Row } from '@components/generic/Row';
import Add from '@mui/icons-material/Add';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Typography } from '@mui/material';
import {
  BooleanOperator,
  Condition,
  Question,
  Template,
} from '@teammay/form-core';

import { isHideConditionValid } from '../../../utils/validityCheck';
import {
  ConditionForm,
  HideConditionEdit,
} from '../../conditions/ConditionEdit';
import { EditWrapper } from '../../EditWrapper';
import { WarningTile } from '../../WarningTile';

export function DisplayButton({
  content,
  questions,
  setCondition,
}: {
  questions: Question[];
  setCondition: (cond: ConditionForm) => void;
} & (
  | {
      type: 'question';
      content: Partial<Question>;
    }
  | {
      type: 'template';
      content: Partial<Template>;
    }
)) {
  const translate = useTranslate();
  const hasHideCondition = !!content?.hideCondition;
  const invalidFields = isHideConditionValid(content?.hideCondition, questions);

  if (questions?.length === 0) {
    return null;
  }

  return (
    <>
      {hasHideCondition ? (
        <Row
          onClick={() => setCondition(content.hideCondition)}
          sx={{ cursor: 'pointer' }}
        >
          <WarningTile invalidFields={invalidFields} />
          <>
            <VisibilityOff color="primary" sx={{ fontSize: '0.625rem' }} />
            <Typography
              color="primary"
              variant="body2"
              sx={{
                fontSize: '0.625rem',
                marginLeft: '0.25rem',
              }}
            >
              {translate('forms.display.title')}
            </Typography>
          </>
        </Row>
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            cursor: 'pointer',
          }}
        >
          <Add sx={{ fontSize: '0.625rem' }} />
          <Typography
            onClick={() =>
              setCondition({
                id: self.crypto.randomUUID(),
                booleanOperator: BooleanOperator.AND,
                questionRules: [],
                dataRules: [],
                children: [],
              })
            }
            variant="caption"
            sx={{
              cursor: 'pointer',
              fontSize: '0.625rem',
              marginLeft: '0.25rem',
            }}
          >
            {translate('forms.display.title')}
          </Typography>
        </Box>
      )}
    </>
  );
}

export const DisplayEdit = ({
  content,
  condition,
  setCondition,
  onClick,
  targetObject,
}: {
  condition: ConditionForm;
  setCondition: (condition: ConditionForm) => void;
  onClick: (condition: Condition) => void;
  targetObject: { type: 'question' | 'template' | 'step'; id: string };
} & (
  | {
      type: 'question';
      content: Partial<Question>;
    }
  | {
      type: 'template';
      content: Partial<Template>;
    }
)) => {
  return (
    <EditWrapper
      content={condition as ConditionForm}
      onSave={(_content) => {
        onClick({ id: condition.id, ..._content });
        content.hideCondition = _content;
        setCondition(null);
      }}
      open={!!condition}
      onCancel={() => setCondition(null)}
      onDelete={() => {
        content.hideCondition = null;
        onClick(null);
        setCondition(null);
      }}
      FormComponent={HideConditionEdit}
      defaultValues={{ booleanOperator: BooleanOperator.AND }}
      targetObject={targetObject}
    />
  );
};
