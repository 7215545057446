import React from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { Link, Typography } from '@mui/material';

import { useResetPasswordSendMail } from './hook';
import { TextInput } from '../../components/forms/textInput';
import { Button } from '../../components/generic/Button';
import { AuthFormContainer } from '../components/styled';

export const ResetForm = ({
  reset,
}: {
  reset: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const sendEmail = useResetPasswordSendMail();
  const { handleSubmit, control, formState } = useForm();
  const { isSubmitting } = formState;
  const translate = useTranslate();

  return (
    <>
      <form onSubmit={handleSubmit(sendEmail)} noValidate>
        <AuthFormContainer>
          <TextInput
            control={control}
            disabled={isSubmitting}
            name="email"
            label={translate('auth.email')}
            rules={{ required: true, pattern: /.+@.+\..+/ }}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            sx={{ marginTop: '2rem' }}
            loading={isSubmitting}
            value={translate('auth.resetPassword.sendEmail')}
          />
        </AuthFormContainer>
      </form>
      <Typography sx={{ textAlign: 'center', margin: '0.25rem' }}>
        <Link
          onClick={() => reset(false)}
          sx={{
            fontSize: '0.75rem',
            cursor: 'pointer',
          }}
          underline="hover"
        >
          {translate('auth.backToLogin')}
        </Link>
      </Typography>
    </>
  );
};
