import { useTranslate } from 'react-admin';

import { Videocam } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { Tokbox } from './tokbox';
import { ConsultationWithRelationIds } from '../../types';

export const TokboxHandler = ({
  startVisio,
  visioCode,
  openVisio,
}: {
  startVisio: () => void;
  visioCode: ConsultationWithRelationIds['visioCode'];
  openVisio: boolean;
}) => {
  const translate = useTranslate();

  return (
    <Box>
      <Button
        startIcon={<Videocam />}
        variant="contained"
        onClick={startVisio}
        color="primary"
      >
        {translate('consultations.startConsultation')}
      </Button>
      {openVisio && <Tokbox visioCode={visioCode} openVisio={openVisio} />}
    </Box>
  );
};
