import { Create, useTranslate } from 'react-admin';

import { ScenariosForm } from './form';

export const ScenariosCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create
      {...props}
      resource="scenarios"
      redirect="list"
      title={translate('scenario.add')}
    >
      <ScenariosForm />
    </Create>
  );
};
