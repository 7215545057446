import { Datagrid, DateField, TextField, useTranslate } from 'react-admin';

import { List } from '../../components/generic/List';

export const ScenariosList = (props) => {
  const translate = useTranslate();

  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label={translate('common.name')} />
        <DateField
          locales="fr-FR"
          source="created"
          label={translate('scenario.created')}
        />
      </Datagrid>
    </List>
  );
};
