import { usePermissions, useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import Calendar from '@components/Calendar';
import { Loader } from '@components/Loader';
import { Paper, Typography } from '@mui/material';

import { useConsultationPlanning } from './consultationPlanning.hook';
import { useSelector } from '../store';

const calendarStyles = {
  height: '200%',
  minHeight: '200%',
};

export const ConsultationPlanning = () => {
  const translate = useTranslate();
  const { events, isLoading, staffUsers, create, remove, update } =
    useConsultationPlanning();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN);
  const userId = useSelector((state) => state.user.userId);

  return (
    <Paper
      elevation={0}
      sx={{
        overflow: 'auto',
        height: '100%',
        paddingX: 2,
      }}
    >
      <Typography
        variant="h5"
        color="primary"
        sx={{ fontWeight: 'bold', marginBottom: 1, marginTop: 1 }}
      >
        {translate('consultations.planning.title')}
      </Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <Calendar
          events={events}
          staffUsers={staffUsers}
          options={{ styles: calendarStyles, timeslots: 2, step: 30 }}
          drawerCallback={{
            create,
            update,
            remove,
          }}
          displayActions={{
            update: (s) => isAdmin || s.staffUserId === userId,
            delete: (s) => isAdmin || s.staffUserId === userId,
          }}
          participantIds={Object.keys(staffUsers).map(Number)}
        />
      )}
    </Paper>
  );
};
