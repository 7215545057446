import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSearchParams } from 'react-router-dom';

import { Roles } from '@boTypes/user';
import {
  Box,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import List from './list';
import Summary from './summary';
import { useSelector } from '../../store';

export enum DISPLAY {
  SUMMARY = 'summary',
  LIST = 'list',
}

export const AdminInvoices = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);
  const [display, setDisplay] = useState('summary');
  const [_, setSearchParams] = useSearchParams();

  const setDisplayAndReset = (_display: DISPLAY) => {
    if (_display === DISPLAY.SUMMARY) {
      const params = new URLSearchParams();
      setSearchParams(params);
    }
    setDisplay(_display);
  };

  if (!roles.includes(Roles.ADMIN)) {
    return;
  }

  return (
    <Paper
      sx={{
        minHeight: '100%',
        padding: '1rem',
        zIndex: 2,
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 2,
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
          {translate('invoice.admin.title')}
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={display}
          exclusive
          onChange={(e) => {
            setDisplayAndReset(
              (e.target as HTMLButtonElement).value as DISPLAY,
            );
          }}
          aria-label="Display"
        >
          <ToggleButton value={DISPLAY.SUMMARY}>
            {translate('invoice.admin.summary')}
          </ToggleButton>
          <ToggleButton value={DISPLAY.LIST}>
            {translate('invoice.admin.list')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {display === DISPLAY.SUMMARY && (
        <Summary setDisplay={setDisplayAndReset} />
      )}
      {display === DISPLAY.LIST && <List />}
    </Paper>
  );
};
