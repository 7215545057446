import { useTranslate } from 'react-admin';

import { Menu, MenuItem, MenuProps } from '@mui/material';
import { DefaultRawMessage } from '@teammay/chat-core';
import { BOMessageTypes } from '@teammay/mayssenger/lib/typescript/src/components/messageCollection';

import { useSelector } from '../../../store';

export enum MenuOptions {
  COPY = 'COPY',
  DELETE = 'DELETE',
}

const OPTIONS_TO_TEXT: Record<MenuOptions, string> = {
  [MenuOptions.COPY]: 'common.copy',
  [MenuOptions.DELETE]: 'common.delete',
};

const optionsGenerator = (
  message: BOMessageTypes | DefaultRawMessage,
  readonly = false,
  userId: string,
): MenuOptions[] => {
  const options: MenuOptions[] = [];
  if (message.type !== 'livi') {
    options.push(MenuOptions.COPY);
  }
  if (
    (message.participantId === userId ||
      message.type === 'media' ||
      message.type === 'content') &&
    !readonly
  ) {
    options.push(MenuOptions.DELETE);
  }
  return options;
};

export const ContextMenu = ({
  message,
  readonly = false,
  onItemPressed,
  ...menuProps
}: {
  message: BOMessageTypes | DefaultRawMessage;
  readonly?: boolean;
  onItemPressed: (
    item: BOMessageTypes | DefaultRawMessage,
    option: MenuOptions,
  ) => void;
  onClose: () => void;
} & Omit<MenuProps, 'children' | 'onClose'>) => {
  const translate = useTranslate();
  const userId = useSelector((state) => state.user.email);
  const options = optionsGenerator(message, readonly, userId);

  return (
    <Menu {...menuProps}>
      {options.map((option) => (
        <MenuItem
          key={option}
          onClick={() => {
            onItemPressed(message, option);
            menuProps.onClose?.();
          }}
        >
          {translate(OPTIONS_TO_TEXT[option])}
        </MenuItem>
      ))}
    </Menu>
  );
};
