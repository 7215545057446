import {
  BooleanField,
  Datagrid,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin';

import { List } from '../../components/generic/List';

const PlanFilters = [<SearchInput source="q" alwaysOn variant="standard" />];

export const PlanList = (props) => {
  const translate = useTranslate();

  return (
    <List {...props} filters={PlanFilters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label={translate('common.name')} />
        <TextField
          source="description"
          label={translate('common.description')}
        />
        <BooleanField source="active" label={translate('common.active')} />
      </Datagrid>
    </List>
  );
};
