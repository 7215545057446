import { useTranslate } from 'react-admin';

import { AuthCard } from '../components/authCard';
import { TwoFA } from '../TwoFA';
import { useResetPasswordResetSteps } from './hook';
import { ResetPasswordForm } from './ResetPasswordForm';

export const ResetPassword = () => {
  const translate = useTranslate();
  const { smsSent, handleSubmit, successCallback } =
    useResetPasswordResetSteps();

  return smsSent ? (
    <AuthCard hint={translate('auth.resetPassword.twoFA')}>
      <TwoFA onSubmit={handleSubmit} />
    </AuthCard>
  ) : (
    <AuthCard hint={translate('auth.resetPassword.resetPassword')}>
      <ResetPasswordForm successCallback={successCallback} />
    </AuthCard>
  );
};
