import { useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  useTranslate,
  ChipField,
  ReferenceField,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { MarkdownInput } from '@components/forms/markdownInput';
import { Summarize } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import { ConsultationToolbar } from './components/ConsultationToolbar';
import { TherapyInstructions } from './components/therapy';
import { TherapyDetails } from './components/therapy.dto';
import { logViewTherapyOnboardingForm } from '../../analytics/events';

export const WrapperMardownInput = ({
  therapy,
}: {
  therapy: TherapyDetails;
}) => {
  const translate = useTranslate();
  const formContext = useFormContext();

  useEffect(() => {
    const patientEmailContent = formContext?.getValues('patientEmailContent');
    if (therapy && !patientEmailContent) {
      formContext.setValue('patientEmailContent', therapy.proDefaultActionPlan);
    }
  }, [therapy, formContext]);

  return (
    <MarkdownInput
      label={translate('consultations.actionPlan')}
      source="patientEmailContent"
    />
  );
};

export const FormConsultation = ({
  therapy,
  setViewableForm,
}: {
  therapy?: TherapyDetails;
  setViewableForm: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const translate = useTranslate();

  return (
    <Edit component={Box} redirect={false} mutationMode="pessimistic">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
          {translate('consultations.reportShort')}
        </Typography>
        {!therapy ? (
          <ReferenceField
            source="consultationCategoryId"
            reference="consultation-categories"
            link={false}
            label={translate('consultations.category')}
          >
            <ChipField
              source="name"
              label={translate('consultations.category')}
            />
          </ReferenceField>
        ) : (
          <Button
            startIcon={<Summarize />}
            variant="text"
            onClick={() => {
              logViewTherapyOnboardingForm(therapy.therapySlug);
              setViewableForm(true);
            }}
          >
            {translate('consultations.displayOnboardingForm')}
          </Button>
        )}
      </Box>
      <SimpleForm
        toolbar={<ConsultationToolbar />}
        sx={{ padding: 0 }}
        warnWhenUnsavedChanges
      >
        <TextInput
          multiline
          source="report"
          fullWidth
          variant="standard"
          label={translate('consultations.reportShortPro')}
        />
        {therapy ? (
          <>
            <TherapyInstructions therapy={therapy} />
            <WrapperMardownInput therapy={therapy} />
          </>
        ) : (
          <TextInput
            multiline
            label={translate('consultations.patientEmailContent')}
            source="patientEmailContent"
            fullWidth
            variant="standard"
          />
        )}
      </SimpleForm>
    </Edit>
  );
};
