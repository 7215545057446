import {
  AutocompleteInput,
  Create,
  CreateProps,
  ReferenceInput,
  SimpleForm,
  useTranslate,
} from 'react-admin';

export const AppBundleTestersCreate = (props: CreateProps) => {
  const translate = useTranslate();
  return (
    <Create resource="app-bundle-testers" redirect="list" {...props}>
      <SimpleForm>
        <ReferenceInput source="appUserId" reference="patients">
          <AutocompleteInput
            label={translate('common.parents')}
            variant="standard"
            optionText={(record) =>
              record?.name ? `${record.name} (${record.email})` : '---'
            }
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
