import { Create, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { PlanForm } from './form';

export const PlanCreate = () => {
  const location = useLocation();
  const translate = useTranslate();

  return (
    <Create
      resource="plans"
      title={translate('plan.add')}
      redirect={location?.state?.redirect || 'list'}
      sx={{ padding: '1rem' }}
    >
      <PlanForm />
    </Create>
  );
};
