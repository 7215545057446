export const guideTheme = {
  theme1: {
    background: '#DDCAFC',
  },
  theme2: {
    background: '#AADEDA',
  },
  theme3: {
    background: '#FFCA94',
  },
} as const;

export const guideDesignTokens = {
  colors: {
    text: {
      primary: '#073735',
      secondary: '#204B49',
      warning: '#FF5555',
    },
    background: {
      accent: '#FFFFFF99',
    },
    borders: {
      primary: '#07373520',
    },
  },
  fonts: {
    h1: {
      fontFamily: 'Lora',
      fontWeight: '800',
      fontSize: 28,
      lineHeight: 28 * 1.1,
    },
    h2: {
      fontFamily: 'Lora',
      fontWeight: '800',
      fontSize: 22,
      lineHeight: 22 * 1.2,
    },
    h3: {
      fontFamily: 'Lora',
      fontWeight: '800',
      fontSize: 18,
      lineHeight: 18 * 1.4,
    },
    h4: {
      fontFamily: 'Lora',
      fontWeight: '800',
      fontSize: 16,
      lineHeight: 16 * 1.4,
    },
    body: {
      sm: {
        fontFamily: 'Manrope',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: 20,
      },
      md: {
        fontFamily: 'Manrope',
        fontWeight: '800',
        fontSize: 16,
        lineHeight: 24,
      },
    },
  },
} as const;
