import { useTranslate } from 'react-admin';

import { useUsersInRoom } from '@hooks/useUsersInRoom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Tooltip } from '@mui/material';
import { useGateway } from '@utils/gateway';

import { ColoredBadge } from './coloredBadge';
import { useCountAttributions } from './hooks';
import { StyledIconButton } from './styledIconButton';
import { ALL_PRO_ROOM } from '../../pages/subjects';
import { useSelector } from '../../store';
import { COLORS } from '../../themes';
import { AttributionFilter, Discussion } from '../../types/discussion';
import { conditionalColor } from '../../utils/conditionalColor';

const UnattributedBadge = ColoredBadge(COLORS.PINK['200']);

export const AttributedFilter = ({
  filterValue,
  onFilterClick,
  activatedFilter = AttributionFilter.ATTRIBUTED,
}: {
  filterValue: AttributionFilter;
  onFilterClick: (filter: AttributionFilter) => void;
  activatedFilter?: AttributionFilter;
}) => {
  return (
    <Tooltip title="Mes attributions">
      <StyledIconButton onClick={() => onFilterClick(activatedFilter)}>
        <AccountCircleIcon
          sx={{
            color: conditionalColor(filterValue === activatedFilter),
          }}
        />
      </StyledIconButton>
    </Tooltip>
  );
};

export const NotAttributedFilters = ({
  unattributedCount,
  filterValue,
  onFilterClick,
}: {
  unattributedCount: number;
  onFilterClick: (filter: AttributionFilter) => void;
  filterValue: AttributionFilter;
}) => {
  const translate = useTranslate();
  return (
    <Tooltip title={translate('attributions.noAttribution')}>
      <StyledIconButton
        onClick={() => onFilterClick(AttributionFilter.UNATTRIBUTED)}
      >
        <UnattributedBadge badgeContent={unattributedCount}>
          <PersonOffIcon
            sx={{
              color: conditionalColor(
                filterValue === AttributionFilter.UNATTRIBUTED,
              ),
            }}
          />
        </UnattributedBadge>
      </StyledIconButton>
    </Tooltip>
  );
};

export const AttributionFilters = ({
  filterValue,
  onFilterClick,
  discussions,
}: {
  onFilterClick: (filter: AttributionFilter) => void;
  filterValue: AttributionFilter;
  discussions: Discussion[];
}) => {
  const userId = useSelector((state) => state.user.userId);
  const socket = useGateway();
  const connectedUsers = useUsersInRoom(socket, ALL_PRO_ROOM);
  const { unattributedCount } = useCountAttributions(
    discussions,
    userId,
    connectedUsers,
  );
  return (
    <>
      <AttributedFilter
        filterValue={filterValue}
        onFilterClick={onFilterClick}
      />
      <NotAttributedFilters
        filterValue={filterValue}
        onFilterClick={onFilterClick}
        unattributedCount={unattributedCount}
      />
    </>
  );
};
