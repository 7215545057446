import { Create, useTranslate } from 'react-admin';
import { useLocation, Location } from 'react-router-dom';

import { CompanyForm } from './form';

interface RedirectLocation extends Location {
  state: { redirect: string };
}

export const CompanyCreate = () => {
  const translate = useTranslate();
  const location = useLocation() as RedirectLocation;
  return (
    <Create
      resource="companies"
      title={translate('company.add')}
      redirect={location?.state?.redirect || 'list'}
      sx={{ padding: '1rem' }}
    >
      <CompanyForm />
    </Create>
  );
};
