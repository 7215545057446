import { useCallback } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { Chat } from '@mui/icons-material';
import { Button, SxProps } from '@mui/material';

import { Discussion } from '../../types';
import { apiRequest } from '../../utils/httpClients';

export const GoToDiscussionField = (
  { keyId = 'appUser' }: { keyId?: string } = { keyId: 'appUser' },
) => {
  const { [keyId]: id } = useRecordContext();

  const navigate = useNavigate();
  const translate = useTranslate();

  const goToDiscussionPage = useCallback(
    async (e) => {
      e.stopPropagation();
      if (!id) {
        return;
      }
      const discussion = await apiRequest<Discussion>({
        url: `/api/discussions-midwife/fromAppUser/${id}`,
      });
      navigate(`/midwifesubjects/${discussion.id}`);
    },
    [navigate, id],
  );

  return (
    <Button size="small" onClick={goToDiscussionPage}>
      {translate('common.goToDiscussion')}
    </Button>
  );
};

export const RelatedDiscussionURLField = ({
  discussion,
  label,
  midwife,
  sx,
  callback,
}: {
  discussion: Discussion;
  label: string;
  midwife: boolean;
  sx?: SxProps;
  callback?: () => void;
}) =>
  discussion ? (
    <Button
      href={`/${midwife ? 'midwife' : 'nurse'}subjects/${discussion.id}`}
      target="_blank"
      onClick={(event) => {
        event.stopPropagation();
        callback && callback?.();
      }}
      rel="noopener noreferrer"
      color="primary"
      startIcon={<Chat />}
      sx={sx}
    >
      {label}
    </Button>
  ) : null;

export const RelatedDiscussionURLFieldWrapper = ({
  label,
  midwife,
}: {
  label: string;
  midwife: boolean;
}) => {
  const record = useRecordContext<any>();

  return record?.discussion ? (
    <RelatedDiscussionURLField
      discussion={record.discussion}
      label={label}
      midwife={midwife}
    />
  ) : null;
};
