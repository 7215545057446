import { useTranslate } from 'ra-core';
import { useState } from 'react';
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { Button } from '@components/generic/Button';
import { Row } from '@components/generic/Row';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, MenuItem, Select, Typography } from '@mui/material';
import {
  Operator,
  Question,
  QuestionType,
  ScoreMode,
} from '@teammay/form-core';

import { COLORS } from '../../../../themes';

export type ScoringRulesForm = Question;
const implementedOperators = [Operator.EQUAL, Operator.NOT_EQUAL];

const OneRuleForm = ({
  index,
  onBlur,
}: {
  onBlur: () => void;
  field: FieldArrayWithId<ScoringRulesForm, 'scoringRules', 'id'>;
  index: number;
}) => {
  const translate = useTranslate();
  const { control, setValue } = useFormContext<ScoringRulesForm>();
  const [type, options, expectedValue] = useWatch({
    control,
    name: ['type', 'options', `scoringRules.${index}.expectedValue`],
  });
  const [selectedKey, setSelectedKey] = useState<string>(
    expectedValue ?? options?.[0]?.key ?? '',
  );
  const mode = useWatch({ control, name: `scoringRules.${index}.mode` });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: COLORS.GREY_TEXT_LIGHT,
        p: '2rem',
        borderRadius: '1rem',
        flex: 1,
      }}
    >
      <SelectInput
        variant="outlined"
        control={control}
        name={`scoringRules.${index}.mode`}
        label={'Mode'}
      >
        <MenuItem key={ScoreMode.NUMBER} value={ScoreMode.NUMBER}>
          {translate(`forms.scoreMode.${ScoreMode.NUMBER}`)}
        </MenuItem>
        <MenuItem key={ScoreMode.TAG} value={ScoreMode.TAG}>
          {translate(`forms.scoreMode.${ScoreMode.TAG}`)}
        </MenuItem>
      </SelectInput>
      <SelectInput
        variant="outlined"
        control={control}
        name={`scoringRules.${index}.operator`}
        label={translate('forms.scoringRule.operator')}
      >
        {implementedOperators.map((operator) => (
          <MenuItem key={operator} value={operator}>
            {translate(`forms.operator.${operator}`)}
          </MenuItem>
        ))}
      </SelectInput>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextInput
          sx={{
            flex: 1,
            display: type !== QuestionType.RADIO ? 'block' : 'none',
          }}
          control={control}
          name={`scoringRules.${index}.expectedValue`}
          label={translate('forms.scoringRule.expectedValue')}
          rules={{ required: true }}
          onBlur={onBlur}
        />

        {type === QuestionType.RADIO && (
          <>
            <Select
              value={selectedKey ?? options?.[0]?.key ?? ''}
              onChange={(e) => {
                setSelectedKey(e.target.value);
                setValue(`scoringRules.${index}.expectedValue`, e.target.value);
              }}
            >
              {options?.map((option: { key: string; value: string }) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Box>
      <TextInput
        sx={{ flex: 1 }}
        control={control}
        name={`scoringRules.${index}.scoringValue`}
        label={translate('forms.scoringRule.scoringValue', {
          number_or_string: mode,
        })}
        rules={{ required: true }}
        onBlur={onBlur}
        type={mode === ScoreMode.NUMBER ? 'number' : 'text'}
      />
    </Box>
  );
};

export const ScoringRulesEdit = ({
  onBlur,
  onCancel,
}: {
  onBlur: () => void;
  onCancel: () => void;
}) => {
  const { control } = useFormContext<ScoringRulesForm>();
  const translate = useTranslate();

  const [questionId, title] = useWatch({ control, name: ['id', 'title'] });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'scoringRules',
  });

  return (
    <>
      <Row>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }} noWrap>
          {translate('forms.scoringRule.formTitle', { question: title })}
        </Typography>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Row>
      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.25rem',
          }}
        >
          <OneRuleForm field={field} index={index} onBlur={onBlur} />
          <IconButton onClick={() => remove(index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        startIcon={<Add />}
        onClick={() =>
          append({
            id: self.crypto.randomUUID(),
            questionId,
            expectedValue: '',
            scoringValue: '',
            operator: Operator.EQUAL,
            mode: ScoreMode.TAG,
          })
        }
      >
        {translate('forms.scoringRule.add')}
      </Button>
    </>
  );
};
