import { useRef, useState } from 'react';
import { Loading } from 'react-admin';

import { FilledFormViewer } from '@components/forms/filledFormViewer';
import { Paper, Slide, SlideProps } from '@mui/material';

import { ShowConsultation } from './components/ShowConsultation';
import { FormConsultation } from './form';
import { useConsultation } from '../../hooks/consultation';

const slideStyles = {
  height: 'calc(100% - 49px)',
  width: 'calc(100% - 50px)',
  position: 'absolute',
  zIndex: 20,
} as SlideProps['style'];

export const ConsultationEdit = () => {
  const {
    consultation,
    therapy,
    relatedDiscussion,
    loading,
    formAnswersId,
    formSlug,
  } = useConsultation();
  const [viewableForm, setViewableForm] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  if (loading || !consultation) {
    return <Loading />;
  }

  return (
    <>
      <Slide
        style={slideStyles}
        direction="left"
        in={!!viewableForm}
        container={contentRef.current}
        unmountOnExit
        mountOnEnter
      >
        <Paper sx={{ height: '100%' }} elevation={4}>
          <FilledFormViewer
            onClose={() => setViewableForm(false)}
            form={{ slug: formSlug, id: undefined }}
            formAnswers={{ id: formAnswersId }}
          />
        </Paper>
      </Slide>
      <Paper sx={{ padding: '1rem' }} elevation={0}>
        <ShowConsultation
          relatedDiscussion={relatedDiscussion}
          consultation={consultation}
          therapy={therapy}
        />
        <FormConsultation therapy={therapy} setViewableForm={setViewableForm} />
      </Paper>
    </>
  );
};
