import sortBy from 'lodash/sortBy';

import { TherapyChapter, TherapyContent, TherapySession } from './therapy.dto';

export const cleanupSession = (
  sessions: TherapySession[],
  flags: string[],
): TherapySession[] => {
  return sortBy(sessions, 'order').map(({ chapters, ...session }) => {
    const newSession: TherapySession = { ...session, chapters: [] };
    newSession.chapters = sortBy(chapters, 'defaultOrder').map((chapter) => {
      const newChapter: TherapyChapter = {
        ...chapter,
        content: chapter.content
          .flatMap((cont) => {
            if (!cont.displayFlag) {
              return [cont];
            }

            if (!flags.includes(cont.displayFlag)) {
              return [];
            }
            return [cont];
          })
          .sort((a, b) => a.order - b.order)
          .reduce((acc, con) => {
            // sanity check;
            // keep only one content if two content share the same order
            if (acc.length && acc[acc.length - 1].order === con.order) {
              return acc;
            }
            return [...acc, con];
          }, [] as TherapyContent[])
          .map(({ order, displayFlag, ...rest }) => rest as TherapyContent),
      };

      return newChapter;
    });
    return newSession;
  });
};

export const mappingComponent = {
  'therapy-content.post': 'therapy.post',
  'therapy-content.video': 'therapy.video',
  'therapy-content.guide': 'therapy.guide',
  'therapy-content.audio': 'therapy.audio',
};

export const getInnerContent = (component: TherapyContent['__component']) =>
  ({
    'therapy-content.post': 'post',
    'therapy-content.video': 'video',
    'therapy-content.guide': 'guide',
    'therapy-content.audio': 'audio',
  })[component];
