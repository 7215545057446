import { useState, useCallback } from 'react';
import { ImageField, SimpleShowLayout, useTranslate } from 'react-admin';

import { ConsultationHeader } from '@components/subjects/patientHeader';
import { AccessTime, CalendarToday, Phone } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Chip, Stack, Typography } from '@mui/material';
import { getReadableDate, getReadableHourRange } from '@utils/date';

import { OtherActionsConsultation } from './OtherActionsConsultation';
import { TherapyDetails } from './therapy.dto';
import { logViewDiscussionFromConsultation } from '../../../analytics/events';
import { RelatedDiscussionURLField } from '../../../components/fields';
import { TokboxHandler } from '../../../components/tokbox/tokboxHandler';
import { useSelector } from '../../../store';
import { COLORS } from '../../../themes';
import { ConsultationWithRelationIds, Discussion } from '../../../types';

export const ConsultationMainHeader = ({
  relatedDiscussion,
  consultation,
}: {
  relatedDiscussion: Discussion;
  consultation: ConsultationWithRelationIds;
}) => {
  const translate = useTranslate();
  const [openVisio, setOpenVisio] = useState(false);
  const startVisio = useCallback(() => {
    setOpenVisio(true);
  }, [setOpenVisio]);

  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
        {translate('consultations.titleOne')}
      </Typography>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <RelatedDiscussionURLField
          discussion={relatedDiscussion}
          label={translate('common.goToDiscussion')}
          midwife={false}
          callback={() => {
            logViewDiscussionFromConsultation(consultation.id);
          }}
        />
        {consultation && !consultation.phoneOnly && (
          <TokboxHandler
            startVisio={startVisio}
            openVisio={openVisio}
            visioCode={consultation?.visioCode}
          />
        )}
      </Box>
    </Box>
  );
};

export const ConsultationDateInfo = ({
  consultation,
}: {
  consultation: ConsultationWithRelationIds;
}) => {
  const translate = useTranslate();
  const tz = useSelector((state) => state.timezone);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Stack direction="row" spacing={1} sx={{ marginRight: '0.5rem' }}>
        <Stack direction={'row'} spacing={1} alignItems="center">
          <CalendarToday sx={{ color: COLORS.GREY_TEXT, fontSize: '1rem' }} />
          <Typography>{getReadableDate(consultation.start, tz)}</Typography>
        </Stack>
        <Stack direction={'row'} spacing={1} alignItems="center">
          <AccessTime sx={{ color: COLORS.GREY_TEXT, fontSize: '1rem' }} />
          <Typography>
            {consultation?.start && consultation?.end
              ? getReadableHourRange(consultation.start, consultation.end, tz)
              : translate('common.noDate')}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={1}
        >
          <Phone sx={{ color: COLORS.GREY_TEXT, fontSize: '1rem' }} />
          <Typography>{consultation?.appUser?.phone}</Typography>
        </Stack>
      </Stack>
      <OtherActionsConsultation consultation={consultation} />
    </Box>
  );
};

export const ShowConsultation = ({
  relatedDiscussion,
  consultation,
  therapy,
}: {
  relatedDiscussion: Discussion;
  consultation: ConsultationWithRelationIds;
  therapy?: TherapyDetails;
}) => {
  const translate = useTranslate();

  return (
    <Box>
      <ConsultationMainHeader
        relatedDiscussion={relatedDiscussion}
        consultation={consultation}
      />

      <ConsultationDateInfo consultation={consultation} />

      {consultation.phoneOnly && (
        <Alert severity="warning" variant="outlined" sx={{ marginTop: '1rem' }}>
          <AlertTitle sx={{ fontWeight: '500' }}>
            {translate('consultations.warning')}
          </AlertTitle>
          <Typography>{translate('consultations.phoneOnlyWarning')}</Typography>
        </Alert>
      )}

      <Alert
        variant="outlined"
        severity="info"
        sx={{ width: '100%' }}
        action={
          therapy && (
            <Chip
              label={translate('consultations.therapyTitle') + therapy?.title}
              sx={{ color: COLORS.GREY_TEXT }}
              variant="outlined"
            />
          )
        }
      >
        <AlertTitle sx={{ fontWeight: '500' }}>
          {translate('consultations.reason')}
        </AlertTitle>
        <Typography>{consultation?.reason}</Typography>
        <ConsultationHeader consultation={consultation} />
      </Alert>
      <SimpleShowLayout record={consultation} sx={{ padding: 0 }}>
        {consultation?.files?.map((url, index) => (
          // We use an image field in a wacky way. We could use a <img> instead
          // but because of laziness and lack of time, I'm using react-admin wrap elements to
          // ensure rendering is somewhat consistent.
          <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
            <ImageField
              source="url"
              label={`photo_${index}`}
              record={{ url, id: 'hello ts' }}
            />
          </a>
        ))}
      </SimpleShowLayout>
    </Box>
  );
};
