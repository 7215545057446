import {
  BooleanField,
  Datagrid,
  ImageField,
  TextField,
  useTranslate,
} from 'react-admin';

import { List } from '../../components/generic/List';

export const ConsultationCategoriesList = (props) => {
  const translate = useTranslate();

  return (
    <List {...props} title={translate('consultationCategory.title')}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label={translate('common.name')} />
        <ImageField
          source="image"
          sx={{ '& > img': { maxWidth: 250, maxHeight: 250 } }}
          label={translate('common.image')}
        />
        <BooleanField source="active" label={translate('common.active')} />
      </Datagrid>
    </List>
  );
};
