import { useState } from 'react';
import { useTranslate } from 'react-admin';

import { DialogTitle } from '@components/generic/Dialog';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';

import { TherapyDetails } from './therapy.dto';
import { getInnerContent, mappingComponent } from './therapy.utils';
import { logViewTherapyDetails } from '../../../analytics/events';
import { COLORS } from '../../../themes';

export const TherapyInstructions = ({
  therapy,
}: {
  therapy?: TherapyDetails;
}) => {
  const translate = useTranslate();
  const [displayTherapy, setDisplayTherapy] = useState(false);

  if (!therapy) {
    return null;
  }

  return (
    <Alert severity="info" sx={{ maxHeight: '300px', width: '100%' }}>
      <AlertTitle sx={{ fontWeight: '500' }}>
        {translate('consultations.proGuidelines')}
      </AlertTitle>
      <Typography>{therapy.proGuidelines ?? 'N/A'}</Typography>

      <Button
        onClick={() => {
          logViewTherapyDetails(therapy.therapySlug);
          setDisplayTherapy(true);
        }}
      >
        {translate('consultations.displayTherapy')}
      </Button>
      {displayTherapy && therapy && (
        <Dialog open={displayTherapy} onClose={() => setDisplayTherapy(false)}>
          <DialogTitle onClose={() => setDisplayTherapy(false)}>
            {therapy.title}
          </DialogTitle>
          <DialogContent>
            <Typography variant="caption">{therapy.catchPhrase}</Typography>
            {therapy.sessions.map((session) => (
              <Box key={session.title} sx={{ marginTop: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {session.title}
                </Typography>
                {session.chapters.map((chapter) => (
                  <Box key={chapter.title}>
                    <Typography variant="subtitle1">{chapter.title}</Typography>
                    {chapter.content.map((content, ndx) => (
                      <Box
                        key={ndx}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'baseline',
                          gap: '0.5rem',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ color: COLORS.GREY_TEXT, minWidth: '60px' }}
                        >
                          {translate(
                            mappingComponent[content.__component] ?? 'unknown',
                          )}
                        </Typography>
                        <Typography>
                          {content[getInnerContent(content.__component)]?.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            ))}
          </DialogContent>
        </Dialog>
      )}
    </Alert>
  );
};
