import { StyleSheet } from 'react-native';

import { guideDesignTokens } from './resources/guideTheme';

const { colors, fonts } = guideDesignTokens;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  footerSpace: { paddingBottom: 40 },

  footer: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  pageNumber: { ...fonts.body.md, color: colors.text.secondary },
});

export const markdownStyles = StyleSheet.create({
  body: { flex: 1, padding: 24, gap: 16 },

  paragraph: { marginTop: 0, marginBottom: 0 },

  heading1: { ...fonts.h1, color: colors.text.primary },
  heading2: { ...fonts.h2, color: colors.text.secondary },
  heading3: { ...fonts.h3, color: colors.text.warning },
  heading4: { ...fonts.h4, color: colors.text.secondary },
  text: { ...fonts.body.sm, color: colors.text.secondary },

  squareImage: { aspectRatio: 1 },
  imageContent: { width: '100%', height: '100%' },

  mediumImage: { height: 80 },
  smallImage: { width: 32, height: 32 },

  list: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginVertical: 4,
    gap: 8,
  },
  imageList: { alignItems: 'center' },
  bulletContainer: {
    height: fonts.body.sm.lineHeight,
    justifyContent: 'center',
  },
  bullet: {
    height: 4,
    aspectRatio: 1,
    borderRadius: 2,
    backgroundColor: colors.text.secondary,
  },
  bulletContent: { flex: 1 },

  blockquotes: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.background.accent,
    borderRadius: 8,
  },

  table: {
    borderRadius: 0,
    borderWidth: 0,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderColor: colors.borders.primary,
  },
  th: {
    borderRightWidth: 1,
    borderColor: colors.borders.primary,
  },
  td: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightWidth: 1,
    gap: 4,
    borderColor: colors.borders.primary,
  },
  tr: {
    borderColor: colors.borders.primary,
  },
});
