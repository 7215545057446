import MarkdownIt from 'markdown-it';
import React from 'react';
import { StyleSheet, Image, Text, View } from 'react-native';
import Markdown, {
  hasParents,
  RenderRules,
} from 'react-native-markdown-display';

import { guideTheme } from './resources/guideTheme';
import { Logo } from './resources/logo';
import { styles, markdownStyles } from './styles';
import { splitPages } from './utils/splitPages';

enum RULES {
  BLOCKQUOTE = 'blockquote',
  BULLET_LIST = 'bullet_list',
  ORDERED_LIST = 'ordered_list',
  LIST_ITEM = 'list_item',
  LINK = 'link',
  TEXT = 'text',
  HR = 'hr',
  IMAGE = 'image',
  TABLE = 'table',
  T_HEAD = 'thead',
  PARAGRAPH = 'paragraph',
  BODY = 'body',
  INLINE = 'inline',
}

function FillImage({
  style,
  source,
}: Pick<Image['props'], 'style' | 'source'>) {
  const [imageRatio, setImageRatio] = React.useState(1);

  return (
    <Image
      style={[{ aspectRatio: imageRatio }, style]}
      onLoad={(_) => {
        // force re-render to update aspect ratio
        Image.getSize((source as { uri: string }).uri, (width, height) => {
          const ratio = width / height;
          setImageRatio(ratio);
        });
      }}
      resizeMode="contain"
      source={source}
    />
  );
}

const md = MarkdownIt({
  typographer: true,
  linkify: true, //turn on link redirection in markdown
});

const rules: RenderRules = {
  [RULES.T_HEAD]: (node, children, _, mdStyles) => {
    const headerEmpty = node.children[0]?.children.every(
      (th) => th.children[0]?.type === RULES.INLINE,
    );

    if (headerEmpty) {
      return null;
    }

    return (
      <View key={node.key} style={mdStyles._VIEW_SAFE_thead}>
        {children}
      </View>
    );
  },
  [RULES.IMAGE]: (node, children, parentNodes, mdStyles) => {
    const { src } = node.attributes;
    const withinTable = parentNodes.some((p) => p.type === RULES.TABLE);

    const imageWidth = withinTable ? 32 : mdStyles._dimensions.width - 48;

    return (
      <FillImage
        key={node.key}
        style={[{ width: imageWidth }, withinTable && mdStyles.squareImage]}
        source={{ uri: src }}
      />
    );
  },
  [RULES.LIST_ITEM]: (node, children, parentNodes, mdStyles) => {
    if (hasParents(parentNodes, RULES.BULLET_LIST)) {
      const childImage = node.children[0]?.type === RULES.IMAGE;

      if (!childImage) {
        return (
          <View key={node.key} style={mdStyles.list}>
            <View style={mdStyles.bulletContainer}>
              <View style={mdStyles.bullet} />
            </View>
            <View style={mdStyles.bulletContent}>{children}</View>
          </View>
        );
      }

      const imageIndex = node.children.findIndex(
        (child) => child.type === RULES.IMAGE,
      );
      const image = node.children[imageIndex];
      const childrenWithoutImage = [
        ...children.slice(0, imageIndex),
        ...children.slice(imageIndex + 1),
      ];

      return (
        <View key={node.key} style={[mdStyles.list, mdStyles.imageList]}>
          <Image
            key={image.key}
            style={mdStyles.smallImage}
            resizeMode="contain"
            source={{ uri: image.attributes.src }}
          />
          <View style={mdStyles.bulletContent}>{childrenWithoutImage}</View>
        </View>
      );
    }
    if (hasParents(parentNodes, RULES.ORDERED_LIST)) {
      return (
        <View key={node.key} style={mdStyles.list}>
          <Text style={mdStyles.text}>{node.index + 1}.</Text>
          <Text>{children}</Text>
        </View>
      );
    }
    return null;
  },
  [RULES.BLOCKQUOTE]: (node, children, __, mdStyles) => {
    return (
      <View key={node.key} style={mdStyles.blockquotes}>
        {children}
      </View>
    );
  },
  [RULES.PARAGRAPH]: (node, children, parent, mdStyles) => {
    const childImage = node.children[0]?.type === RULES.IMAGE;
    if (childImage) {
      return children;
    }

    const imageIndex = node.children.findIndex(
      (child) => child.type === RULES.IMAGE,
    );
    if (imageIndex >= 0) {
      const image = node.children[imageIndex];
      const childrenWithoutImage = [
        ...children.slice(0, imageIndex),
        ...children.slice(imageIndex + 1),
      ];

      const imageWidth = mdStyles._dimensions.width - 48;

      return (
        <View key={node.key}>
          <View style={mdStyles.paragraph}>{childrenWithoutImage}</View>
          <Image
            key={image.key}
            style={[{ width: imageWidth }, mdStyles.mediumImage]}
            resizeMode="contain"
            source={{ uri: image.attributes.src }}
          />
        </View>
      );
    }

    return (
      <View key={node.key} style={mdStyles.paragraph}>
        {children}
      </View>
    );
  },
  [RULES.TEXT]: (node, _, parent, mdStyles, inheritedStyles = {}) => {
    return (
      <Text key={node.key} style={[mdStyles.text, inheritedStyles]}>
        {node.content}
      </Text>
    );
  },
  [RULES.BODY]: (node, children, parent, mdStyles) => {
    return (
      <View key={node.key} style={mdStyles.body}>
        {children}
      </View>
    );
  },
};

interface GuideRendererProps {
  style?: View['props']['style'];
  content: string;
  pageNumber: number;
  pagesCount: number;
  themeType: 'theme1' | 'theme2' | 'theme3';
  width: number;
}

export function GuideRenderer({
  style,
  content,
  pageNumber,
  pagesCount,
  themeType,
  width,
}: GuideRendererProps) {
  const mdStyles = React.useMemo(
    () => ({ _dimensions: { width }, ...markdownStyles }),
    [width],
  );

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: guideTheme[themeType]?.background, width },
        style,
      ]}
    >
      <View style={[StyleSheet.absoluteFillObject, styles.footerSpace]}>
        <Markdown markdownit={md} style={mdStyles} rules={rules}>
          {content}
        </Markdown>
      </View>
      <View style={styles.footer}>
        <Logo height={24} width={48} />
        <Text style={styles.pageNumber}>
          {pageNumber} / {pagesCount}
        </Text>
      </View>
    </View>
  );
}

GuideRenderer.Memo = React.memo(GuideRenderer);
GuideRenderer.splitPages = splitPages;

export declare namespace GuideRenderer {
  export type Props = GuideRendererProps;
}
