import {
  BooleanInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TextField,
  usePermissions,
  useTranslate,
  Toolbar,
  SaveButton,
  useRecordContext,
} from 'react-admin';

import { Roles } from '@boTypes/user';
import { FamilyHeader } from '@components/subjects/familyHeader';
import { Typography } from '@mui/material';

import { subscriptionChoices } from '../common';
import { DeletionForm } from './inlines/deletionForm';

const PatientToolbar = () => {
  const translate = useTranslate();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton label={translate('common.save')} />
      <DeletionForm />
    </Toolbar>
  );
};

export const PatientsForm = (props) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const record = useRecordContext();

  return (
    <SimpleForm {...props} toolbar={<PatientToolbar />}>
      <Typography
        variant="h5"
        sx={{ fontWeight: 'bold', mb: 1 }}
        color="primary"
      >
        {translate('appUsers.title')}
      </Typography>
      <TextField
        label={translate('common.name')}
        source="name"
        variant="body1"
        sx={{ fontWeight: 'bold' }}
      />
      <TextField
        label={translate('common.email')}
        source="email"
        variant="body2"
        sx={{ mb: 1 }}
      />
      <TextInput
        label={translate('common.phone')}
        source="phone"
        variant="standard"
      />
      <BooleanInput label="VIP" source="vip" variant="standard" />
      <SelectInput
        disabled
        source="subscriptionStatus"
        choices={subscriptionChoices()}
        variant="standard"
        emptyText={translate('subscription.none')}
        label={translate('common.subscriptionStatus')}
      />
      {permissions?.includes(Roles.ADMIN) && (
        <BooleanInput
          source="active"
          variant="standard"
          label={translate('common.active')}
        />
      )}
      <Typography
        variant="h6"
        sx={{ fontWeight: 'bold', mb: 1 }}
        color="primary"
      >
        {translate('appUsers.discussion')}
      </Typography>
      {record && <FamilyHeader familyId={record?.familyId} />}
    </SimpleForm>
  );
};
