import {
  BooleanField,
  Datagrid,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin';

import { CompanyPlanField } from '../../components/fields';
import { List } from '../../components/generic/List';

const CompanyFilters = [<SearchInput source="q" alwaysOn variant="standard" />];

export const CompanyList = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      title={translate('company.title')}
      filters={CompanyFilters}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label={translate('common.name')} />
        <BooleanField source="active" label={translate('common.active')} />
        <CompanyPlanField label={translate('company.activePlans')} />
      </Datagrid>
    </List>
  );
};
