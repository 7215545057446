import { useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';

import { AppUserId } from '@boTypes/appUser';
import { AppUserWeight } from '@boTypes/appUserWeights';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material';

import { LineCharts } from './LineCharts';
import { useAppUserWeights } from '../../hooks/appUserWeights';
import { computeStartPregnancyDate } from '../../utils/date';
import { Loader } from '../Loader';

function a11yProps(index: number) {
  return {
    id: `appUserTool-tab-${index}`,
    'aria-controls': `appUserTool-tabpanel-${index}`,
  };
}

export const AppUserWeightChart = ({
  id,
  expectedEnd,
  onClose,
}: {
  id: AppUserId['id'];
  expectedEnd: string | Date;
  onClose: () => void;
}) => {
  const translate = useTranslate();
  const [pregnancyDisplay, setPregnancyDisplay] = useState(true);
  const { data: userWeightList, isLoading } = useAppUserWeights(id);

  const initialDate = useMemo(() => {
    return pregnancyDisplay
      ? computeStartPregnancyDate(expectedEnd)
      : userWeightList?.length
        ? new Date(
            Math.min(...userWeightList.map((weight) => weight.date.getTime())),
          )
        : new Date();
  }, [pregnancyDisplay, expectedEnd, userWeightList]);

  const displayedWeights = useMemo<AppUserWeight[]>(() => {
    return pregnancyDisplay
      ? userWeightList?.filter((weight) => {
          return (
            weight.date < new Date(expectedEnd) && weight.date > initialDate
          );
        })
      : userWeightList;
  }, [pregnancyDisplay, userWeightList, expectedEnd, initialDate]);

  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setPregnancyDisplay(!newValue); // this works because index 0 is pregnancy and index 1 is all
    setTabValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          m: 1,
        }}
      >
        <Typography sx={{ fontWeight: 500 }} color="textPrimary">
          {translate('common.weight')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Tabs
        variant="fullWidth"
        value={tabValue}
        onChange={onTabChange}
        aria-label="basic tabs example"
      >
        <Tab label={translate('common.pregnancy')} {...a11yProps(0)} />
        <Tab label={translate('common.all')} {...a11yProps(1)} />
      </Tabs>
      {isLoading ? (
        <Loader />
      ) : (
        <LineCharts
          data={displayedWeights}
          height={350}
          initialDate={initialDate}
          pregnancy={pregnancyDisplay}
        />
      )}
    </Box>
  );
};
