import dayjs from 'dayjs';
import React, {
  useState,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
} from 'react';
import {
  Identifier,
  useGetOne,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { DiscussionFavorite } from '@boTypes/discussionFavorite';
import { FamilyPopulated } from '@boTypes/family';
import { PatientDetail as PatientDetailType } from '@boTypes/patient';
import { Roles } from '@boTypes/user';
import { TextInput } from '@components/forms/textInput';
import { useMutation, useQuery, useQueryClient } from '@hooks/queryWrappers';
import { useGetVaryingMany } from '@hooks/useGetVaryingMany';
import { Bookmark, BookmarkAdd } from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BadgeIcon from '@mui/icons-material/Badge';
import CakeIcon from '@mui/icons-material/Cake';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ParentIcon from '@mui/icons-material/EscalatorWarning';
import FemaleIcon from '@mui/icons-material/Female';
import InfoIcon from '@mui/icons-material/Info';
import MaleIcon from '@mui/icons-material/Male';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/Phone';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  SxProps,
  Tooltip,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material';

import { DiscussionContext, membershipStatus } from '../../common';
import { getDiscussionRoom } from '../../hooks/useUsersInRoom';
import { COLORS } from '../../themes';
import { Kid } from '../../types';
import { ConsultationBase } from '../../types/consultation';
import { getAge, getAgeComplete, isToday } from '../../utils';
import { preciseAmenorrheaWeeks } from '../../utils/date';
import { ConnectedUsers } from '../ConnectedUsers';
import { Avatar } from '../fields/AvatarField';
import { Button } from '../generic/Button';
import { DialogTitle } from '../generic/Dialog';

const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const KidTitle = ({ kid }: { kid: Kid }) => (
  <TitleContainer>
    <Avatar
      user={kid}
      source={'image'}
      sx={{ width: 24, height: 24, fontSize: '0.75rem', marginRight: 0.5 }}
      size="custom"
    />
    <Typography color="inherit">{kid.firstName}</Typography>
    {kid.sex === 'm' ? (
      <MaleIcon sx={{ fontSize: '1rem' }} />
    ) : (
      <FemaleIcon sx={{ fontSize: '1rem' }} />
    )}
    <Typography
      sx={{
        fontSize: '0.75rem',
        marginLeft: '0.25rem',
        color:
          dayjs().diff(dayjs(kid.birthDay), 'month') < 3
            ? COLORS.PINK[200]
            : 'inherit',
        fontWeight:
          dayjs().diff(dayjs(kid.birthDay), 'month') < 3 ? 'bold' : 'inherit',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
      }}
    >
      {`${getAge(kid.birthDay)}`}
    </Typography>
    {isToday(kid.birthDay) ? (
      <Typography sx={{ fontSize: '1rem', marginLeft: '0.25rem' }}>
        🎂
      </Typography>
    ) : null}
  </TitleContainer>
);

const PatientTitle = ({ patient }: { patient?: PatientDetailType }) => {
  const translate = useTranslate();
  let fullName = patient
    ? `${patient.firstName} ${patient.lastName}`
    : translate('patients.unknown');
  let additionalDetail = '';
  if (patient) {
    const isPregnant = patient.pregnancy?.id;
    additionalDetail = [
      isPregnant &&
        `🤰 ${preciseAmenorrheaWeeks(patient.pregnancy?.expectedEnd)} (${dayjs(
          patient.pregnancy?.expectedEnd,
        ).format('DD/MM/YYYY')})`,
      patient.pregnancy?.multiple
        ? ` - ${translate('pregnancy.multiple')}`
        : '',
    ].join('');
  }
  return !patient ? null : (
    <TitleContainer>
      <Avatar
        user={patient}
        source={'image'}
        sx={{ width: 24, height: 24, fontSize: '0.75rem', marginRight: 0.5 }}
        size="custom"
      />
      <Typography color="inherit">{fullName}</Typography>
      {additionalDetail ? (
        <Typography
          sx={{
            fontSize: '0.75rem',
            marginLeft: '0.25rem',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
          color="inherit"
        >
          {additionalDetail}
        </Typography>
      ) : null}
    </TitleContainer>
  );
};

const SubTitle = ({
  appUserId,
  family,
  context,
}: {
  appUserId?: Identifier;
  family?: FamilyPopulated;
  context: DiscussionContext;
}) => {
  const translate = useTranslate();

  if (!family) {
    return <CircularProgress />;
  }

  if (
    appUserId &&
    family.appUsers.every((appUser) => appUser.id !== appUserId)
  ) {
    return (
      <Typography color={'error'}>
        {translate('patients.deactivated')}
      </Typography>
    );
  }

  const parents = family.appUsers
    .filter((appUser) => !appUserId || String(appUser.id) !== String(appUserId))
    .map(
      (appUser) =>
        appUser.firstName +
        (appUser.pregnancies?.length
          ? ` 🤰 ${preciseAmenorrheaWeeks(appUser.pregnancies[0].expectedEnd)}`
          : ''),
    )
    .join(', ');

  let parentsTitle =
    context === DiscussionContext.NURSE ? 'Parents: ' : 'Coparent: ';

  const patientHeader = `${family?.vip ? 'VIP ⭐ ' : ''}${
    parents?.length ? parentsTitle + parents : ''
  }`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '1rem',
      }}
    >
      <Tooltip title={family?.vip ? translate('discussion.vipTooltip') : ''}>
        <Typography
          variant="caption"
          color="inherit"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: { display: 'none' },
            fontSize: '0.75rem',
          })}
        >
          {patientHeader}
        </Typography>
      </Tooltip>
    </Box>
  );
};

const FamilyDetailContent = ({ family }: { family: FamilyPopulated }) => {
  const { permissions } = usePermissions();

  return (
    <React.Fragment>
      {family.appUsers.map((parent) => (
        <React.Fragment key={parent.id}>
          <ListItem>
            <ListItemIcon>
              <ParentIcon />
            </ListItemIcon>
            <ListItemText>
              {parent.firstName + ' ' + parent.lastName}
            </ListItemText>
          </ListItem>
          {permissions?.includes(Roles.ADMIN) && (
            <ListItem>
              <ListItemIcon>
                <AlternateEmailIcon />
              </ListItemIcon>
              <ListItemText>{`${parent.email}`}</ListItemText>
            </ListItem>
          )}
          {parent.pregnancies.length > 0 ? (
            <ListItem>
              <ListItemIcon>
                <PregnantWomanIcon />
              </ListItemIcon>
              <ListItemText>
                {`${preciseAmenorrheaWeeks(
                  parent.pregnancies[0]?.expectedEnd,
                )} (${dayjs(parent.pregnancies[0]?.expectedEnd).format(
                  'DD/MM/YYYY',
                )})${
                  parent.pregnancies[0]?.multiple ? ' Grossesse multiple' : ''
                }`}
              </ListItemText>
            </ListItem>
          ) : null}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const PatientDetailContent = ({
  patient: patientDetail,
}: {
  patient: PatientDetailType;
}) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();

  const isPregnant = patientDetail.pregnancy?.id;
  const hasKids = patientDetail?.children?.length;
  let kids = [];
  if (hasKids) {
    kids = patientDetail.children.map((el) => ({
      ...el,
      age: dayjs(el.birthDay),
    }));
    kids.sort((a, b) => b.age.diff(a.age));
  }

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <ParentIcon />
        </ListItemIcon>
        <ListItemText>
          {patientDetail.firstName + ' ' + patientDetail.lastName}
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CreditScoreIcon />
        </ListItemIcon>
        <ListItemText>
          {`${patientDetail.vip ? 'VIP ⭐ ' : ''}${translate(
            membershipStatus[patientDetail.subscriptionStatus] ||
              'appUsers.membershipStatus.notSubscribed',
          )}`}
        </ListItemText>
      </ListItem>
      {permissions?.includes('admin') && (
        <>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText>{patientDetail.phone}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AlternateEmailIcon />
            </ListItemIcon>
            <ListItemText>{patientDetail.email}</ListItemText>
          </ListItem>
        </>
      )}
      {isPregnant ? (
        <ListItem>
          <ListItemIcon>
            <PregnantWomanIcon />
          </ListItemIcon>
          <ListItemText>
            {`${preciseAmenorrheaWeeks(
              patientDetail.pregnancy?.expectedEnd,
            )} (${dayjs(patientDetail.pregnancy?.expectedEnd).format(
              'DD/MM/YYYY',
            )})${
              patientDetail.pregnancy?.multiple
                ? ` ${translate('pregnancy.multiple')}`
                : ''
            }`}
          </ListItemText>
        </ListItem>
      ) : null}
      {hasKids ? (
        <ListItem>
          <ListItemIcon>
            <ChildCareIcon />
          </ListItemIcon>
          <ListItemText>
            {translate('patients.kidsNumber', { smart_count: kids.length })}{' '}
            {translate('patients.kidsLastBorn', {
              date: kids[0].age.format('DD/MM/YYYY'),
            })}
          </ListItemText>
        </ListItem>
      ) : null}
    </List>
  );
};

const KidDetailContent = ({
  kid,
  family,
}: {
  kid: Kid;
  family: FamilyPopulated;
}) => (
  <List>
    <ListItem>
      <ListItemIcon>
        <BadgeIcon />
      </ListItemIcon>
      <ListItemText>{kid.firstName}</ListItemText>
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <CakeIcon />
      </ListItemIcon>
      <ListItemText>{getAgeComplete(kid.birthDay)}</ListItemText>
    </ListItem>
    <FamilyDetailContent family={family} />
  </List>
);

const PatientDetail = ({
  familyId,
  childId,
  appUserId,
}: {
  familyId: string;
  childId?: number;
  appUserId?: number;
}) => {
  const [visible, setVisible] = useState(false);
  const { data: [family] = [] } = useGetVaryingMany('family', [familyId], {
    enabled: !!familyId,
  });

  if (!family) {
    return null;
  }

  return childId ? (
    <PatientChildDetail
      childId={childId}
      family={family}
      visible={visible}
      setVisible={setVisible}
    />
  ) : (
    <PatientAdultDetail
      appUserId={appUserId}
      family={family}
      visible={visible}
      setVisible={setVisible}
    />
  );
};

const PatientConsultationDetail = ({
  consultation,
}: {
  consultation: ConsultationBase;
}) => {
  const { data: fetchedKid } = useGetOne<Kid>('children', {
    id: Number(consultation.childId),
  });

  const familyId = fetchedKid?.familyId;
  const { data: [family] = [] } = useGetVaryingMany('family', [familyId], {
    enabled: !!familyId,
  });

  const [visible, setVisible] = useState(false);

  if (!fetchedKid || !family) {
    return null;
  }

  return (
    <PatientChildDetail
      childId={consultation.childId}
      visible={visible}
      setVisible={setVisible}
      family={family}
    />
  );
};

const MoreInformationContainer = ({
  visible,
  setVisible,
  children,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  children: ReactElement;
}) => {
  const translate = useTranslate();

  return (
    <>
      <IconButton
        color="inherit"
        sx={{ padding: 0.5 }}
        onClick={() => setVisible(true)}
      >
        <InfoIcon color="inherit" sx={{ fontSize: '0.875rem' }} />
      </IconButton>
      <Dialog onClose={() => setVisible(false)} open={visible}>
        <DialogTitle onClose={() => setVisible(false)}>
          {translate('appUsers.moreInformation')}
        </DialogTitle>
        <DialogContent sx={{ p: 1 }}>{children}</DialogContent>
      </Dialog>
    </>
  );
};

const PatientChildDetail = ({
  childId,
  setVisible,
  visible,
  family,
}: {
  childId: number;
  setVisible: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
  family: FamilyPopulated;
}) => {
  const translate = useTranslate();
  const kid = family?.children?.find((child) => child.id === childId);
  const { data: fetchedKid } = useGetOne<Kid>(
    'children',
    { id: Number(childId) },
    {
      enabled: Boolean(family && !kid),
      select(data) {
        return {
          ...data,
          firstName: `${data.firstName} (${translate('children.deactivated')})`,
        };
      },
    },
  );
  const usedKid = kid ?? fetchedKid;

  return (
    <Box>
      <TitleContainer>
        {Boolean(usedKid) && (
          <>
            <KidTitle kid={usedKid} />
            <MoreInformationContainer visible={visible} setVisible={setVisible}>
              <KidDetailContent kid={usedKid} family={family} />
            </MoreInformationContainer>
          </>
        )}
      </TitleContainer>
      <SubTitle family={family} context={DiscussionContext.NURSE} />
    </Box>
  );
};

const PatientAdultDetail = ({
  appUserId,
  family,
  setVisible,
  visible,
}: {
  appUserId: Identifier;
  family?: FamilyPopulated;
  setVisible: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
}) => {
  const { data: patientDetail } = useGetOne<PatientDetailType>('patients', {
    id: Number(appUserId),
  });

  return (
    <Box>
      <TitleContainer>
        <PatientTitle patient={patientDetail} />
        <MoreInformationContainer visible={visible} setVisible={setVisible}>
          {patientDetail && <PatientDetailContent patient={patientDetail} />}
        </MoreInformationContainer>
      </TitleContainer>
      <SubTitle
        family={family}
        appUserId={appUserId}
        context={DiscussionContext.MIDWIFE}
      />
    </Box>
  );
};

const FavoriteDiscussion = ({ discussionId }: { discussionId: number }) => {
  const queryClient = useQueryClient();
  const [openFavoriteModal, setOpenFavoriteModal] = useState(false);
  const close = useCallback(
    () => setOpenFavoriteModal(false),
    [setOpenFavoriteModal],
  );
  const translate = useTranslate();

  const { data: favorites = [], isLoading } = useQuery<DiscussionFavorite>(
    ['discussion-favorites', { discussionId }],
    {
      method: 'get',
      url: `/api/discussion-favorites?filter=${JSON.stringify({
        discussionId,
      })}`,
    },
  );

  const favorite = favorites[0];

  const { handleSubmit, control, reset, formState } =
    useForm<DiscussionFavorite>({
      defaultValues: {
        discussionId,
        id: favorite?.id,
        comments: favorite?.comments,
      },
    });

  const { mutateAsync: addFavorite } = useMutation<
    DiscussionFavorite,
    any,
    { id: number; comments?: string }
  >(
    ['discussion-favorites'],
    (data) => ({
      method: 'post',
      url: '/api/discussion-favorites',
      data: { discussionId: data.id, comments: data.comments },
    }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<DiscussionFavorite[]>(
          ['discussion-favorites', { discussionId: data.discussionId }],
          () => [data],
        );
      },
    },
  );

  const onSubmit = handleSubmit(async (data: DiscussionFavorite) => {
    await addFavorite({ ...data, id: discussionId });
    reset();
    setOpenFavoriteModal(false);
  });

  const removeFavorite = useMutation(
    ['discussion-favorites'],
    (id: number) => ({
      method: 'delete',
      url: `/api/discussion-favorites/${id}`,
    }),
    {
      onSuccess: () => {
        queryClient.setQueryData<DiscussionFavorite[]>(
          ['discussion-favorites', { discussionId }],
          () => [],
        );
      },
    },
  );

  if (isLoading) {
    return null;
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={() => {
          if (favorite) {
            removeFavorite.mutate(favorite.id);
          } else {
            setOpenFavoriteModal(true);
          }
        }}
        sx={{ color: 'inherit' }}
      >
        {favorite ? (
          <Bookmark sx={{ fontSize: '1rem', color: 'inherit' }} />
        ) : (
          <BookmarkAdd sx={{ fontSize: '1rem', color: 'inherit' }} />
        )}
      </IconButton>
      <Dialog
        onClose={close}
        open={openFavoriteModal}
        aria-labelledby={translate('favorites.addLabel')}
        aria-describedby={translate('favorites.addDescription')}
        maxWidth="md"
      >
        <DialogTitle onClose={close}>{translate('favorites.add')}</DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <TextInput
              autoFocus
              multiline
              control={control}
              name="comments"
              label={translate('favorites.commentLabel')}
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ flex: 1, margin: '1rem 0' }}
              variant="contained"
              type="submit"
              loading={formState.isSubmitting}
              color="primary"
              value={translate('favorites.save')}
              fullWidth
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const ConnectedDetail = () => {
  const { discussionId } = useParams();
  const notify = useNotify();
  const location = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '-0.25rem',
        }}
      >
        <Typography
          variant="caption"
          color="inherit"
          sx={{
            cursor: 'pointer',
            width: '100%',
          }}
          onClick={() => {
            try {
              navigator.clipboard.writeText(
                `${window.location.protocol}//${window.location.host}/#${location.pathname}`,
              );
              notify('discussion.numberCopied', { type: 'info' });
            } catch {}
          }}
        >{`#${discussionId}`}</Typography>
        <FavoriteDiscussion discussionId={Number(discussionId)} />
      </Box>
      <ConnectedUsers room={getDiscussionRoom(discussionId)} size="dense" />
    </Box>
  );
};

const ConsultationWrapperHeaderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '4px 0',
  zIndex: '10',
  height: '48px',
});

const WrapperHeaderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  zIndex: '10',
  height: '48px',
  padding: '4px 8px',
  borderBottom: `1px solid ${COLORS.GREY_LAYOUT}`,
  boxShadow: `0 5px 5px -4px ${COLORS.GREY_LAYOUT}`,
});

export const ConsultationHeader = ({
  consultation,
}: {
  consultation: ConsultationBase;
}) => {
  return (
    <ConsultationWrapperHeaderBox>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <PatientConsultationDetail consultation={consultation} />
      </Box>
    </ConsultationWrapperHeaderBox>
  );
};

export const ChatHeader = ({
  familyId,
  childId,
  appUserId,
  onSliderOpen,
  extraInfo = true,
  sx,
  backAction,
}: {
  familyId: string;
  childId?: number;
  appUserId?: number;
  onSliderOpen?: () => void;
  extraInfo?: boolean;
  sx?: SxProps;
  backAction?: () => void;
}) => {
  const bigScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  return (
    <WrapperHeaderBox sx={sx}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {Boolean(!bigScreen && backAction) && (
          <IconButton
            aria-label="back"
            onClick={backAction}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <PatientDetail
          appUserId={appUserId}
          childId={childId}
          familyId={familyId}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {Boolean(extraInfo) && <ConnectedDetail />}
        {Boolean(onSliderOpen) && (
          <IconButton onClick={onSliderOpen} sx={{ color: 'inherit' }}>
            <MoreVertIcon sx={{ color: 'inherit' }} />
          </IconButton>
        )}
      </Box>
    </WrapperHeaderBox>
  );
};
