// For anyone who might need it, here is a median point « · »

export const domainMessages = {
  fr: {
    activityPopup: {
      cancelText: 'Terminer ma vacation',
      confirmText: 'Continuer ma vacation',
      stillThere: '%{firstName}, es-tu toujours là ?',
    },
    additionalHours: {
      add: 'Heure de renfort',
      cancelSelection: 'Annuler la sélection',
      comment: 'Commentaire',
      deleteConfirmText:
        'Voulez-vous vraiment supprimer cette heure de renfort ?',
      deleteHours: 'Supprimer',
      duration: 'Durée (min)',
      emptySelection: 'Aucune heure de renfort sélectionnée',
      error:
        'La date de fin doit être après le début, le même jour. La durée entre 15min et 2h.',
      errorCreateShort: 'Erreur lors de la création. (15min minimum, 2h max)',
      errorGeneric: 'Erreur lors de la création',
      errorNoPlanning: 'Aucun planning trouvé',
      hoursDeleted: 'Heures supprimées',
      hoursInvalidated: 'Heures invalidées',
      hoursValidated: 'Heures validées',
      invalidateHours: '❌ Invalider',
      selectedRows:
        '1 heure de renfort sélectionnée |||| %{smart_count} heures de renfort sélectionnées',
      startHour: 'Heure de début',
      validateHours: '✅ Valider',
      validated: 'Validée',
    },
    admin: {
      appBundles: {
        list: 'Liste des bundles',
        testers: 'Liste des testeurs',
        title: 'Bundles',
        platform: 'Plateforme',
        runtimeVersion: 'Version',
        status: 'Statut',
        active: 'Actif',
        inactive: 'Inactif',
        testing: 'En test',
        s3Key: 'Clé S3',
      },
      categories: {
        consultations: 'Catégories de consultation',
        questions: 'Catégories de question',
        title: 'Catégories',
      },
      forms: {
        create: 'Créer un formulaire',
        list: 'Liste des formulaires',
        title: 'Formulaires',
      },
      others: {
        title: 'Autres',
        scenarios: 'Scénarios',
      },
      planning: {
        admin: 'Admin',
      },
      pricing: {
        affiliations: 'Code promo affiliations',
        slots: 'Prix des vacations',
        title: 'Pricing et factures',
      },
      title: 'Admin',
    },
    affiliations: {
      consultationsNumber: 'Nb consultations offertes',
      create: 'Ajouter une affiliation',
      createdAt: 'Date de création',
      empty: 'Aucune affiliation',
      expirationDate: "Date d'expiration",
      exportProCommunity: 'Export communauté de pros',
      managePartners: 'Gérer les partenaires',
      managePlans: 'Gérer les offres génériques',
      title: 'Affiliations',
      titleCode: 'Codes promo affiliation',
      usage: 'Usage / mois',
      usagePerMonth: 'Activation du code / mois',
    },
    appUsers: {
      addToWhitelist: 'Ajouter à la whitelist',
      currentEmail: 'Email actuel',
      deletionConfirmation: 'Êtes vous sûr de vouloir supprimer ce compte ?',
      deletionCompletedAt: 'Suppression effectuée le ',
      deletionDate: 'Suppression planifiée le ',
      discussion: 'Discussions',
      membershipStatus: {
        bonus: 'Abonné·e bonus',
        active: 'Abonné·e',
        affiliated: 'Affilié·e',
        trialing: 'Abonné·e (période essai)',
        notSubscribed: 'Non abonné·e',
      },
      moreInformation: 'Informations détaillées',
      onWhitelist: 'Sur whitelist',
      removeFromWhitelist: 'Retirer de la whitelist',
      requestDeletion: 'Demander la suppression ',
      title: 'Patient',
      updateMailAlertItem1: 'Le mail est valide',
      updateMailAlertItem2: "Le mail est différent de l'ancien",
      updateMailAlertItem3: "Aucun compte n'existe avec ce nouveau mail",
      updateMailAlertItem4: 'Stripe & Customer.io sont mis à jour',
      updateMailAlertTitle: 'Etapes intégrées',
      updateMailButton: 'Mettre à jour le mail',
      updateMailError: 'Erreur - Email non mis à jour',
      updateMailSuccess: 'Email mis à jour',
      updateMailTitle: 'Mettre à jour le mail utilisateur',
    },
    attributions: {
      automatic: 'Automatique',
      choiceTitle: "Mode d'attribution :",
      noAttribution: 'Sans attribution ou attribution non connectée',
      start: 'Commencer ma vacation',
      startNoAttrib: 'Prêter main forte',
      stop: 'Terminer ma vacation',
      toMe: "Je m'attribue cette question et réponds",
    },
    auth: {
      backToLogin: "Revenir à l'écran de connexion",
      changePassword: 'Changer mon mot de passe',
      confirmationDialog: {
        title: 'Êtes vous sûr·e de vouloir vous déconnecter?',
        disconnect: 'Me déconnecter',
      },
      email: 'Email',
      forgottenPassword: 'Mot de passe oublié',
      forms: {
        errors: {
          generic_error:
            'Une erreur est survenue... Vérifiez les règles de mot de passe',
          invalid_credentials: 'Identifiants invalides',
        },
        success: {
          email_sent: 'Email envoyé',
          password_updated: 'Mot de passe mis à jour',
          password_reset: 'Mot de passe réinitialisé',
        },
      },
      login: {
        '2FA': 'Veuillez entrer le code reçu par sms',
        connect: 'Connexion',
      },
      logout: 'Déconnexion',
      passwordConstraints:
        'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule & un chiffre ou caractère spécial.',
      resetPassword: {
        newPassword: 'Nouveau mot de passe',
        resetPassword: 'Réinitialisez votre mot de passe',
        sendEmail: 'Envoyer le mail de réinitialisation',
        twoFA: 'Veuillez entrer le code reçu par SMS',
        validateNew: 'Valider mon nouveau mot de passe',
      },
      trustDevice: 'Faire confiance à cet appareil',
    },
    availabilities: {
      created: 'Créneau créé',
      deleted: 'Créneau supprimé',
      my_consultations: 'Mes consultations',
      other_consultations: 'Autres consultations',
      owner_error:
        'Vous ne pouvez pas modifier/supprimer un créneau qui ne vous appartient pas',
      updated: 'Créneau mis à jour',
      none: 'Pas de dispo',
    },
    button: {
      add: 'Ajouter',
    },
    calendars: {
      allDay: 'Toute la journée',
      daily: 'Quotidien',
      date: 'Date',
      hour: 'Heure',
      list: 'Liste',
      monthly: 'Mensuel',
      next: '>',
      noEvents: 'Aucun créneau',
      previous: '<',
      slot: 'Créneau',
      title: 'Calendriers',
      today: "Aujourd'hui",
      tomorrow: 'Demain',
      total: '1 autre |||| %{smart_count} autres',
      weekly: 'Hebdo',
      yesterday: 'Hier',
    },
    category: {
      name: 'Catégorie',
      save: 'Catégorie sauvée',
      useSuggestion: 'Utiliser la suggestion',
      chose: 'Choisir une catégorie :',
      suggested: 'Catégorie suggérée',
    },
    categories: {
      active: 'Active',
      add: 'Ajouter une catégorie',
      excludeFromTriage: 'Pas pour les IAO',
      meta: 'Méta',
      name: 'Nom',
      pregnancy: 'Grossesse',
      priority: 'Priorité',
      slug: 'Slug',
      slugDoNotModify: 'Identifiant unique. Ne pas mettre à jour.',
      title: 'Catégories',
    },
    changelog: {
      contents: 'Contenus',
      displayNotifications: 'Afficher les notifications',
      empty: 'Pas de news pour le moment :(',
      title: 'Nouveautés',
    },
    changes_saved: 'Changements sauvés',
    chat: {
      contentTitle: {
        folder: 'Dossier',
        masterclass: 'Masterclass',
        article: 'Article',
        guide: 'Fiche pratique',
        dailyTip: 'Clé du jour',
        weeklyTip: 'Activité de la semaine',
        fertiTip: 'Conseil fertilité',
        video: 'Vidéo',
      },
      endIntroductionMessage: "chez May et je vous accompagne jusqu'à",
      greetings: 'Bonjour',
      greetingsNight: 'Bonsoir',
      iAm: 'je suis',
      loadPrevious: 'Charger les messages précédents',
      placeholder: 'Tapez votre message ici...',
      sentError: "Erreur lors de l'envoi du message %{error}",
    },
    children: {
      deactivated: 'désactivé',
      empty: 'Aucun enfant',
      title: 'Enfants',
    },
    common: {
      active: 'Actif',
      add: 'Ajouter',
      admin: 'Admin',
      age: 'Age',
      alias: 'Alias',
      all: 'Tous',
      already: 'déjà',
      avatar: 'Avatar',
      averageLast7Days: 'Moyenne quotidienne sur les derniers jours',
      birth: 'Naissance',
      birthDay: 'Date de naissance',
      born: 'Né·e',
      bottleShort: 'Bib.',
      cancel: 'Annuler',
      charts: 'Courbes',
      child: 'Enfant',
      children: 'Enfants',
      close: 'Fermer',
      colon: ' : ',
      confirm: 'Confirmer',
      confirmClose: 'Confirmer la fermeture',
      confirmCloseText:
        'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
      confirmDeletion: 'Confirmer la suppression',
      confirmSave: 'Confirmer la sauvegarde',
      copy: 'Copier',
      create: 'Créer',
      createdAt: 'Création',
      creationDate: 'Date ajout',
      dashboard: 'Tableau de bord',
      date: 'Date',
      day: 'Jour',
      dayCount: '1 jour |||| %{smart_count} jours',
      dayShortCount: '%{days}j',
      deactivate: 'Désactiver',
      delete: 'Supprimer',
      description: 'Description',
      discussion: 'Discussion',
      doctorShort: 'Doc',
      edit: 'Editer',
      email: 'email',
      emptyList: 'Aucune donnée disponible',
      enclose: 'Clôturer',
      error: {
        create: 'Erreur lors de la création',
        delete: 'Erreur lors de la suppression',
        validate: 'Erreur lors de la validation',
        withArgs: 'Une erreur est survenue : %{error}',
        generic: 'Une erreur est survenue...',
      },
      errorBoundary:
        'Une erreur est survenue, merci de réessayer ultérieurement',
      expectedEnd: 'Date de terme',
      failToFetch: 'Immpossible de récupérer',
      feedback: 'Votre retour',
      feedbackHelpText:
        'Votre retour nous aide à améliorer notre service. Quel contenus attendiez vous ?',
      feeding: 'Alimentation',
      filterByName: 'Filtrer par prénom',
      firstName: 'Prénom',
      french: 'Français',
      genericError: 'Une erreur est survenue...',
      goToDiscussion: 'Voir la discussion',
      height: 'Taille',
      image: 'Image',
      in: 'dans',
      international: 'International',
      job: 'Job',
      legacy: 'Legacy',
      loadMore: 'Charger plus',
      logWith: 'Log with',
      male: 'Masculin',
      female: 'Féminin',
      me: 'Moi',
      midwifeShort: 'SF',
      model: 'Modèle',
      modifyEntry: 'Modifier ma saisie',
      month: 'mois',
      monthCount: '1 mois |||| %{smart_count} mois',
      monthShortCount: '%{months}m',
      more: 'Voir plus',
      multiple: 'Multiple',
      name: 'Nom',
      negativeFeedbackSubmitted: 'Entendu. On fera mieux la prochaine fois !',
      number: 'Nombre',
      next: 'Suivant',
      noData: 'Aucune donnée',
      noDate: 'Pas de date',
      noResults: 'Aucun résultat',
      notValidated: 'non validé',
      nurseShort: 'IPDE',
      OK: 'OK',
      onCall: 'Astreinte',
      page: 'Page',
      parent: 'Parent',
      parents: 'Parents',
      partners: 'Partenaires',
      partnersSendLivi: 'Envoyer un lien vers Livi ?',
      phone: 'Téléphone',
      positiveFeedbackSubmitted: '🤗 Merci pour votre retour !',
      preferences: 'Préférences',
      pregnancy: 'Grossesse',
      previous: 'Précédent',
      priority: 'Prio',
      pro: 'Pro',
      save: 'Enregistrer',
      saveAnyway: 'Sauvegarder quand même',
      search: 'Recherche...',
      see: 'Voir',
      seeMore: 'Voir plus...',
      seen: 'Vu',
      selectAll: 'Tout sélectionner',
      settings: 'Paramètres',
      sex: 'Sexe',
      sleep: 'Sommeil',
      slot: 'Créneau',
      slots: 'Créneaux',
      socketError:
        'Erreur de connexion au serveur, rechargez la page si ce message ne disparait pas',
      status: 'Statut',
      submit: 'Soumettre',
      subscriptionStatus: "Status d'abonnement",
      successfulUpdate: 'Mise à jour réussie',
      title: 'Titre',
      today: "Aujourd'hui",
      total: 'Total',
      totalPerDay: 'Total par jour',
      triageNurse: 'IAO',
      unknown: 'Inconnu',
      unitPrice: 'Prix unitaire',
      unselectAll: 'Tout déselectionner',
      update: 'Modifier',
      updatedAt: 'Modification',
      usePrompt:
        'Vos modifications ne seront pas enregistrées, voulez-vous continuer ?',
      validate: 'Valider',
      waitingTime: "Tps d'attente global",
      waitingTimeMine: "Tps d'attente perso",
      weeks: 'semaines',
      weight: 'Poids',
      yearCount: '1 an |||| %{smart_count} ans',
    },
    company: {
      activePlans: 'Plans actifs',
      add: 'Ajouter une entreprise',
      codes: 'Codes',
      create: 'Créer une entreprise',
      maxUsage: 'Max utilisateurs',
      title: 'Entreprises',
    },
    companyPlan: {
      alias: 'Alias',
      companyPlan: 'Plan entreprise',
      create: "Ajouter un plan d'entreprise",
      maxUsage: 'Max utilisations (-1 pour infini)',
      renewable: 'Renouvelable',
    },
    companyPlanCode: {
      code: 'Code',
      create: 'Ajouter un code',
      expiresAt: "Date d'expiration",
      maxUsage: 'Max utilisations (-1 pour infini)',
    },
    connectedUsers: {
      title: 'Utilisateurs connectés',
    },
    consultationCategory: {
      title: 'Consultation catégories',
    },
    consultations: {
      actionPlan: "Plan d'action pour le patient",
      attribution: 'Changer de pro',
      attributionWarningTitle: 'Attention',
      attributionWarningText:
        'Avant de changer de professionnel, assurez-vous que toutes les parties prenantes sont au courant, une notification slack sera envoyée.',
      attributionSuccess:
        "Consultation mise à jour : changement de l'attribution",
      availabilities: 'Créneau de disponibilité',
      cancel: 'Annuler la consultation',
      category: 'Thématique',
      date: 'Date',
      displayOnboardingForm: 'Résultats questionnaire',
      displayTherapy: '🤓 Voir le contenu du programme',
      empty: 'Aucune consultation prévue',
      goToConsultations: 'Accéder aux consultations',
      hangUp: 'Raccrocher',
      otherActionsTitle: 'Autres actions',
      patientEmailContent: "Contenu de l'email destiné au parent",
      phoneOnlyWarning:
        'Pensez bien à masquer votre numéro en le préfixant avec "#31#" sur votre portable.',
      planning: {
        title: 'Planning des consultations',
      },
      proGuidelines: "Consignes pour la rédaction du plan d'action",
      reason: 'Motif',
      reopen: 'Réouvrir',
      report: 'Compte-rendu de CS',
      reportShort: 'Compte-rendu',
      reportShortPro: 'Compte-rendu à usage interne',
      staffUser: 'Pro',
      startConsultation: 'Démarrer la visio',
      status: 'Statut',
      tabs: {
        todo: 'A traiter',
        closed: 'Clôturé',
        canceled: 'Annulé',
        mine: 'Mes consultations',
      },
      therapyTitle: 'Programme : ',
      title: 'Consultations',
      titleOne: 'Consultation',
      toComeTitle: 'Consultations à venir',
      warning: 'Attention',
    },
    contentSuggestion: {
      assessment: 'Évaluer les suggestions',
      empty: 'Aucun contenu pertinent trouvé.',
      guide: 'Fiches',
      macro: 'Macro',
      masterclass: 'Masterclass',
      openLibrary: 'Consulter la bibliothèque',
      post: 'Article',
      subtitle: 'Contenus suggérés en fonction des messages patient',
      title: 'Suggestions de contenu',
    },
    discussion: {
      dailyTip: 'Daily tip',
      deletedMessage: '[Message supprimé]',
      folder: 'Dossier',
      form: 'Questionnaire',
      guide: 'Fiche',
      image: 'Image',
      masterclass: 'Masterclass',
      noMessage: 'Aucun message',
      numberCopied: 'Numéro de discussion copié',
      partner: 'Livi',
      post: 'Article',
      video: 'Vidéo',
      vipTooltip: 'Famille VIP ⭐ à chouchouter sur le BO 😊',
      weeklyTip: 'Weekly tip',
    },
    favorites: {
      add: 'Ajouter un favori',
      addDescription: 'Ajouter une discussion aux favoris avec un commentaire',
      addLabel: 'Ajouter une discussion aux favoris',
      commentLabel: 'Ajouter un commentaire',
      comments: 'Commentaires',
      dashboard: 'Mes discussions en favoris',
      lastMessage: 'Dernier msg',
      none: "Vous n'avez pas encore de favoris",
      save: 'Enregistrer le favori',
      title: 'Favoris',
    },
    fertiTip: {
      cyclePosition: 'Semaine dans le cycle : %{cyclePosition}',
      cycleNumber: 'Cycle n°%{cycleNumber}',
      shortNumberAndPosition: 'Cycle %{cycleNumber} - Semaine %{cyclePosition}',
    },
    field: {
      image: {
        placeholder: 'Glissez votre fichier ou sélectionnez une image',
      },
      optionInput: {
        add: 'Ajouter une option',
        key: 'Clé',
        requiredKey: 'Clé requise',
        requiredValue: 'Valeur requise',
        title: 'Options',
        value: 'Valeur',
      },
    },
    followUp: {
      cancel: 'Annuler',
      confirm: 'Confirmer',
      create: 'Suivi',
      createTitle: 'Ajouter un suivi',
      edit: 'Modifier un suivi',
      empty: " Aucun suivi médical n'a été enregistré pour le moment",
      observations: 'Observations',
      suppressText: 'Êtes vous sur de vouloir supprimer ce suivi',
      suppressTitle: 'Supprimer un suivi',
      title: 'Suivi',
    },
    forms: {
      chat: {
        formMessage: 'En cours. Le patient doit terminer le questionaire.',
        formMessageLocked: 'Formulaire complété',
      },
      conditions: {
        booleanOperator: 'Opérateur logique',
        hideTitle: 'Editer les conditions de masquage',
        displayTitle: "Editer les conditions d'affichage",
        questionRules: {
          add: 'Ajouter une règle',
          expectedValue: 'Valeur cible',
          operator: 'Opérateur',
          question: 'Question',
          title: 'Règles sur les questions',
        },
        dataRules: {
          add: 'Ajouter une donnée',
          dataSlug: 'Donnée',
          expectedValue: 'Valeur cible',
          operator: 'Opérateur',
          title: 'Règles sur les données utilisateurs',
        },
      },
      dialogs: {
        delete: {
          content: 'Voulez vous vraiment supprimer cet élément ?',
          title: 'Confirmer la suppression',
        },
      },
      display: {
        title: 'Affichage',
      },
      edit: {
        modified:
          'Des modifications sont en cours. Voulez-vous vraiment quitter ?',
        saved: 'Formulaire sauvegardé',
        title: 'Paramètres du formulaire',
      },
      errors: {
        generic_error: 'Une erreur est survenue...',
        invalid_fields: 'Champs invalides ou manquants: %{invalidFields}',
        invalid_pattern: 'Format invalide',
        required_field: 'Champ Requis',
      },
      list: {
        columns: {
          createdAt: 'Création',
          description: 'Description',
          slug: 'slug',
          title: 'Titre',
        },
        title: 'Formulaires',
        empty: 'Aucun formulaire',
      },
      operator: {
        EQUAL: 'Égal',
        NOT_EQUAL: 'Différent',
      },
      patientFile: {
        empty: " Aucun formulaire n'a été rempli pour le moment",
      },
      questions: {
        description: 'Description',
        formTitle: 'Editer la question',
        label: 'CTA Label',
        options: 'Options',
        title: 'Titre',
        type: 'Type',
        copy: {
          title: 'Copier une question',
          question: 'Question à copier',
          copy: 'Copie',
        },
      },
      runner: {
        backClicked: 'Retour',
        canBack: 'Retour possible',
        canceledForm: 'Formulaire annulé',
        completedForm: 'Formulaire complété. Score : %{score}, Tags : %{tags}',
        noAnswers: 'Aucune réponse',
        proAnalysis: 'Analyse des réponses',
        questionCount: 'Nombre de questions',
        score: 'Score',
        submitClicked: 'Valider',
        tags: 'Tags',
        unlockedForm: 'Formulaire non finalisé',
      },
      scoreMode: {
        NUMBER: 'Nombre',
        TAG: 'Tag',
      },
      scoringRule: {
        add: 'Ajouter une règle de scoring',
        expectedValue: 'Valeur cible',
        formTitle: 'Règle de scoring "%{question}"',
        operator: 'Opérateur',
        scoringValue: 'Valeur si vrai (%{number_or_string})',
      },
      settings: {
        deleteTitle: 'Êtes-vous sûr de vouloir supprimer ce formulaire ?',
        deleteText:
          'Cette action est irréversible. Si des réponses ont déjà été fournies, la suppression échouera. Vous pouvez toujours archiver le formulaire.',
        description: 'Description',
        illustration: 'Illustration',
        questionCount: 'Nombre de questions',
        questionCountHelper:
          "Laisser vide si l'affichage d'une barre de progression n'est pas souhaité",
        sentInChat: 'Envoyé au sein de la messagerie',
        slug: 'Slug',
        status: 'Statut',
        title: 'Paramètres',
        titleField: 'Titre',
      },
      send: 'Envoyer un formulaire',
      status: {
        draft: 'Brouillons',
        published: 'Publiés',
        archived: 'Archivés',
      },
      step: {
        createQuestion: 'Ajouter une question',
        copyQuestion: 'Copier une question',
        createTemplate: 'Ajouter un template',
        formTitle: 'Editer l’étape',
      },
      template: {
        formTitle: 'Editer le template',
      },
      viewer: {
        retry: 'Essayer à nouveau',
        runError:
          'Impossible de lancer le formulaire, une incohérence persiste',
      },
    },
    generic: {
      createSuccess: 'Création réalisée',
      notSaved:
        'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
      reset: 'Réinitialiser',
      save: 'Enregistrer',
      updateSuccess: 'Modification réalisée',
    },
    handover: {
      AISuggestion: '🎉 Suggestion IA : ',
      acceptAllGeneration: '🎉 Accepter toutes les suggestions',
      activeRevives: 'relances actives',
      add: 'Ajouter une transmission',
      adviceLong: 'Conseils/Actions proposés',
      advice: 'Conseils :',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      create: 'Créer une transmission',
      createWithRevive: 'Créer avec relance',
      deleteConfirmContent:
        'Voulez-vous vraiment supprimer cette transmission ?',
      deleteConfirmTitle: 'Supprimer cette transmission ?',
      edit: 'Editer une transmission',
      editTooltip: 'Modifier la transmission',
      error: 'Erreur lors de la création de la relance: %{error}',
      noData: 'Aucune transmission disponible',
      noneAvailable: 'Pas de transmission disponible !',
      observations: 'Obs :',
      observationsLong: 'Observations faites',
      preventEarlyLeave:
        "Attention, votre transmission/relance n'est pas sauvegardée",
      problem: 'Problème identifié',
      reviveAt: 'Date relance souhaitée',
      title: 'Transmissions',
      titleForOne: 'Transmission',
    },
    help: {
      changeBrowser: "Changez de navigateur. Dans l'incapacité de le faire…",
      contact:
        'Pour toute question technique ou remontée de bug, vous pouvez nous contacter directement sur le channel slack #help-technique.',
      help: 'Aide May',
      or: 'ou',
      pressOn: 'Appuyez sur',
      pressSimultaneously: 'Appuyez simultanément sur ',
      refresh: 'Rafraichir le BO',
      refreshBrowser1: 'Chrome/Firefox et Windows/Linux',
      refreshBrowser2: 'Chrome et Mac',
      refreshBrowser3: 'Safari et Mac',
      refreshBrowser4: 'Internet Explorer',
      shiftExplanation:
        '(shift étant la touche près de Ctrl avec une flèche vers le haut, la touche pour faire des majuscules !)',
    },
    history: {
      revives:
        '%{smart_count} relance active |||| %{smart_count} relances actives',
      title: 'Transmissions',
    },
    invoice: {
      admin: {
        list: 'Liste',
        summary: 'Résumé',
        title: 'Factures - Admin',
      },
      adminList: {
        amountValidated: 'Montant validé',
        amountValidatedShort: '€ ?',
        comments: '💬',
        createdAt: 'Ajout',
        downloadButton:
          'Télécharger une facture |||| Télécharger les %{smart_count} factures',
        downloadError: 'Erreur lors du téléchargement',
        downloadSuccess: 'Téléchargement réussi',
        filters: 'Filtres',
        invoice: 'Facture',
        job: {
          doctor: 'Médecin',
          midwife: 'SF',
          nurse: 'IPDE',
          title: 'Métier',
        },
        notValidated: 'Non validé',
        planning: 'Planning',
        resetFilters: 'Réinitialiser',
        staffUser: 'Pro',
        validated: 'Validé',
        validateAndSepa: 'Valider + SEPA',
        validatedShort: '✔️',
      },
      bankTransfer: 'Virement',
      comments: 'Commentaires',
      deleteShort: 'Suppr.',
      detailPerSlot: 'Détail par créneau HT',
      dialog: {
        amountErrorTitle: 'Erreur de montant',
        amountSuccessTitle: 'Montant détecté dans la facture',
        amountError:
          "Nous n'avons pas détecté de montant correspondant à votre bilan (%{amount} €) dans votre facture. Corrigez-la si besoin ou laissez un commentaire ci-dessous pour justifier l'écart.",
        amountSuccess:
          'Nous avons bien détecté le montant correspondant à votre bilan (%{amount} €) dans votre facture.',
        comments: 'Commentaires',
        commentsHelpTextWhenError:
          "Commentaire obligatoire pour justifier l'écart",
        error: 'Erreur lors de création de la facture',
        errorRetrievingSummary: 'Erreur lors de la récupération du bilan',

        instructions: 'Consignes',
        instructions1: 'Votre document doit être au format PDF',
        instructions2:
          'La date qui y figure doit correspondre au mois de la prestation',
        instructions3:
          'Le total doit correspondre au montant dans le bilan (sauf erreur de notre part : faites-le nous savoir)',
        success: 'Facture ajoutée avec succès',
        title: 'Ajouter une facture',
        upload: 'Ajouter une facture',
      },
      downloadXLS: 'Télécharger XLSX',
      downloadAccountantSummary: 'Télécharger le récap fichier compta',
      empty: 'Aucune facture pour le moment.',
      errorGeneratingSepa:
        'Erreur lors de la génération du fichier SEPA - vérifiez les IBAN/BIC',
      feesNurse: 'Honoraires puers',
      feesDoctor: 'Honoraires médicaux',
      format: 'format',
      generateSepa: 'Générer document virement SEPA',
      invoiceFrom: 'Facture du',
      invoiceTo: 'au',
      lineChat: 'Décaissement honoraires praticiens tchat',
      mailSent: 'Mail envoyé',
      mailSentPlural: 'Mails envoyés',
      numberSelected: '1 sélectionné |||| %{smart_count} sélectionnés',
      noActivity: 'Aucune activité sur ce planning',
      pendingInvoice: 'En attente de validation',
      see: 'Voir',
      sendSummaryMail: 'Envoyer le bilan par mail',
      sendSummaryToAll: 'Envoyer le récap à tous par email',
      sepaGenerated: 'Fichier SEPA généré',
      summary: 'Bilan',
      title: 'Factures',
      total: 'Total calculé: %{total} €',
      totalTaxFree: 'Total HT',
      vat20: 'TVA 20%',
      totalWithTax: 'Total TTC',
      viewerTitle: 'Facture',
      upload: 'Ajouter une facture',
      validatedInvoice: 'Facture validée',
    },
    latestContent: {
      empty: "Aucun contenu n'a été créé dans les 7 derniers jours. 😴",
      goToLibrary: 'Consulter la bibliothèque',
      loadError: 'Impossible de charger les derniers contenus',
      subtitle: 'Sortis il y a moins de 7 jours',
      title: 'Derniers contenus',
    },
    library: {
      contentType: 'Type de contenu',
      dailyTips: 'Daily tips',
      empty: 'Aucun résultat',
      guide: {
        carousel: 'Détail des fiches',
        title: 'Fiche',
      },
      explorer: 'Tous contenus',
      fertiTips: 'Conseils fertilité',
      folders: 'Dossiers',
      form: 'Formulaires',
      guides: 'Fiches',
      itemSuggestions: "Puériculture : Guide d'achats",
      loading: 'Chargement...',
      macros: 'Protocoles',
      masterclasses: 'Masterclasses',
      mode: 'Mode',
      post: 'Article',
      posts: 'Articles',
      pregnancyQuestions: 'Grossesse: Est-ce que je peux...',
      results: 'Résultat de la recherche',
      search: 'Contenus à envoyer',
      searchField: 'Recherche',
      shareContent: 'Partager du contenu',
      steps: 'Étapes',
      summary: 'Résumé',
      title: 'Bibliothèque',
      tracks: 'Episodes',
      types: 'Types de contenus',
      weeklyTips: 'Weekly tips',
    },
    locale: {
      days: {
        long: {
          friday: 'Vendredi',
          monday: 'Lundi',
          saturday: 'Samedi',
          sunday: 'Dimanche',
          thursday: 'Jeudi',
          tuesday: 'Mardi',
          wednesday: 'Mercredi',
        },
        short: {
          friday: 'Ven',
          monday: 'Lun',
          saturday: 'Sam',
          sunday: 'Dim',
          thursday: 'Jeu',
          tuesday: 'Mar',
          wednesday: 'Mer',
        },
      },
      months: {
        long: {
          april: 'Avril',
          august: 'Août',
          december: 'Décembre',
          february: 'Février',
          january: 'Janvier',
          july: 'Juillet',
          june: 'Juin',
          march: 'Mars',
          may: 'Mai',
          november: 'Novembre',
          october: 'Octobre',
          september: 'Septembre',
        },
        short: {
          april: 'Avr',
          august: 'Aou',
          december: 'Dec',
          february: 'Fev',
          january: 'Jan',
          july: 'Jul',
          june: 'Jun',
          march: 'Mar',
          may: 'Mai',
          november: 'Nov',
          october: 'Oct',
          september: 'Sep',
        },
      },
    },
    macro: {
      behavior: 'Conduite à tenir',
      noProtocol: "Aucune CAT n'a été défini pour le moment",
      protocol: 'CAT',
    },
    medicalFollowUp: {
      add: 'Ajouter un suivi',
      deleteConfirmTitle: 'Supprimer cette observation ?',
      deleteConfirmContent:
        'Voulez-vous vraiment supprimer cette observation ?',
      fields: {
        content: 'Observations',
      },
      noData: 'Aucune observation disponible',
      preventEarlyLeave: "Attention, votre suivi n'est pas sauvegardé",
      title: 'Suivi global',
    },
    midwifeSubjects: {
      title: 'Chat SF',
    },
    nurseSubjects: {
      title: 'Chat IPDE',
    },
    patients: {
      deactivated: 'Patient désactivé',
      kidsLastBorn: 'Dernier né le %{date}',
      kidsNumber: '1 enfant. |||| %{smart_count} enfants.',
      title: 'Parents',
      unknown: 'Inconnu',
    },
    patientFile: {
      title: 'Dossier patient',
    },
    partner: {
      livi: {
        description: '7j/7 de 7h à minuit',
        title: 'Téléconsultez en ligne avec un médecin',
      },
    },
    plan: {
      accessLevel: "Niveau d'accès",
      add: 'Ajouter un plan',
      consultationsNumber: 'Nombre de consultations offertes',
      monthsNumber: 'Nombre de mois valide',
      name: 'Plan',
    },
    planning: {
      availabilities: {
        title: 'Disponibilités',
        cantUpdate: 'Vous ne pouvez modifier les disponibilités de ce planning',
        explanationTitle:
          'Quelques rappels pour améliorer le remplissage du planning :',
        explanationItem1:
          'Donne des disponibilités sur un maximum de JOURS possibles dans le mois',
        explanationItem2:
          'Donne des disponibilités sur un maximum de CRENEAUX possibles dans une journée',
        explanationItem3:
          'Remplis tes préférences de manière à venir "encadrer" ce que l\'algo t\'attribue parmi toutes ces disponibilités',
        explanationItem4:
          'Coche ✅ si tu es disponible sur le créneau, sinon ne coche rien. Si tu as coché ✅ par erreur, tu peux recliquer dessus pour que la case redevienne vide (= pas dispo)',
        notSaved:
          'Vos modifications ne sont pas sauvegardées. Quitter quand même?',
      },
      bias: 'Biais planning',
      chosePlanningHours: 'Choisissez les horaires du planning',
      copyPlanning: 'Copier le planning',
      create: 'Créer un nouveau planning',
      deadlineAvailabilities: 'Deadline remplissage des disponibilités',
      dialog: {
        deletion: 'Suppression',
        deletionText: 'Voulez-vous vraiment supprimer ce planning ?',
        editSlots: 'Modifier les créneaux',
        endRegistration: 'Finaloser les inscriptions',
        endRegistrationCTA: 'Clôturer la saisie',
        openToPro: "Permettre aux prfessionnels de s'inscrire ?",
        openToProCTA: 'Ouvrir la saisie pro',
        seeAgenda: 'Voir le planning',
        summary: 'Récap/Factures',
        validate: 'Valider le planning',
      },
      displayedUsers: 'Utilisateurs affichés',
      displaySelector: {
        displayUnattributed: 'Afficher les créneaux non attribués',
      },
      edit: 'Modifier le planning',
      errorLoading: 'Erreur lors du chargement des plannings',
      evaluation: {
        BORING: 'Un peu trop calme 😴',
        CALM: 'Plutôt calme 😌',
        feedback: "Comment s'est passée ta vacation ?",
        INTENSE: 'Intense mais ça restait gérable 💪',
        PERFECT: 'Juste parfait 👌',
        summary:
          'Pendant cette vacation, tu as participé à %{participatedSubjects} questions et en a clôturé %{closedSubjects}.\n\nMerci 👍 !',
        summaryNoCounts: 'Merci pour ton retour !',
        title: 'Retour sur ta vacation',
        TOO_INTENSE: 'Trop intense donc inconfortable 🥵',
        error: 'Une erreur est survenue, peux-tu réessayer ?',
      },
      event: {
        additionalHour: 'Heure de renfort',
        attribution: 'Attribution',
        cancelAttribution: 'Libérer le créneau',
        cancelAttributionConfirmText:
          'Êtes-vous sûr·e de vouloir libérer ce créneau ?',
        create: 'Ajouter un créneau',
        created: 'Créneau créé',
        delete: 'Supprimer ce créneau',
        deleteError: 'Erreur lors de la suppression du créneau',
        deleted: 'Créneau supprimé',
        edit: 'Modifier un créneau',
        end: 'Fin',
        error: 'Erreur lors de la création/modification du créneau',
        getAttribution: "S'attribuer le créneau",
        getAttributionConfirmText:
          'Êtes-vous sûr·e de vouloir vous attribuer ce créneau ?',
        isTriage: 'IAO',
        noOtherAvailable: 'Aucune disponibilité',
        onCall: 'Astreinte',
        onCallActivated: 'Astreinte activée',
        otherAvailable:
          '%{smart_count} disponibilité |||| %{smart_count} disponibilités',
        slot: 'Créneau',
        start: 'Début',
        type: 'Type de créneau',
        updated: 'Créneau mis à jour',
      },
      fields: {
        title: 'Planning',
        dates: 'Dates',
        totalInvoices: 'Factures',
        totalValidated: 'Validés',
        totalInvalidAmount: 'Erreurs',
        totalExpectedInvoices: 'Pros',
      },
      goToPlanning: 'Voir le planning',
      hoursAlreadyValidated: 'Impossible : les horaires ont déjà été validés',
      job: { nurse: 'IPDE', midwife: 'Sage-femme', doctor: 'Médecin/Pédiatre' },
      mine: 'Mes plannings',
      modelGlobal: 'Modèle global',
      modelPerWeek: 'Modèle par semaine',
      noMatch: 'Aucun planning ne correspond',
      notAttributed: 'Non attribué',
      notify: {
        errorSubmittingPlanning: 'Ereur lors de la soumission du planning',
        create: 'Planning créé',
        edit: 'Planning modifié',
      },
      notYetOpen:
        '🤷 Les plannings ne sont pas encore ouverts pour la saisie des disponibilités futures.',
      preferences: {
        unsaved:
          'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
        confirmClose: 'Confirmer la fermeture',
        confirmCloseText:
          'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
        title: 'Préférences',
        maximumPerPlanning: 'Maximum de vacations sur tout le planning',
        maximumSlotsOnWeekends:
          'Maximum de WE travaillés dans le mois (samedi ou dimanche)',
        maximumPerWeek: 'Maximum de vacations par semaine (hors WE)',
        maximumNightsPerWeek:
          'Maximum de vacations le soir par semaine (hors WE)',
        minimumSlotsWanted: 'Minimum de vacations souhaitées au total',
        minimumSlotsWantedError: 'Le minimum doit être inférieur au maximum !',
        noWeekEndAvailable:
          "Tu n'as donné aucune disponibilité le week-end ce mois-ci, est-ce un oubli ?",
        save: 'Mettre à jour mes préférences',
        setFirst: "Saisir les préférences d'abord",
        reminderTitle: 'Rappel',
        reminderText:
          "Mieux vaut remplir tes préférences d'abord pour bénéficier de la sauvegarde automatique",
        tooMuchSlotsWanted: `Tu demandes plus de vacations que tu n'as de jours avec des disponibilités : nous ne pourrons pas satisfaire ta demande.`,
        workWholeWeekend:
          'Acceptez-vous de travailler deux jours différents dans le même weekend ?',
      },
      priorityBias: 'Biais pour créneaux prioritaires',
      results: {
        additionalHours: 'Renfort',
        attributions: 'Attributions',
        attributionCount: 'Attributions %{count}/%{total}',
        availabilities: 'Disponibilités',
        summary: 'Résumé',
      },
      status: {
        adminSlotsAssigned: 'Saisie dispos',
        calculationPerformed: 'A valider',
        done: 'Validé',
        draft: 'Brouillon',
        healthProAssigned: 'Saisie clôturée',
        slotsCreated: 'Template créé',
      },
      summary: {
        maxNightPerWeek: '# max soir/semaine',
        nbMaxPerWeek: '# max/semaine',
        nbMin: '# min',
        nbNight: '# soir',
        nbOnCalls: '# astreintes',
        nbSlots: '# vacations',
        pro: 'Pro',
        weekend: 'Week-end',
      },
      title: 'Planning',
      titleAdmin: 'Planning admin',
      topToolbar: {
        byJob: 'Par métier',
        byUser: 'Par personne',
      },
    },
    pregnancy: {
      born: 'Né·e',
      empty: 'Aucune grossesse en cours',
      expectedEnd: 'Terme prévu',
      interrupted: 'Interrompue',
      multiple: 'Grossesse multiple',
      multipleVeryShort: 'Mult.',
      plural: 'Grossesses',
    },
    pricingProCustom: {
      add: 'Ajouter un pricing',
      base: 'Base',
      night: 'Nuit',
      rateOnCall: 'Coeff astreinte',
      sundayHoliday: 'Férié',
      vat: 'TVA',
    },
    pricingProGeneric: {
      add: 'Ajouter un pricing',
      base: 'Base',
      job: 'Profession',
      night: 'Nuit',
      rateOnCall: 'Coeff astreinte',
      sundayHoliday: 'Férié',
    },
    profile: {
      changePassword: 'Changer mon mot de passe',
      commercialName: 'Nom commercial (factures)',
      confirmPassword: 'Confirmer le nouveau mot de passe',
      jobTitle: 'Spécialité',
      newPassword: 'Nouveau mot de passe',
      passwordsDoNotMatch: 'Les mots de passe ne sont pas identiques',
      phone: 'Téléphone (format +336...)',
      title: 'Mon profil',
      update: 'Mettre à jour mes informations',
    },
    revives: {
      attributed: 'Attribuée',
      attributedStaffUserId: 'Attribuée à',
      by: 'par : ',
      close: 'Fermer la relance',
      closeQuestion: 'Fermer la relance ?',
      createOne: 'Créer une relance',
      edit: 'Modifier la relance',
      errorCreate: 'Erreur lors de la création de la relance',
      errorClosing: 'Erreur lors de la clôture de la relance',
      errorSendingMessage: "Erreur lors de l'envoi du message",
      loading: 'Chargement...',
      noRevives: 'Aucune relance',
      nonAttributed: 'Non attribuée',
      pendingRevivesBeforeSlotCancel:
        '1 relance était attribuée sur ce créneau. Elle a été désattribuée. |||| %{smart_count} relances étaient attribuées sur ce créneau. Elles ont été désattribuées.',
      performAt: 'Relancer le : ',
      performedAt: 'Relance faite le : ',
      planned:
        'Vous avez %{smart_count} relance planifiée pour cette vacation : |||| Vous avez %{smart_count} relances planifiées pour cette vacation :',
      plannedText: 'Texte de la relance',
      reviveAt: 'Date souhaitée de relance',
      revives: '1 relance |||| %{smart_count} relances',
      sendRevive: 'Envoyer la relance ?',
      sendText: 'Envoyer le texte de la relance',
      slot: 'Créneau',
      successCreate: 'Relance créée avec succès',
      title: 'Relance',
      todo: 'Relances à faire',
      unattributed: 'non attribuée |||| non attribuées',
    },
    roles: {
      cannotCreateAdminRoleDueToPermission:
        "Vous n'avez pas les droits suffisants pour créer un rôle administrateur",
      cannotCreateRoleWithSameRole: 'Cet utilisateur possède déjà ce rôle',
      created: 'Rôle créé',
      deleted: 'Rôle supprimé',
      role: 'Rôle',
      roles: 'Rôles',
    },
    scenario: {
      add: 'Ajouter un scénario',
      created: 'Créé le',
    },
    similarQuestion: {
      button: 'Afficher des questions similaires',
      noMatch: 'Aucune question similaire trouvée.',
      noMatchNB:
        'ℹ️ Pour les relances, aucune question similaire ne remonte. Ainsi que toutes les questions où les pros ont initié une conversation',
      title: 'Questions similaires',
    },
    slots: {
      atLeastOneRequired: 'Au moins un slot requis',
      empty: 'Aucun créneau prévu pour le moment.',
      startBeforeEnd:
        'Les créneaux doivent avoir une date de début antérieure à la date de fin',
      toComeTitle: 'Créneaux à venir',
      toComeSubtitle: 'Seuls 10 résultats sont affichés au maximum',
    },
    staffUser: {
      avatar: 'Avatar',
      bic: 'BIC',
      comfortArea: "A l'aise avec",
      commercialName: 'Nom commercial (facture)',
      consultationPrice: 'Tarif de la consultation',
      email: 'Email',
      expertiseArea: "Domaine d'expertise",
      firstName: 'Prénom',
      iban: 'IBAN',
      job: {
        admin: 'Admin',
        childPsychiatrist: 'Pedo-psychiatre',
        generalPractitioner: 'Médecin généraliste',
        midwife: 'Sage-femme',
        nurseryNurse: 'Infirmière puéricultrice',
        nutritionistDoctor: 'Médecin-nutritionniste',
        pediatrician: 'Pédiatre',
        psychologist: 'Psychologue',
      },
      jobTitle: 'Métier',
      language: 'Langue',
      lastName: 'Nom',
      password: 'Mot de passe',
      passwordHelperText:
        'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule et un chiffre ou caractère spécial.',
      phone: 'Téléphone',
      phoneWithFormat: 'Téléphone (format +336...)',
      timezone: 'Fuseau horaire',
      title: 'Team may',
    },
    subject: {
      actives: 'En cours',
      category: 'Catégorie',
      inactives: 'Terminés',
      medicalIntervention: 'Avis médicaux',
      midwifeQuestionsTooltip: "Questions SF en attente d'une réponse IPDE",
      medicalQuestions: 'Questions avec avis médical',
      myQuestions: 'Mes questions',
      myQuestionsTooltip: 'Nombre de questions attribuées : perso (total)',
      noCategory: 'Questions sans catégorie',
      notAttributed: 'Non attribuées',
      notAttributedTooltip: 'Nombre de questions non attribuées',
      notPrio: 'Non Prio',
      noQuestionFilter: 'Aucune question ne correspond à ce(s) filtre(s)',
      ongoingQuestions: 'Questions en cours',
      prio: 'Prio',
      priority: 'Prioritaire ?',
      priorityQuestions: 'Questions prioritaires',
      revives: 'Relances',
      selectOne: 'Sélectionnez une question dans la liste de gauche',
      uncategorized: 'Non catégorisées',
      withMedicalIntervention: '👩🏻‍⚕️ Avis médical demandé ?',
      withMedicalInterventionShort: 'Med requis',
      withoutMedicalInterventionShort: 'Med',
      withNurseIntervention: 'Intervention IPDE ?',
      withNurseInterventionShort: 'IPDE requis',
      withoutNurseInterventionShort: 'IPDE',
      zenTooltip: 'Questions en cours\n(attendant une réponse de notre part)',
    },
    subjectExplorer: {
      category: 'Catégorie',
      discussion: '# de discussion',
      end: 'Fin',
      goToDiscussion: 'Aller sur la discussion',
      keywords: 'Mots clés',
      noMessage: 'Aucun message...',
      noSubjectWithFilters: 'Aucune question ne correspond à ce(s) filtre(s)',
      pro: 'Pro',
      start: 'Début',
      title: 'Super fouine',
    },
    subscription: {
      activateBonus: 'Créer abonnement bonus',
      affiliated: 'Affilié',
      bonus: 'Compte gratuit',
      deactivateBonus: 'Désactiver le compte bonus',
      empty: 'Aucun abonnement pour le moment',
      none: 'Nada',
      paid: 'Souscription payante',
      refresh: 'Rafraîchir le status',
      title: 'Abonnements',
      trial: 'Période essai',
    },
    timezoneSelector: {
      label: 'Fuseau horaire',
      title: 'Fuseau horaire',
    },
    title: {
      admin: "Interface d'administration",
      calendars: 'Calendriers',
      dashboard: 'Tableau de bord',
      help: 'Aide',
      moreInfo: "Plus d'infos",
      patient: 'Parents',
    },
    tools: {
      appert: {
        babyBottles: 'Nombre biberons',
        childWeight: "Poids de l'enfant (en g)",
        description: 'Calculateur méthode Appert',
        formula: 'Formule utilisée (Appert): 250 mL + (poids en gramme) / 10',
        quantityInBottles: 'Soit : %{bottleQuantity} mL par biberon',
        quantityRecommended: 'Quantité de lait recommandé : %{milk} mL / 24h',
        title: 'Méthode Appert',
      },
      common: {
        oneYearShort: '1a',
      },
      feedingAndSleep: {
        legend: {
          babyBottle: 'Biberon',
          breastfeed: 'Allaitement',
          sleep: 'Sommeil',
        },
        title: 'Suivi sommeil/tétées',
        tooltip: {
          duration: 'Durée: %{duration} min',
          quantity: 'Quantité: %{quantity} mL',
        },
        statistics: 'Statistiques',
      },
      weight: {
        dueDate: 'Terme',
        start: 'Début',
        title: 'Poids',
      },
    },
    therapy: {
      audio: '🎧 Audio',
      guide: '📄 Fiche',
      post: '🗞️ Article',
      unknown: 'Inconnu',
      video: '🎞️ Vidéo',
      withConsultation: 'avec cs',
    },
    validation: {
      integer: 'Un chiffre entier est attendu',
    },
  },
};
