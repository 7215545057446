import { Typography, type TypographyProps } from '@mui/material';

export const ClampTypography = ({
  clamp = 1,
  sx,
  children,
  ...props
}: TypographyProps & { clamp?: number }) => {
  return (
    <Typography
      sx={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: clamp ?? 1,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
