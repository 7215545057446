import {
  DateInput,
  SimpleForm,
  BooleanInput,
  required,
  useTranslate,
} from 'react-admin';

import { FormProps } from '../../types';

export const PregnanciesForm = (props: FormProps) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <DateInput
        source="expectedEnd"
        validate={required()}
        variant="standard"
        label={translate('common.expectedEnd')}
      />
      <BooleanInput
        source="active"
        variant="standard"
        label={translate('common.active')}
      />
      <BooleanInput
        source="multiple"
        variant="standard"
        label={translate('common.multiple')}
      />
      <BooleanInput
        source="interrupted"
        variant="standard"
        label={translate('pregnancy.interrupted')}
      />
      <BooleanInput
        source="born"
        variant="standard"
        label={translate('common.born')}
      />
    </SimpleForm>
  );
};
