import { useTranslate } from 'react-admin';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { Box, MenuItem } from '@mui/material';
import { Operator, oppositeOperators } from '@teammay/form-core';

import { type ConditionForm } from './ConditionEdit';
import { COLORS } from '../../../../themes';
import { useAvailableDataSlugs } from '../../hooks/useAvailableDataSlugs';

export const DataRuleEdit = ({
  onBlur,
  field,
  index,
  reversedLogic,
}: {
  onBlur: () => void;
  field: FieldArrayWithId<ConditionForm, 'dataRules', 'id'>;
  index: number;
  reversedLogic?: boolean;
}) => {
  const translate = useTranslate();
  const { control } = useFormContext<ConditionForm>();

  const availableDataSlugs = useAvailableDataSlugs();

  return (
    <Box
      key={field.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: COLORS.GREY_TEXT_LIGHT,
        p: '2rem',
        borderRadius: '1rem',
        flex: 1,
      }}
    >
      <SelectInput
        variant="outlined"
        control={control}
        name={`dataRules.${index}.dataSlug`}
        label={translate('forms.conditions.dataRules.dataSlug')}
        defaultValue={availableDataSlugs[0]}
      >
        {availableDataSlugs.map((slug) => (
          <MenuItem key={slug} value={slug}>
            {slug}
          </MenuItem>
        ))}
      </SelectInput>
      <TextInput
        sx={{ flex: 1 }}
        control={control}
        name={`dataRules.${index}.expectedValue`}
        label={translate('forms.conditions.dataRules.expectedValue')}
        rules={{ required: true }}
        onBlur={onBlur}
      />
      <SelectInput
        variant="outlined"
        control={control}
        name={`dataRules.${index}.operator`}
        label={translate('forms.conditions.dataRules.operator')}
        rules={{ required: true }}
        defaultValue={Operator.EQUAL}
      >
        {Object.values(Operator).map((operator) => (
          <MenuItem key={operator} value={operator}>
            {reversedLogic ? oppositeOperators[operator] : operator}
          </MenuItem>
        ))}
      </SelectInput>
    </Box>
  );
};
