import _clone from 'lodash/clone';
import _isEqual from 'lodash/isEqual';
import _memoize from 'lodash/memoize';
import _merge from 'lodash/merge';

import { Socket, SocketEvents, SocketHandlerGenerator } from '@boTypes/socket';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@hooks/queryWrappers';
import {
  addSubjectInAttributions,
  removeSubjectFromAttributions,
  formatSubjectAttributions,
  ensureStaffListInAttributions,
  addSubjectToWaitingList,
  removeSubjectFromWaitingList,
} from '@utils/subjectAttributions';

import { useSocketHandlers } from './useSocketHandlers';

export const SUBJECT_ATTRIBUTION_KEY = ['subject-attribution'];
export const SUBJECT_WAITING_ATTRIBUTION_KEY = ['subject-waiting-attribution'];
const subjectAttributionSocketHandlers: SocketHandlerGenerator<[QueryClient]> =
  {
    [SocketEvents.SUBJECT_ATTRIBUTION_ADDED]:
      (queryClient) =>
      ({ subjectIds, staffUserIds }) =>
        queryClient.setQueryData<{
          staffUserIds: number[];
          subjectIds: number[][];
        }>(SUBJECT_ATTRIBUTION_KEY, (previousData) =>
          addSubjectInAttributions(previousData, {
            subjectIds,
            staffUserIds,
          }),
        ),
    [SocketEvents.SUBJECT_ATTRIBUTION_REMOVED]:
      (queryClient) =>
      ({ subjectIds, staffUserIds }) =>
        queryClient.setQueryData<{
          staffUserIds: number[];
          subjectIds: number[][];
        }>(SUBJECT_ATTRIBUTION_KEY, (previousData) =>
          removeSubjectFromAttributions(previousData, {
            subjectIds,
            staffUserIds,
          }),
        ),
    [SocketEvents.ACTIVE_SESSIONS_LIST_CHANGED]:
      (queryClient) =>
      ({ users }) =>
        queryClient.setQueryData<{
          staffUserIds: number[];
          subjectIds: number[][];
        }>(SUBJECT_ATTRIBUTION_KEY, (previousData) =>
          ensureStaffListInAttributions(previousData, users),
        ),
  };

// export a version of the hook that does not perform the query nor the socket connection
// to ensure that there is no conflict with the attributions in the discussion provider
export const useSubjectAttributions = () => _useSubjectAttributions();

export const _useSubjectAttributions = (socket?: Socket, enabled = false) => {
  const queryClient = useQueryClient();
  useSocketHandlers(socket, subjectAttributionSocketHandlers, queryClient);

  return useQuery<
    { staffUserIds: number[]; subjectIds: number[][] },
    any,
    {
      staffUserFromSubjectId: Record<number, number>;
      subjectsFromStaffUSerId: Record<number, number[]>;
    }
  >(
    SUBJECT_ATTRIBUTION_KEY,
    () => ({
      url: '/api/chatAttribution/attributed',
    }),
    {
      select: formatSubjectAttributions,
      enabled,
      refetchInterval: () => {
        if (!socket) {
          return 60 * 1000;
        }
        return 3 * 60 * 1000;
      },
    },
  );
};

export const useGiveMeMore = () => {
  const queryClient = useQueryClient();
  return useMutation<
    { staffUserIds: number[]; subjectIds: number[][] },
    any,
    void
  >(
    ['giveMeMore'],
    () => ({ method: 'get', url: '/api/chatAttribution/giveMeMore' }),
    {
      onSuccess(data) {
        queryClient.setQueryData<{
          staffUserIds: number[];
          subjectIds: number[][];
        }>(SUBJECT_ATTRIBUTION_KEY, data);
      },
    },
  );
};

const subjectWaitingAttributionSocketHandlers: SocketHandlerGenerator<
  [QueryClient]
> = {
  [SocketEvents.SUBJECT_ADDED_TO_WAITING_LIST]:
    (queryClient) =>
    ({ subjectId }) =>
      queryClient.setQueryData<number[]>(
        SUBJECT_WAITING_ATTRIBUTION_KEY,
        (previousData) => addSubjectToWaitingList(previousData, subjectId),
      ),
  [SocketEvents.SUBJECT_REMOVED_FROM_WAITING_LIST]:
    (queryClient) =>
    ({ subjectId }) =>
      queryClient.setQueryData<number[]>(
        SUBJECT_WAITING_ATTRIBUTION_KEY,
        (previousData) => removeSubjectFromWaitingList(previousData, subjectId),
      ),
};

// export a version of the hook that does not perform the query nor the socket connection
// to ensure that there is no conflict with the attributions in the discussion provider
export const useWaitingAttributions = () => _useWaitingAttributions();

export const _useWaitingAttributions = (socket?: Socket, enabled = false) => {
  const queryClient = useQueryClient();
  useSocketHandlers(
    socket,
    subjectWaitingAttributionSocketHandlers,
    queryClient,
  );

  return useQuery<number[], any>(
    SUBJECT_WAITING_ATTRIBUTION_KEY,
    () => ({
      url: '/api/chatAttribution/waiting',
    }),
    {
      enabled,
      refetchInterval: () => {
        if (!socket) {
          return 60 * 1000;
        }
        return 3 * 60 * 1000;
      },
    },
  );
};
