import { Row } from '@components/generic/Row';
import { Add, Scoreboard } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Operator, Question, ScoreMode, ScoringRule } from '@teammay/form-core';

import { isScoringRulesValid } from '../../../utils/validityCheck';
import { EditWrapper } from '../../EditWrapper';
import { ScoringRulesEdit } from '../../scoringRules/ScoringRulesEdit';
import { WarningTile } from '../../WarningTile';

export function ScoringButton({
  question,
  setRule,
}: {
  question: Question;
  setRule: (rules: ScoringRule[]) => void;
}) {
  const hasScoringRule = !!question.scoringRules?.length;
  const invalidFields = isScoringRulesValid(question);

  return (
    <>
      {hasScoringRule ? (
        <Row
          onClick={() => setRule(question.scoringRules)}
          sx={{ cursor: 'pointer' }}
        >
          <>
            <WarningTile invalidFields={invalidFields} />
            <Scoreboard sx={{ fontSize: '0.625rem' }} color="primary" />
            <Typography
              color="primary"
              variant="body2"
              sx={{
                fontSize: '0.625rem',
                marginLeft: '0.25rem',
              }}
            >
              Scoring
            </Typography>
          </>
        </Row>
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            cursor: 'pointer',
          }}
        >
          <Add sx={{ fontSize: '0.625rem' }} />
          <Typography
            variant="body2"
            onClick={() => {
              const newRule: ScoringRule = {
                id: self.crypto.randomUUID(),
                questionId: question.id,
                expectedValue: '',
                scoringValue: '',
                operator: Operator.EQUAL,
                mode: ScoreMode.TAG,
              };
              setRule([newRule]);
            }}
            sx={{
              cursor: 'pointer',
              fontSize: '0.625rem',
              marginLeft: '0.25rem',
            }}
          >
            Scoring
          </Typography>
        </Box>
      )}
    </>
  );
}

export const ScoringRuleEdit = ({
  question,
  scoringRule,
  setRule,
  onClick,
  targetObject,
}: {
  question: Question;
  scoringRule: ScoringRule[];
  setRule: (scoringRule: ScoringRule[]) => void;
  onClick: (question: Question) => void;
  targetObject: { type: 'question' | 'template' | 'step'; id: string };
}) => {
  return (
    scoringRule && (
      <EditWrapper<Question>
        content={{ ...question, scoringRules: scoringRule }}
        onSave={(content) => {
          question.scoringRules = content.scoringRules;
          onClick(content);
          setRule(null);
        }}
        open={!!scoringRule}
        onCancel={() => setRule(null)}
        defaultValues={{
          ...question,
          scoringRules: scoringRule,
        }}
        onDelete={() => {
          question.scoringRules = null;
          onClick(question);
          setRule(null);
        }}
        FormComponent={ScoringRulesEdit}
        targetObject={targetObject}
      />
    )
  );
};
