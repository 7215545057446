import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

import { FormProps } from '../../types';
import { User } from '../../types/user';

const optionText = (record: User) =>
  (record?.firstName && `${record.firstName} ${record.lastName}`) || '---';

export const PricingProCustomForm = (props: FormProps) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <TextInput
        source="name"
        validate={required()}
        variant="standard"
        label={translate('common.name')}
      />
      <ReferenceInput
        source="staffUserId"
        reference="users"
        variant="standard"
        label={translate('common.pro')}
      >
        <AutocompleteInput
          label={translate('common.pro')}
          variant="standard"
          optionText={optionText}
          matchSuggestion={(filterValue, suggestion) => {
            if (filterValue === '') {
              return true;
            }
            const lowerFilterValue = filterValue.toLowerCase();
            const lowerSuggestion = optionText(suggestion).toLowerCase();
            return lowerSuggestion.includes(lowerFilterValue);
          }}
        />
      </ReferenceInput>
      <TextInput
        source="pricePerUnitBase"
        variant="standard"
        label={translate('pricingProCustom.base')}
      />
      <TextInput
        label={translate('pricingProCustom.night')}
        source="pricePerUnitNight"
        variant="standard"
      />
      <TextInput
        source="pricePerUnitSundayAndHoliday"
        variant="standard"
        label={translate('pricingProCustom.sundayHoliday')}
      />
      <TextInput
        source="onCallNonActivatedRate"
        variant="standard"
        label={translate('pricingProCustom.rateOnCall')}
      />
      <BooleanInput
        source="vat"
        variant="standard"
        defaultChecked
        label={translate('pricingProCustom.vat')}
      />
    </SimpleForm>
  );
};
