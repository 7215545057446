import {
  BooleanInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

export const ScenariosForm = (props) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <TextInput
        source="name"
        validate={required()}
        variant="standard"
        label={translate('common.name')}
      />
      <TextInput
        multiline
        source="description"
        validate={required()}
        variant="standard"
        label={translate('common.description')}
      />
      <BooleanInput
        defaultValue={false}
        source="legacy"
        variant="standard"
        label={translate('common.legacy')}
      />
    </SimpleForm>
  );
};
