import { Create, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { PricingProGenericForm } from './form';

export const PricingProGenericCreate = () => {
  const location = useLocation();
  const translate = useTranslate();

  return (
    <Create
      resource="pricing-pro-generic"
      title={translate('pricingProGeneric.add')}
      redirect={location?.state?.redirect || '/pricing-pro'}
    >
      <PricingProGenericForm />
    </Create>
  );
};
