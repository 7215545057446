import { AxiosRequestConfig } from 'axios';

import { AgeLevel, Categories } from '@boTypes/cms';
import { FertiTip } from '@boTypes/fertiTip';
import Folder from '@boTypes/folder';
import ItemSuggestion from '@boTypes/ItemSuggestion';
import PregnancyQuestion from '@boTypes/PregnancyQuestion';
import {
  QueryKey,
  useCMSQuery,
  useInfiniteSearchQuery,
  useSearchQuery,
} from '@hooks/queryWrappers';
import { keepPreviousData } from '@tanstack/react-query';
import { getSmallestImageUrlAvailableFor } from '@utils/cmsFiles';

import { formatSearchFilters } from './formatSearchFilters';
import { ContentType } from '../entities/library/contentTextList';
import { DailyTip, Guide, Macro, Masterclass, Post, WeeklyTip } from '../types';

const getListCMSRequest = ({ url }: { url: string }): AxiosRequestConfig => {
  return {
    url,
    method: 'GET',
  };
};

const getDetailCMSRequest = ({
  lookup,
  url,
}: {
  lookup: string | number;
  url: string;
}): AxiosRequestConfig => {
  return {
    url: url + '/' + lookup,
  };
};

const fetchCategoriesList = ({ pregnancy }) =>
  getListCMSRequest({
    url: `/pro/categories${
      pregnancy !== undefined
        ? '?filters[pregnancy]=' + (pregnancy ? 'true' : 'false')
        : ''
    }`,
  });

export const usePostItem = (lookup: string) => {
  return useCMSQuery<Post>(['post-item', lookup], () =>
    getDetailCMSRequest({ lookup, url: '/pro/posts' }),
  );
};

export const useGuideItem = (lookup: string) => {
  return useCMSQuery<Guide>(['guide-item', lookup], () =>
    getDetailCMSRequest({ lookup, url: '/pro/guides' }),
  );
};

export const useMasterclassItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<Masterclass>(
    ['masterclass-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/masterclasses' }),
    {
      select: (res) => {
        res.trackTitles = res.tracks.map((track) => track.title);
        return res;
      },
    },
  );
  return { data, isLoading };
};

const formatMacros = (macroInit: Macro): Macro => {
  const macro = { ...macroInit };
  const { suggestionsContents, suggestionsIds, suggestionsTitles } = macro;
  if (!macro.suggestions?.length && suggestionsContents?.length) {
    macro.suggestions = suggestionsContents.map(
      (content: string, index: number) => ({
        content,
        title: suggestionsTitles[index],
        id: suggestionsIds[index],
      }),
    );
  }
  return macro;
};

export const useFolderItem = (lookup: string) => {
  return useCMSQuery<Folder>(
    ['folder-item', lookup],
    () => getDetailCMSRequest({ lookup, url: '/pro/programs' }),
    {
      select: ({ program_steps, ...rest }) => {
        rest.programSteps = program_steps.map((el) => {
          el.content = el.content.map((_el) => {
            if (_el.__component === 'step.article-wrapper') {
              _el.post.coverThumbnailUrl = getSmallestImageUrlAvailableFor(
                _el.post.cover,
              );
            }
            return _el;
          });
          return el;
        });

        return rest;
      },
    },
  );
};

export const useDailyTipItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<DailyTip>(
    ['daily-tip-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/daily-tips' }),
    { staleTime: 5 * 60 * 1000 },
  );
  return { data, isLoading };
};

export const useWeeklyTipItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<WeeklyTip>(
    ['weekly-tip-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/weekly-tips' }),
    { staleTime: 5 * 60 * 1000 },
  );
  return { data, isLoading };
};

export const useAgeLevelsList = () =>
  useCMSQuery<AgeLevel[]>(
    ['age-levels'],
    getListCMSRequest({ url: '/pro/age-levels?pregnancy=false' }),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

export const useCategoriesList = (pregnancy: boolean) =>
  useCMSQuery<Categories[]>(
    ['categories', pregnancy],
    () => fetchCategoriesList({ pregnancy }),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

type IndexUid =
  | 'macros'
  | 'guides'
  | 'posts'
  | 'masterclasses'
  | 'programs'
  | 'daily-tips'
  | 'weekly-tips'
  | 'pregnancy-questions'
  | 'item-suggestions'
  | 'ferti-tips';
const contentToIndexUid: Record<ContentType, IndexUid> = {
  [ContentType.MACRO]: 'macros',
  [ContentType.GUIDES]: 'guides',
  [ContentType.POSTS]: 'posts',
  [ContentType.MASTERCLASSES]: 'masterclasses',
  [ContentType.FOLDER]: 'programs',
  [ContentType.DAILY_TIPS]: 'daily-tips',
  [ContentType.WEEKLY_TIPS]: 'weekly-tips',
  [ContentType.PREGNANCY_QUESTIONS]: 'pregnancy-questions',
  [ContentType.ITEM_SUGGESTIONS]: 'item-suggestions',
  [ContentType.FERTI_TIPS]: 'ferti-tips',
};

const hasStandardFilter: Record<IndexUid, boolean> = {
  macros: true,
  guides: true,
  posts: true,
  masterclasses: true,
  programs: true,
  'daily-tips': true,
  'weekly-tips': true,
  'pregnancy-questions': true,
  'item-suggestions': true,
  'ferti-tips': false,
};

type Output<Kind extends ContentType> = Kind extends ContentType.MACRO
  ? Macro
  : Kind extends ContentType.GUIDES
    ? Guide
    : Kind extends ContentType.POSTS
      ? Post
      : Kind extends ContentType.MASTERCLASSES
        ? Masterclass
        : Kind extends ContentType.PREGNANCY_QUESTIONS
          ? PregnancyQuestion
          : Kind extends ContentType.ITEM_SUGGESTIONS
            ? ItemSuggestion
            : Kind extends ContentType.FOLDER
              ? Folder
              : Kind extends ContentType.DAILY_TIPS
                ? DailyTip
                : Kind extends ContentType.WEEKLY_TIPS
                  ? WeeklyTip
                  : Kind extends ContentType.FERTI_TIPS
                    ? FertiTip
                    : never;

export type OneSearchQueryData<T> = {
  hits: T[];
  nbHits: number;
  exhaustiveNbHits: boolean;
  query: string;
  limit: number;
  offset: number;
  processingTimeMs: number;
};

export const useOneKindList = <Kind extends ContentType>(
  selectedContentType: Kind,
  {
    search,
    category,
    ageLevel,
    pregnancy = null,
    searchKey = '',
    enabled = true,
  }: {
    search?: string;
    category?: number;
    ageLevel?: number;
    pregnancy?: boolean | null;
    searchKey?: string;
    enabled?: boolean;
  },
) => {
  const filter = formatSearchFilters({ category, ageLevel, pregnancy });
  return useInfiniteSearchQuery<
    OneSearchQueryData<Output<Kind>>,
    any,
    Output<Kind>[],
    QueryKey,
    { offset: number; limit: number }
  >({
    enabled: !!selectedContentType && !!searchKey && enabled,
    queryKey: search
      ? ['library', selectedContentType, ageLevel, category, pregnancy, search]
      : ['library', selectedContentType, ageLevel, category, pregnancy],
    initialPageParam: { offset: 0, limit: 20 },
    queryFn: ({ signal, pageParam }) => ({
      url: `/indexes/${contentToIndexUid[selectedContentType]}/search`,
      data: {
        q: search,
        filter,
        ...pageParam,
      },
      method: 'POST',
      headers: {
        Authorization: `Bearer ${searchKey}`,
      },
      signal,
    }),
    getNextPageParam: (lastPage) => {
      if (lastPage.hits.length < lastPage.limit) {
        return undefined;
      }
      return { offset: lastPage.offset + lastPage.limit, limit: 20 };
    },
    select: (data) =>
      selectedContentType === ContentType.MACRO
        ? (data.pages.flatMap((page) =>
            (page.hits as Macro[]).map(formatMacros),
          ) as Output<Kind>[])
        : data.pages.flatMap((page) => page.hits),
    staleTime: 5 * 60 * 1000,
    placeholderData: keepPreviousData,
  });
};

type MultiSearchInput = {
  queries: {
    q?: string;
    indexUid: IndexUid;
    filter?: string;
    attributesToRetrieve?: string[];
    attributesToHighlight?: string[];
    highlightPreTag?: string;
    highlightPostTag?: string;
    federationOptions?: { weight?: number };
    sort?: string[];
    limit?: number;
  }[];
};

type BaseHit = {
  estimatedTotalHits: number;
  query: string;
  limit: number;
  offset: number;
};
type MultiSearchHits =
  | ({ indexUid: 'macros'; hits: Macro[] } & BaseHit)
  | ({ indexUid: 'guides'; hits: Guide[] } & BaseHit)
  | ({ indexUid: 'posts'; hits: Post[] } & BaseHit)
  | ({ indexUid: 'masterclasses'; hits: Masterclass[] } & BaseHit)
  | ({ indexUid: 'pregnancy-questions'; hits: PregnancyQuestion[] } & BaseHit)
  | ({ indexUid: 'item-suggestions'; hits: ItemSuggestion[] } & BaseHit)
  | ({ indexUid: 'programs'; hits: Folder[] } & BaseHit)
  | ({ indexUid: 'daily-tips'; hits: DailyTip[] } & BaseHit)
  | ({ indexUid: 'weekly-tips'; hits: WeeklyTip[] } & BaseHit)
  | ({ indexUid: 'ferti-tips'; hits: FertiTip[] } & BaseHit);

export type CMSSearchResults = {
  macros: Macro[];
  guides: Guide[];
  posts: Post[];
  masterclasses: Masterclass[];
  folders: Folder[];
  'daily-tips': DailyTip[];
  'weekly-tips': WeeklyTip[];
};

export const useMultiSearch = ({
  search,
  searchKey,
  ageLevel,
  category,
  pregnancy,
  limit = 40,
  enabled = true,
}: {
  search: string;
  searchKey?: string;
  ageLevel?: number;
  category?: number;
  pregnancy?: boolean;
  limit?: number;
  enabled?: boolean;
}) => {
  return useSearchQuery<{ results: MultiSearchHits[] }, any, CMSSearchResults>(
    ['multi-search', search, ageLevel, category, pregnancy],
    ({ signal }) => ({
      url: '/multi-search',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${searchKey}`,
      },
      data: {
        queries: Object.values(contentToIndexUid)
          .filter(
            (indexUid) =>
              indexUid !== 'item-suggestions' &&
              indexUid !== 'pregnancy-questions',
          )
          .map((indexUid) => {
            const filter = hasStandardFilter[indexUid]
              ? formatSearchFilters({
                  category,
                  ageLevel,
                  pregnancy,
                })
              : undefined;
            return {
              indexUid,
              q: search,
              filter,
              limit,
            };
          }),
      } satisfies MultiSearchInput,
      signal,
    }),
    {
      enabled: !!searchKey && enabled,
      select: (data) =>
        data.results.reduce(
          (acc, curr) => {
            if (curr.indexUid === 'macros') {
              acc[curr.indexUid] = curr.hits.map(formatMacros);
            } else {
              acc[curr.indexUid] = curr.hits;
            }
            return acc;
          },
          {
            macros: [],
            guides: [],
            posts: [],
            masterclasses: [],
            folders: [],
            'daily-tips': [],
            'weekly-tips': [],
          },
        ),
      staleTime: 5 * 60 * 1000,
      placeholderData: keepPreviousData,
    },
  );
};
