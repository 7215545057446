import dayjs, { ManipulateType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import memoize from 'lodash/memoize';
import { useEffect, useMemo } from 'react';
import { dayjsLocalizer } from 'react-big-calendar';
import { views as Views } from 'react-big-calendar/lib/utils/constants';
import 'dayjs/locale/fr';

import { StaffUser } from '@boTypes/staffUser';

import Agenda from './agenda/agendaView';
import { eventPropGetterByColor, inverseWrapper } from './events/events';
import { formats } from './formats';
import { messages } from './messages';
import { monthHeader } from './month/monthHeader';
import Toolbar from './toolbar';
import { weekHeader } from './week/weekHeader';
import { useSelector } from '../../store';

dayjs.locale('FR-fr');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const useCalendarConfig = (
  view: Views[keyof Views],
  displaySelector: boolean = false,
  staffUsers: Record<StaffUser['id'], StaffUser>,
  displayedUsers: Record<StaffUser['id'], boolean>,
  setDisplayDrawer?: (stateFn: (value: boolean) => boolean) => void,
  setTimezoneDrawer?: (value: boolean) => void,
  sortedEvents = false,
) => {
  const tz = useSelector((state) => state.timezone);
  dayjs.tz.setDefault(tz);

  const localizer = useMemo(() => {
    // ⚡ tz costs a lot => memoize it
    const dayjsTZ = memoize(
      (date) => dayjs(date).tz(tz),
      (date) => date.toString(),
    );

    const _localizer = dayjsLocalizer(dayjs);

    function fixUnit(
      unit: ManipulateType | 'FullYear' | undefined,
    ): ManipulateType | undefined {
      let datePart: ManipulateType | 'FullYear' | 'fullyear' | undefined = unit
        ? (unit.toLowerCase() as ManipulateType | 'fullyear')
        : unit;
      if (datePart === 'FullYear' || datePart === 'fullyear') {
        return 'year';
      }
      return datePart || undefined;
    }

    _localizer.inRange = (
      day: Date,
      min: Date,
      max: Date,
      unit: ManipulateType | 'FullYear' = 'day',
    ) => {
      const datePart = fixUnit(unit);
      // ⚡ improve performance by limiting the usage of dayjs
      if (
        day.getTime() - min.getTime() < -86400000 ||
        day.getTime() - max.getTime() > 86400000
      ) {
        return false;
      }
      const djDay = dayjsTZ(day);
      const djMax = dayjsTZ(max);
      if (djMax.diff(djDay, 'minute') < 1) {
        return false;
      }
      const djMin = dayjsTZ(min);
      return djDay.isBetween(djMin, djMax, datePart, '[]');
    };

    const endOfDay = memoize(
      (date) => dayjsTZ(date).endOf('day').toDate(),
      (date) => date.toString(),
    );

    _localizer.inEventRange = ({
      event: { start, end },
      range: { start: rangeStart, end: rangeEnd },
    }: {
      event: { start: Date; end: Date };
      range: { start: Date; end: Date };
    }) => {
      // ⚡ improve performance by limiting the usage of dayjs
      // On some views, rangeEnd may be === to rangeStart because why not
      // Also, it's sometimes not set to the end of the day
      // fuck that world
      rangeEnd = endOfDay(rangeEnd);
      return (
        start.getTime() <= rangeEnd.getTime() &&
        rangeStart.getTime() < end.getTime()
      );
    };

    _localizer.sortEvents = sortedEvents
      ? // This puzzling function is used to avoid to sort events ! We already provide events in order so sorting them
        // is a useless loss of performance
        () => 0
      : // This is an optimized version of the default sortEvents function... Still costs 2s for 350 events
        function sortEvents({
          evtA: { start: aStart, end: aEnd, allDay: aAllDay },
          evtB: { start: bStart, end: bEnd, allDay: bAllDay },
        }) {
          // start not on same day with large timezone margin
          if (Math.abs(aStart.getTime() - bStart.getTime()) > 86400000) {
            return aStart.getTime() - bStart.getTime();
          }

          // start not on same day with exact timezone margin
          const aStartDay = dayjsTZ(aStart);
          const bStartDay = dayjsTZ(aStart);
          if (!aStartDay.isSame(bStartDay, 'day')) {
            return aStart.getTime() - bStart.getTime();
          }

          const durA = aStartDay.diff(aEnd, 'day');
          const durB = bStartDay.diff(bEnd, 'day');

          const aAllDayNumb = aAllDay ? 1 : 0;
          const bAllDayNumb = bAllDay ? 1 : 0;

          return (
            Math.max(durB, 1) - Math.max(durA, 1) || // events spanning multiple days go first
            aAllDayNumb - bAllDayNumb || // then allDay single day events
            +aStart - +bStart || // then sort by start time *don't need dayjs conversion here
            +aEnd - +bEnd // then sort by end time *don't need dayjs conversion here either
          );
        };

    _localizer.isSameDate = memoize(
      (date1: Date, date2: Date) => {
        // improve performance by limiting the usage of dayjs
        if (Math.abs(date1.getTime() - date2.getTime()) > 86400000) {
          return false;
        }

        const dt = dayjsTZ(date1);
        const dt2 = dayjsTZ(date2);
        if (
          view !== Views.MONTH &&
          Math.abs(dt2.diff(dt.endOf('day'), 'minute')) < 1
        ) {
          return true;
        }
        return dt.isSame(dt2, 'day');
      },
      (date1, date2) => `${date1.toString()}${date2.toString()}`,
    );

    _localizer.eq = (
      a: string | number | Date | dayjs.Dayjs,
      b: string | number | Date | dayjs.Dayjs,
      unit: ManipulateType | 'FullYear' | undefined,
    ) => {
      return dayjs(a).isSame(dayjs(b), fixUnit(unit));
    };

    _localizer.neq = (
      a: string | number | Date | dayjs.Dayjs,
      b: string | number | Date | dayjs.Dayjs,
      unit: ManipulateType | 'FullYear' | undefined,
    ) => {
      return !_localizer.eq(a, b, unit);
    };

    _localizer.merge = (
      date: string | number | Date | dayjs.Dayjs,
      time: string | number | Date | dayjs.Dayjs,
    ) => {
      if (!date && !time) {
        return null;
      }

      const tm = dayjs(time).tz(tz).format('HH:mm:ss');
      const dt = dayjs(date).tz(tz).format('MM/DD/YYYY');
      // We do it this way to avoid issues when timezone switching
      return dayjs(`${dt} ${tm}`, 'MM/DD/YYYY HH:mm:ss').tz(tz, true).toDate();
    };

    _localizer.range = (
      start: Date,
      end: Date,
      unit: ManipulateType | 'FullYear' = 'day',
    ) => {
      const datePart = fixUnit(unit);
      // because the add method will put these in tz, we have to start that way
      let current = dayjs(start).tz(tz);
      const days = [];
      while (_localizer.lte(current, end)) {
        days.push(current.toDate());
        current = current.add(1, datePart);
      }

      return days;
    };

    _localizer.gt = (
      dateA: string | number | Date | dayjs.Dayjs,
      dateB: string | number | Date | dayjs.Dayjs,
      unit: ManipulateType | undefined,
    ) => {
      return dayjsTZ(dateA).isAfter(dayjsTZ(dateB), fixUnit(unit));
    };

    _localizer.lt = (
      dateA: string | number | Date | dayjs.Dayjs,
      dateB: string | number | Date | dayjs.Dayjs,
      unit: ManipulateType | undefined,
    ) => {
      return dayjsTZ(dateA).isBefore(dayjsTZ(dateB), fixUnit(unit));
    };

    _localizer.gte = (
      dateA: string | number | Date | dayjs.Dayjs,
      dateB: string | number | Date | dayjs.Dayjs,
      unit: ManipulateType | undefined,
    ) => {
      return dayjsTZ(dateA).isSameOrAfter(dayjsTZ(dateB), fixUnit(unit));
    };

    _localizer.lte = (
      dateA: string | number | Date | dayjs.Dayjs,
      dateB: string | number | Date | dayjs.Dayjs,
      unit: ManipulateType | undefined,
    ) => {
      return dayjsTZ(dateA).isSameOrBefore(dayjsTZ(dateB), fixUnit(unit));
    };

    _localizer.format = (...args) => {
      if (typeof args[1] === 'function') {
        return args[1](args[0], args[2], _localizer);
      }
      return dayjsTZ(args[0]).format(args[1]);
    };

    return _localizer;
  }, [tz, view, sortedEvents]);

  // Reset tz after unmount
  useEffect(() => {
    dayjs.tz.setDefault(tz);
    return () => dayjs.tz.setDefault();
  });

  return useMemo(
    () => ({
      now: dayjs(),
      views: {
        month: true,
        week: true,
        day: true,
        agenda: Agenda,
      },
      formats,
      components: {
        week: {
          header: weekHeader,
        },
        month: {
          header: monthHeader,
        },
        eventWrapper: inverseWrapper,
        toolbar: (props) => (
          <Toolbar
            {...props}
            displaySelector={displaySelector}
            staffUsers={staffUsers}
            displayedUsers={displayedUsers}
            setDisplayDrawer={setDisplayDrawer}
            setTimezoneDrawer={setTimezoneDrawer}
          />
        ),
      },
      messages: messages(),
      eventPropGetter: eventPropGetterByColor,
      localizer,
    }),
    [
      localizer,
      staffUsers,
      displayedUsers,
      setDisplayDrawer,
      setTimezoneDrawer,
      displaySelector,
    ],
  );
};
