import {
  Datagrid,
  TextField,
  TextInput,
  TopToolbar,
  BooleanInput,
  useTranslate,
} from 'react-admin';

import { AvatarField } from '@components/fields';
import { CreateButton } from '@components/generic/CreateButton';
import { List } from '@components/generic/List';

import { i18nProvider } from '../../App';

const getUsersFilters = () => [
  <TextInput
    source="firstName"
    alwaysOn
    variant="standard"
    label={i18nProvider.translate('common.firstName')}
  />,
  <TextInput
    source="lastName"
    alwaysOn
    variant="standard"
    label={i18nProvider.translate('common.name')}
  />,
  <TextInput source="email" alwaysOn variant="standard" />,
  <BooleanInput
    label={i18nProvider.translate('common.active')}
    source="active"
    alwaysOn
    variant="standard"
  />,
];

const UsersToolbar = () => (
  <TopToolbar>
    <CreateButton redirect="/code-affiliations" />
  </TopToolbar>
);

export const UsersList = () => {
  const translate = useTranslate();

  return (
    <List
      filters={getUsersFilters()}
      filterDefaultValues={{ active: true }}
      perPage={10}
      actions={<UsersToolbar />}
      sx={{ padding: '0 1rem' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <AvatarField label={translate('common.avatar')} />
        <TextField source="firstName" label={translate('common.firstName')} />
        <TextField source="lastName" label={translate('common.name')} />
        <TextField source="email" label={translate('common.email')} />
        <TextField source="phone" label="📱" />
      </Datagrid>
    </List>
  );
};
