import { AxiosError, AxiosResponse } from 'axios';
import { produce } from 'immer';
import differenceBy from 'lodash/differenceBy';
import { useCallback, useMemo } from 'react';
import { Identifier, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';

import { Discussion } from '@boTypes/discussion';
import {
  DiscussionEvent,
  DiscussionEventType,
  RawDiscussionEvent,
} from '@boTypes/discussionEvent';
import { HandoverRevive } from '@boTypes/handover';
import { ChatData } from '@boTypes/messages';
import { Socket, SocketEvents, SocketHandlerGenerator } from '@boTypes/socket';
import { MinimalSubject } from '@boTypes/subject';
import { useTypingEvent } from '@components/discussions/chat/typingIndicator';
import { InfiniteData, QueryClient } from '@tanstack/react-query';
import { ITypingMessage, MayParticipant } from '@teammay/mayssenger';
import { formatMessages } from '@utils/messagesFormatting';

import { useCloseHandoverRevive } from './handover';
import { useInfiniteQuery, useQueryClient, useMutation } from './queryWrappers';
import { useGetVaryingMany } from './useGetVaryingMany';
import { useSocketHandlers } from './useSocketHandlers';
import { logPostedAndClosedRevive } from '../analytics/events';
import { useSelector } from '../store';
import { setTargetEventId } from '../store/discussion';

const pageLength = 20;

const addMessage = produce(
  (
    previousData: InfiniteData<AxiosResponse<DiscussionEvent[]>>,
    message: DiscussionEvent,
  ) => {
    if (!previousData?.pages?.length) {
      return previousData;
    }
    if (!previousData.pages[0]?.data.length) {
      return previousData;
    }

    // messages are only inserted in first page
    const date = new Date(message.createdAt);
    if (
      date.getTime() >=
        new Date(previousData.pages[0].data[0].createdAt).getTime() &&
      previousData.pages[0].data.every((m) => m.id !== message.id)
    ) {
      previousData.pages[0].data.unshift(message);
    }
  },
);

// TODO test it
const insertMessages = produce(
  (
    previousData: InfiniteData<AxiosResponse<DiscussionEvent[]>>,
    messages: DiscussionEvent[],
  ) => {
    if (!previousData?.pages?.length) {
      return previousData;
    }
    messages.forEach((message) => {
      const date = new Date(message.createdAt);
      let pageIndex = previousData.pages.findIndex((page) => {
        const lastMessage = page.data[page.data.length - 1];
        return date.getTime() >= new Date(lastMessage.createdAt).getTime();
      });
      if (pageIndex === -1) {
        pageIndex = previousData.pages.length - 1;
        previousData.pages[pageIndex].data.push(message);
      } else {
        const inPageIndex = previousData.pages[pageIndex].data.findIndex(
          (m) => date.getTime() > new Date(m.createdAt).getTime(),
        );
        if (inPageIndex === -1) {
          previousData.pages[pageIndex].data.push(message);
        } else {
          previousData.pages[pageIndex].data.splice(inPageIndex, 0, message);
        }
      }
    });
  },
);

const updateMessage = produce(
  (
    previousData: InfiniteData<AxiosResponse<DiscussionEvent[]>>,
    message: DiscussionEvent,
  ) => {
    if (!previousData?.pages?.length) {
      return previousData;
    }

    for (const page of previousData.pages) {
      const messageIndex = page.data.findIndex((m) => message.id === m.id);
      if (messageIndex !== -1) {
        page.data[messageIndex] = message;
        break;
      }
    }
  },
);

const socketMessageHandlerGenerator: SocketHandlerGenerator<[QueryClient]> = {
  [SocketEvents.NEW_DISCUSSION_EVENT]:
    (queryClient: QueryClient) =>
    ({
      discussion,
      event,
    }: {
      discussion: Discussion;
      event: DiscussionEvent;
    }) => {
      queryClient.setQueriesData<
        InfiniteData<AxiosResponse<DiscussionEvent[]>>
      >({ queryKey: ['messages', discussion.id] }, (previousMessages) =>
        addMessage(previousMessages, event),
      );
    },
  [SocketEvents.UPDATE_DISCUSSION_EVENT]:
    (queryClient: QueryClient) =>
    ({
      event,
      discussionId: eventDiscussion,
    }: {
      event: DiscussionEvent;
      discussionId: Identifier;
    }) => {
      queryClient.setQueriesData<
        InfiniteData<AxiosResponse<DiscussionEvent[]>>
      >({ queryKey: ['messages', eventDiscussion] }, (previousMessages) =>
        updateMessage(previousMessages, event),
      );
    },
};

export const useSocketDiscussionEventHandlers = (socket: Socket) => {
  const queryClient = useQueryClient();
  useSocketHandlers(socket, socketMessageHandlerGenerator, queryClient);
};

export const useTargetSubject = (discussionId?: Discussion['id']) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data } = useInfiniteQuery<DiscussionEvent[]>({
    queryKey: ['messages', discussionId],
    queryFn: () => ({}),
    enabled: false,
    getNextPageParam: () => undefined,
    initialPageParam: undefined,
  });

  const lastEventId = useMemo(() => {
    if (!data?.pages?.length) {
      return;
    }
    const lastPage = data.pages[data.pages.length - 1]?.data ?? [];

    if (!lastPage.length) {
      return;
    }
    return lastPage[lastPage.length - 1].id;
  }, [data]);

  const { mutateAsync: fetchSubject } = useMutation<
    DiscussionEvent[],
    AxiosError,
    MinimalSubject
  >(['fetchSubject', lastEventId], (subject) => ({
    method: 'get',
    url: `/api/discussion_events/${discussionId}`,
    params: {
      end: subject.firstMessageAt,
      lastEventId,
    },
  }));

  return useCallback(
    async (subject: MinimalSubject) => {
      // If revives points to a subject that has no messages, we don't want to highlight anything
      if (subject.firstMessageAt === null) {
        setTargetEventId({ targetEventId: null, highlightedEventIds: [] });
        return;
      }
      const subjectId = subject.id;
      const messages = data?.pages.flatMap(({ data: d }) => d).reverse();
      const hasSubject = messages.filter(
        (m) =>
          m.type !== DiscussionEventType.SYSTEM && m.subjectId === subjectId,
      );
      const targetMessageId = hasSubject?.[0]?.id;
      const targetMessageDate = hasSubject?.[0]?.createdAt;
      // Check if the target subject is already in the store and fully in (ie check if the first message of the subject is the same as the target message)
      if (
        targetMessageId &&
        subject.firstMessageAt &&
        targetMessageDate &&
        new Date(subject.firstMessageAt).getTime() >=
          new Date(targetMessageDate).getTime() - 100
      ) {
        dispatch(
          setTargetEventId({
            targetEventId: targetMessageId,
            highlightedEventIds: hasSubject.map((m) => m.id),
          }),
        );
      } else {
        try {
          const subjectMessages = await fetchSubject(subject);
          if (subjectMessages.length) {
            queryClient.setQueryData<
              InfiniteData<AxiosResponse<DiscussionEvent[]>>
            >(['messages', discussionId], (previousData) => {
              if (!previousData?.pages?.length) {
                return previousData;
              }
              const allData = previousData.pages.flatMap((p) => p.data);
              const toAddEvents = differenceBy(subjectMessages, allData, 'id');
              if (!toAddEvents.length) {
                return previousData;
              }
              return insertMessages(previousData, toAddEvents);
            });

            const targetMessages = subjectMessages
              .reverse()
              .filter((s) => s.type !== DiscussionEventType.SYSTEM);
            dispatch(
              setTargetEventId({
                targetEventId: targetMessages[0].id,
                highlightedEventIds: targetMessages
                  .filter((s) => s.subjectId === subjectId)
                  .map((m) => m.id),
              }),
            );
          }
        } catch (_) {}
      }
    },
    [data?.pages, discussionId, dispatch, fetchSubject, queryClient],
  );
};

export const useMessageQuery = (discussion?: Discussion, socket?: Socket) => {
  const discussionId = discussion?.id;
  const user = useSelector((state) => state.user);
  const userId = user.email;
  const userName = `${user.firstName} ${user.lastName}`;
  const { data, ...rest } = useInfiniteQuery<
    RawDiscussionEvent[],
    any,
    InfiniteData<ChatData>,
    any,
    { lastEventId?: string }
  >({
    queryKey: ['messages', discussionId],
    initialPageParam: {} as { lastEventId?: string },
    queryFn: ({ pageParam }: { pageParam: { lastEventId?: string } }) => {
      return {
        method: 'get',
        url: `/api/discussion_events/${discussionId}`,
        params: {
          lastEventId: pageParam?.lastEventId,
          limit: pageLength,
        },
      };
    },
    enabled: !!discussionId,
    select: ({ pageParams, pages }) => ({
      pageParams,
      pages: pages.map(({ data: messages }) => formatMessages(messages)),
    }),
    staleTime: 0,
    gcTime: 0,
    refetchInterval: socket ? undefined : 2000,
    getNextPageParam: (lastPage) => {
      return lastPage?.data?.length >= pageLength
        ? { lastEventId: lastPage.data[lastPage.data.length - 1].id }
        : undefined;
    },
  });

  const familyId =
    discussion?.appUsers?.length &&
    discussion.appUsers[0] &&
    typeof discussion.appUsers[0] === 'object'
      ? discussion.appUsers[0].familyId
      : discussion?.appUser && typeof discussion.appUser === 'object'
        ? discussion.appUser?.familyId
        : undefined;

  const [isTypingList, isPatientTyping] = useTypingEvent(discussionId);
  const { data: users, isLoading: isUsersLoading } = useGetVaryingMany(
    'users',
    isTypingList,
  );

  const { data: families } = useGetVaryingMany('family', [familyId]);
  const familyContent = families?.[0];

  const participantCollection = useMemo((): Record<string, MayParticipant> => {
    let knownParticipants: Record<string, MayParticipant> = {
      system: { participantId: 'system', name: '', avatar: null, isMay: false },
      ...(userId
        ? {
            [userId.toString()]: {
              participantId: userId.toString(),
              name: userName!,
              avatar: null,
              isMay: true,
            },
          }
        : {}),
      ...users.reduce(
        (acc, u) => {
          if (u) {
            Object.assign(acc, {
              [u.email]: {
                participantId: u.email,
                name: u.firstName + ' ' + u.lastName,
                avatar: u.avatar,
                isMay: u.isMay,
              },
            });
          }
          return acc;
        },
        {} as Record<string, MayParticipant>,
      ),
    };
    if (!data?.pages) {
      return knownParticipants;
    }
    return {
      ...knownParticipants,
      ...data.pages.reduce(
        (acc, { participants: record }) => {
          Object.assign(acc, record);
          return acc;
        },
        {} as Record<string, MayParticipant>,
      ),
    };
  }, [data?.pages, userId, userName, users]);

  const messagesWithTyping = useMemo(() => {
    if (!data?.pages) {
      return [];
    }

    const messages = data.pages.flatMap(
      ({ messages: pageMessages }) => pageMessages,
    );

    if (isPatientTyping) {
      const familyMember = familyContent?.appUsers?.find(
        (u) => u.id === isPatientTyping,
      );
      const patientTyping: ITypingMessage = {
        messageId: 'typing-patient',
        participantId: familyMember?.email ?? 'patient@typing.fr',
        type: DiscussionEventType.TYPING,
        date: new Date().toISOString(),
        seenBy: [
          { participantId: `${userId}`, date: new Date().toISOString() },
        ],
      };
      messages.unshift(patientTyping);
    }
    if (isTypingList.length) {
      if (isUsersLoading) {
        const proTyping: ITypingMessage = {
          messageId: 'typing-patient',
          participantId: 'pro@typing.fr',
          type: DiscussionEventType.TYPING,
          date: new Date().toISOString(),
          seenBy: [
            { participantId: `${userId}`, date: new Date().toISOString() },
          ],
        };
        messages.unshift(proTyping);
      } else {
        users.forEach((u) => {
          if (u) {
            const proTyping: ITypingMessage = {
              messageId: 'typing-patient',
              participantId: u.email,
              type: DiscussionEventType.TYPING,
              date: new Date().toISOString(),
              seenBy: [
                { participantId: `${userId}`, date: new Date().toISOString() },
              ],
            };
            messages.unshift(proTyping);
          }
        });
      }
    }
    return messages;
  }, [
    data,
    userId,
    isPatientTyping,
    isTypingList.length,
    isUsersLoading,
    users,
    familyContent,
  ]);

  return {
    ...rest,
    messages: messagesWithTyping,
    participants: participantCollection,
  };
};

interface CreateDiscussionEvent {
  discussionId: number | string;
  content: string;
  type: DiscussionEventType;
  macroId?: number;
  macroSuggestionId?: number;
}
export const usePostMessage = () => {
  const queryClient = useQueryClient();
  return useMutation<DiscussionEvent, any, CreateDiscussionEvent>(
    ['postMessage'],
    (data) => ({
      method: 'post',
      url: `/api/discussion_events`,
      data,
    }),
    {
      onSuccess: (data, { discussionId }) => {
        queryClient.setQueriesData<
          InfiniteData<AxiosResponse<DiscussionEvent[]>>
        >({ queryKey: ['messages', discussionId] }, (previousMessages) =>
          addMessage(previousMessages, data),
        );
      },
    },
  );
};

export const usePostAndCloseRevive = () => {
  const { mutateAsync: post } = usePostMessage();
  const { mutateAsync: closeRevive } = useCloseHandoverRevive();
  const notify = useNotify();
  return useCallback(
    async (createDTO: CreateDiscussionEvent, revive?: HandoverRevive) => {
      try {
        const discussionEvent = await post(createDTO);
        if (revive) {
          try {
            await closeRevive(revive.id);
            logPostedAndClosedRevive(revive.id);
          } catch (_) {
            notify('revives.errorClosing', { type: 'error' });
          }
        }
        return discussionEvent;
      } catch (_) {
        notify('revives.errorSendingMessage', { type: 'error' });
      }
    },
    [closeRevive, post, notify],
  );
};

export const useDeleteMessage = (discussionId: Identifier) => {
  const queryClient = useQueryClient();
  return useMutation<DiscussionEvent, AxiosError, Identifier>(
    ['deleteDiscussionEvent'],
    (id) => ({
      method: 'delete',
      url: `/api/discussion_events/${id}`,
    }),
    {
      onSuccess(data) {
        queryClient.setQueriesData<
          InfiniteData<AxiosResponse<DiscussionEvent[]>>
        >({ queryKey: ['messages', discussionId] }, (previousMessages) =>
          updateMessage(previousMessages, data),
        );
      },
    },
  );
};
