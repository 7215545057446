import { useCallback, useEffect, useState } from 'react';
import { useAuthProvider, useGetIdentity, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { useSelector } from '../../store';

type LoginParams = {
  email?: string;
  password?: string;
  two_factor_code?: string;
  trustedDevice?: boolean;
};

type Login = (params: LoginParams) => Promise<any>;

export const useLogin = (): Login => {
  const authProvider = useAuthProvider();
  const navigate = useNavigate();

  return useCallback(
    (params: LoginParams = {}) =>
      authProvider
        ? authProvider.login(params).then((ret) => {
            navigate('/');
            return ret;
          })
        : Promise.reject(new Error('No authProvider defined')),
    [authProvider, navigate],
  );
};

interface Credentials {
  email?: string;
  password?: string;
}

interface AuthParams extends Credentials {
  two_factor_code: string;
}

export const useLoginSteps = () => {
  const [smsSent, setSmsSent] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<Credentials>({});
  const notify = useNotify();
  const login = useLogin();

  const successCallback = (auth: AuthParams) => {
    setCredentials(auth);
    setSmsSent(true);
  };

  const navigate = useNavigate();
  const { email } = useSelector((state) => state.user);
  const identity = useGetIdentity();

  useEffect(() => {
    if (email && identity?.data?.email === email) {
      navigate('/');
    }
  }, [email, identity?.data?.email, navigate]);

  // NOTE: we choose this error msg because in theory, if the person received
  // one sms, it means he entered the right username/password.
  // But the user can change his email/password after having received the sms, we don't know.
  const handleLoginFA = async (
    two_factor_code: string,
    trustedDevice = false,
  ) => {
    const auth = { ...credentials, two_factor_code, trustedDevice };
    try {
      await login(auth);
    } catch {
      notify('auth.forms.errors.invalid_credentials');
    }
  };

  return { smsSent, handleLoginFA, successCallback };
};
