import { useState } from 'react';
import { NodeProps, useReactFlow, useStore } from 'reactflow';

import { Row } from '@components/generic/Row';
import { Box, Typography } from '@mui/material';
import { Condition, Template } from '@teammay/form-core';

import { TemplateNodeType } from '../../types';
import { getPreviousQuestionsForQuestionOrTemplateNode } from '../../utils/retrieve';
import { isTemplateValid } from '../../utils/validityCheck';
import { ConditionForm } from '../conditions/ConditionEdit';
import { MenuWrapper } from '../MenuWrapper';
import { DisplayButton, DisplayEdit } from './common/Display';
import {
  ImageTemplate,
  ImageTemplateEdit,
} from '../templates/ImageTemplateEdit';
import { WarningTile } from '../WarningTile';

export function TemplateNode({
  id,
  data: { template, onChange },
}: NodeProps<TemplateNodeType['data']>) {
  const { setNodes } = useReactFlow();
  const [condition, setCondition] = useState<ConditionForm | null>(null);

  const handleChanged = (result: Partial<Template>) => {
    onChange({ updatedTemplate: result as Template });
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, template: result };
        }
        return node;
      }),
    );
  };
  const previousQuestions = useStore((store) => {
    const selfNode = store
      .getNodes()
      .find((node) => node.type === 'template' && node.id === id);
    return getPreviousQuestionsForQuestionOrTemplateNode(store, selfNode);
  });

  const invalidFields = isTemplateValid(template);
  return (
    <>
      <MenuWrapper
        content={template as ImageTemplate}
        onChange={handleChanged}
        FormComponent={ImageTemplateEdit}
        onDelete={() => {
          onChange({ deletedTemplates: template.id });
        }}
        targetObject={{ type: 'template', id }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              <WarningTile invalidFields={invalidFields} />
              <Typography
                component={'p'}
                variant="body2"
                noWrap
                sx={{ fontSize: '0.825rem', alignItems: 'center' }}
              >
                {template.title}
              </Typography>
            </Box>
          </Box>
          <Row>
            <DisplayButton
              type="template"
              content={template}
              questions={previousQuestions}
              setCondition={setCondition}
            />
          </Row>
        </Box>
      </MenuWrapper>
      <DisplayEdit
        type="template"
        content={template}
        condition={condition}
        setCondition={setCondition}
        onClick={(_condition: Condition) =>
          onChange({
            addHideCondition: {
              templateId: template.id,
              condition: _condition,
            },
          })
        }
        targetObject={{ type: 'template', id }}
      />
    </>
  );
}
