import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { Row } from '@components/generic/Row';
import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Question, QuestionType } from '@teammay/form-core';

import { CommonQuestionEdit } from './CommonQuestionEdit';
import { RadioQuestionEdit } from './RadioQuestionEdit';
import { SelectQuestionEdit } from './SelectQuestionEdit';
import { TextQuestionEdit } from './TextQuestionEdit';

export type QuestionForm = Omit<Question, 'id' | 'createdAt' | 'updatedAt'>;

export const QuestionEdit = ({
  onBlur,
  onCancel,
}: {
  onBlur: () => void;
  onCancel: () => void;
}) => {
  const translate = useTranslate();
  const { control } = useFormContext<QuestionForm>();
  const type = useWatch({ control, name: 'type' });
  return (
    <>
      <Row>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {translate('forms.questions.formTitle')}
        </Typography>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Row>
      <CommonQuestionEdit control={control} onBlur={onBlur} />
      {type === QuestionType.RADIO && <RadioQuestionEdit onBlur={onBlur} />}
      {type === QuestionType.SELECT && <SelectQuestionEdit onBlur={onBlur} />}
      {type === QuestionType.TEXT && <TextQuestionEdit onBlur={onBlur} />}
    </>
  );
};
