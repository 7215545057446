export const Logo = (
  { height = 24, width = 48 } = { height: 24, width: 48 },
) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.35248 7.94436C5.35504 6.97273 6.85889 5.79208 8.4752 5.79208C10.1606 5.76922 11.3238 6.67226 11.801 8.0848C12.8502 7.08868 14.4456 5.79208 16.2451 5.79208C18.5233 5.79208 19.8215 7.4365 19.8215 9.77495V16.7217C19.8215 17.161 20.0272 17.4157 20.5959 17.4157H20.8241V17.7635H15.6297V17.4157H15.7888C16.3591 17.4157 16.5857 17.1381 16.5857 16.6972V9.75209C16.5857 8.33955 15.8788 7.48223 14.5356 7.48223C13.5105 7.48223 12.6445 7.85292 11.9151 8.431C12.0292 8.82455 12.0742 9.28832 12.0742 9.77495V16.7217C12.0742 17.161 12.2798 17.4157 12.8486 17.4157H13.0992V17.7635H7.83575V17.4157H8.06389C8.65676 17.4157 8.83831 17.161 8.83831 16.7217V9.77495C8.83831 8.33955 8.15547 7.48223 6.7416 7.48223C5.96718 7.48223 5.12368 7.69125 4.34926 8.29219V16.6988C4.34926 17.1381 4.55492 17.4173 5.14617 17.4173H5.30524V17.7652H0.0915804V17.4173H0.342221C0.91259 17.4173 1.11664 17.1626 1.11664 16.7233V8.19911H0V7.96723C1.11664 7.62103 2.87113 6.74085 4.07774 5.72186H4.35087V7.94436H4.35248Z"
      fill="#073735"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9664 23.6427C32.4414 23.3023 32.0933 22.7052 32.0933 22.0253C32.0933 20.9665 32.9378 20.1082 33.9795 20.1082C35.0212 20.1082 35.8657 20.9665 35.8657 22.0253C35.8657 22.118 35.8593 22.2092 35.8468 22.2984C36.4502 21.8239 37.0449 20.6547 37.9883 18.4361L38.1023 18.1813L32.9979 7.4591C32.6782 6.78794 32.4276 6.53319 31.8813 6.53319H31.7672V6.18536H37.1447V6.53319H36.8025C36.1422 6.53319 36.1422 6.92674 36.3703 7.41337L39.6287 14.6149L42.3632 7.43624C42.5914 6.85652 42.4773 6.53319 41.9535 6.53319H41.5663V6.18536H44.0502V6.53319H44.0052C43.4124 6.53319 43.2308 6.69486 42.9111 7.4591L38.5827 18.2499C36.6226 23.1358 35.575 23.9474 34.0037 23.9474C33.574 23.9474 33.2188 23.8336 32.9664 23.6427Z"
      fill="#073735"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1972 8.11137C24.592 7.11493 25.2377 6.60203 26.388 6.60203H26.3912C27.4628 6.60203 27.895 7.18011 27.895 8.40812V11.0944L26.6193 11.6741C23.5201 13.0866 21.8796 14.1758 21.8796 16.1207C21.8796 17.2785 22.7006 18.0656 24.272 18.0656C25.5011 18.0656 26.5727 17.5561 27.9625 16.3526C28.1441 17.5333 28.896 18.0656 30.1042 18.0656C31.2192 18.0656 32.1318 17.4859 32.6781 16.8376L32.519 16.6286C32.2909 16.8131 32.0177 16.8833 31.7671 16.8833C31.2658 16.8833 31.1293 16.5812 31.1293 15.7713V9.19522C31.1293 7.01844 29.8295 5.90637 27.5287 5.90637C24.9794 5.90637 22.5469 8.02574 22.1947 9.37017C22.124 9.57166 22.0854 9.78873 22.0854 10.0149C22.0854 11.0737 22.9299 11.9321 23.9716 11.9321C25.0134 11.9321 25.8579 11.0737 25.8579 10.0149C25.8579 9.03371 25.1326 8.22466 24.1972 8.11137ZM27.9127 16.0032C27.3439 16.4196 26.7511 16.6286 26.2273 16.6286C25.4513 16.6286 25.0191 16.1648 25.0191 15.1001C25.0191 13.8492 25.5429 12.9691 27.548 11.6496L27.8902 11.4177V15.6096C27.8902 15.7484 27.8902 15.8872 27.9127 16.0032Z"
      fill="#073735"
    />
    <path
      d="M45.9915 8.09783C47.0594 8.09783 47.9251 8.93048 47.9251 9.95761C47.9251 10.9847 47.0594 11.8174 45.9915 11.8174C44.9237 11.8174 44.058 10.9847 44.058 9.95761C44.058 8.93048 44.9237 8.09783 45.9915 8.09783Z"
      fill="#073735"
      fillOpacity="0.5"
    />
    <path
      d="M45.9442 13.9762C46.986 13.9762 47.8305 14.8345 47.8305 15.8933C47.8305 16.9521 46.986 17.8104 45.9442 17.8104C44.9025 17.8104 44.058 16.9521 44.058 15.8933C44.058 14.8345 44.9025 13.9762 45.9442 13.9762Z"
      fill="#073735"
    />
  </svg>
);
