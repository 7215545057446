import { BooleanField, Datagrid, TextField, useTranslate } from 'react-admin';

import { List } from '../../components/generic/List';

export const CompanyPlanList = (props) => {
  const translate = useTranslate();

  return (
    <List {...props} title={translate('companyPlan.companyPlan')}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="alias" label={translate('companyPlan.alias')} />
        <TextField
          source="description"
          label={translate('common.description')}
        />
        <BooleanField source="active" label={translate('common.active')} />
      </Datagrid>
    </List>
  );
};
