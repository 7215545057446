import { useState } from 'react';
import { NodeProps, useReactFlow, useStore } from 'reactflow';

import { Row } from '@components/generic/Row';
import { Box, Typography } from '@mui/material';
import { Condition, Question, ScoringRule } from '@teammay/form-core';

import { QuestionNodeType } from '../../types';
import { isQuestionValid } from '../../utils/validityCheck';
import { ConditionForm } from '../conditions/ConditionEdit';
import { MenuWrapper } from '../MenuWrapper';
import { DisplayButton, DisplayEdit } from './common/Display';
import { ScoringButton, ScoringRuleEdit } from './common/Scoring';
import { getPreviousQuestionsForQuestionOrTemplateNode } from '../../utils/retrieve';
import { QuestionEdit } from '../questions/QuestionEdit';
import { WarningTile } from '../WarningTile';

export function QuestionNode({
  id,
  data: { question, onChange },
}: NodeProps<QuestionNodeType['data']>) {
  const { setNodes } = useReactFlow();

  const previousQuestions = useStore((store) => {
    const selfNode = store
      .getNodes()
      .find((node) => node.type === 'question' && node.id === id);
    return getPreviousQuestionsForQuestionOrTemplateNode(store, selfNode);
  });

  const handleChanged = (result: Partial<Question>) => {
    onChange({ updatedQuestion: result as Question });
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, question: result };
        }
        return node;
      }),
    );
  };

  const [condition, setCondition] = useState<ConditionForm | null>(null);
  const [scoringRule, setRule] = useState<ScoringRule[] | null>(null);

  const invalidFields = isQuestionValid(question);

  return (
    <>
      <MenuWrapper
        content={question}
        onChange={handleChanged}
        FormComponent={QuestionEdit}
        onDelete={() => {
          onChange({ deletedQuestion: question.id });
        }}
        targetObject={{ type: 'question', id }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              <WarningTile invalidFields={invalidFields} />
              <Typography
                component={'p'}
                variant="body2"
                noWrap
                sx={{ fontSize: '0.825rem' }}
              >
                {question.title}
              </Typography>
            </Box>
            <Typography
              component={'p'}
              variant="caption"
              color="textSecondary"
              noWrap
              sx={{ fontSize: '0.625rem' }}
            >
              {question.description}
            </Typography>
          </Box>
          <Row>
            <DisplayButton
              type={'question'}
              content={question}
              questions={previousQuestions}
              setCondition={setCondition}
            />
            <ScoringButton
              setRule={setRule}
              // @ts-ignore
              question={question}
            />
          </Row>
        </Box>
      </MenuWrapper>
      <DisplayEdit
        type={'question'}
        content={question}
        condition={condition}
        setCondition={setCondition}
        onClick={(_condition: Condition) =>
          onChange({
            addHideCondition: {
              questionId: question.id,
              condition: _condition,
            },
          })
        }
        targetObject={{ type: 'question', id }}
      />
      <ScoringRuleEdit
        // @ts-ignore
        question={question}
        scoringRule={scoringRule}
        setRule={setRule}
        onClick={(questionWithRules) =>
          onChange({
            addScore: {
              questionId: question.id,
              scoringRules: questionWithRules.scoringRules,
            },
          })
        }
        targetObject={{ type: 'question', id }}
      />
    </>
  );
}
