import { Create, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { PricingProCustomForm } from './form';

export const PricingProCustomCreate = () => {
  const location = useLocation();
  const translate = useTranslate();

  return (
    <Create
      resource="pricing-pro-custom"
      title={translate('pricingProCustom.add')}
      redirect={location?.state?.redirect || '/pricing-pro'}
    >
      <PricingProCustomForm />
    </Create>
  );
};
