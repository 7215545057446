import { i18nProvider } from '../../App';

export const messages = () => ({
  date: i18nProvider.translate('calendars.date'),
  time: i18nProvider.translate('calendars.hour'),
  event: i18nProvider.translate('calendars.slot'),
  allDay: i18nProvider.translate('calendars.allDay'),
  week: i18nProvider.translate('calendars.weekly'),
  day: i18nProvider.translate('calendars.daily'),
  month: i18nProvider.translate('calendars.monthly'),
  previous: i18nProvider.translate('calendars.previous'),
  next: i18nProvider.translate('calendars.next'),
  yesterday: i18nProvider.translate('calendars.yesterday'),
  tomorrow: i18nProvider.translate('calendars.tomorrow'),
  today: i18nProvider.translate('calendars.today'),
  agenda: i18nProvider.translate('calendars.list'),
  noEventsInRange: i18nProvider.translate('calendars.noEvents'),
  showMore: (total: number) =>
    i18nProvider.translate('calendars.total', { smart_count: total }),
});
