import { memo, useMemo } from 'react';

import { WeeklyTipList } from '@components/WeeklyTip/WeeklyTipList';
import { Box } from '@mui/material';

import { ContentSuggestionPaper } from './contentSuggestionPaper';
import { ContentList, ContentType } from './contentTextList';
import {
  contentTypeToDiscussionEventType,
  useContentType,
  useItemsWithSeen,
  useLibrary,
  useLibraryMode,
  useResultsWithSeen,
  useSentContent,
} from './hook';
import {
  ContentFilters,
  ContentTypeSelector,
  Header,
  Main,
  ModeSelector,
  SearchField,
  libraryMode,
} from './layout';
import { DailyTipList } from '../../components/DailyTip/DailyTipList';
import { FolderList } from '../../components/Folder/FolderList';
import { GuidesList } from '../../components/Guide/GuidesList';
import { MacroList } from '../../components/Macro/MacroList';
import { MasterclassList } from '../../components/Masterclass/MasterclassList';
import { PostsList } from '../../components/Post/PostsList';
import { LatestContentCard } from '../../pages/dashboard/latestContentCard';
import { DiscussionEventType, LookupDTO } from '../../types/discussionEvent';

const LibraryListRaw = ({
  onSend,
  onCopy,
  onSuggestionSend,
  onSuggestionCopy,
  discussionId,
  subjectId,
  onClose,
}: {
  onSend?: (
    { type, content }: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
  ) => void;
  onCopy?: (val: string, macroId: number, suggestionId: number) => void;
  onSuggestionSend?: (input: {
    type: DiscussionEventType;
    content: string;
  }) => void;
  onSuggestionCopy?: (text: string) => void;
  discussionId?: number;
  subjectId?: number;
  onClose?: () => void;
}) => {
  const { selectedContentType, onTypeChange, setContentType } =
    useContentType();
  const { mode, onModeChange } = useLibraryMode(setContentType);

  const {
    ageLevelsList,
    categoriesList,
    isLoading,
    isLoadingFilters,
    pregnancy,
    selectedAgeLevel,
    selectedCategory,
    setAgeLevel,
    setCategory,
    searchedText,
    displayedText,
    setSearchText,
    resetSearch,
    searchResults,
    explorer,
    displayFilters,
    setDisplayFilters,
  } = useLibrary(selectedContentType, mode);

  const sentContent = useSentContent(discussionId);
  const sentContentPerType = useMemo(() => {
    return sentContent.reduce(
      (acc, content) => {
        acc[content.type] ??= [];
        acc[content.type].push(content);
        return acc;
      },
      {} as Record<DiscussionEventType, LookupDTO[]>,
    );
  }, [sentContent]);

  const itemsWithSeen = useItemsWithSeen(
    explorer.items,
    sentContentPerType[contentTypeToDiscussionEventType(selectedContentType)],
  );

  const {
    macroList,
    guideList,
    postList,
    masterclassList,
    folderList,
    dailyTipList,
    weeklyTipList,
  } = useResultsWithSeen(searchResults, sentContentPerType);

  const hasSearch =
    searchedText.length > 0 || selectedAgeLevel || selectedCategory;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      }}
    >
      <Main>
        <Header>
          <SearchField
            search={displayedText}
            onSearchChange={(ev) => setSearchText(ev.target.value)}
            resetSearch={resetSearch}
            isLoading={isLoading}
          />
          <ModeSelector mode={mode} onModeChange={onModeChange} />
        </Header>
        <ContentFilters
          isLoading={isLoadingFilters}
          pregnancy={pregnancy}
          setAgeLevel={setAgeLevel}
          ageLevelsList={ageLevelsList}
          selectedAgeLevel={selectedAgeLevel}
          categoriesList={categoriesList}
          selectedCategory={selectedCategory}
          setCategory={setCategory}
          displayFilters={displayFilters}
          setDisplayFilters={setDisplayFilters}
        />

        {mode === libraryMode.SEARCH ? (
          hasSearch ? (
            <Box
              sx={{
                paddingLeft: '1rem',
                height: `calc(100% - ${displayFilters ? '138' : '82'}px)`,
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <MacroList list={macroList} onCopy={onCopy} />
              <GuidesList list={guideList} onSend={onSend} />
              <PostsList list={postList} onSend={onSend} />
              <MasterclassList list={masterclassList} onSend={onSend} />
              <FolderList list={folderList} />
              <DailyTipList list={dailyTipList} onSend={onSend} />
              <WeeklyTipList list={weeklyTipList} onSend={onSend} />
            </Box>
          ) : subjectId && discussionId ? (
            <ContentSuggestionPaper
              subjectId={subjectId}
              discussionId={discussionId}
              onContentSend={onSuggestionSend}
              onCopyContent={onSuggestionCopy}
              onClose={onClose}
            />
          ) : (
            <LatestContentCard withinLibrary />
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              height: `calc(100% - ${displayFilters ? '138' : '82'}px)`,
            }}
          >
            <ContentTypeSelector
              selectedContentType={selectedContentType}
              onTypeChange={onTypeChange}
            />
            <ContentList
              items={itemsWithSeen}
              type={selectedContentType}
              loadNextPage={explorer.fetchNextPage}
              hasNextPage={explorer.hasNextPage}
              isNextPageLoading={explorer.isFetchingNextPage}
              onSend={
                [
                  ContentType.DAILY_TIPS,
                  ContentType.GUIDES,
                  ContentType.POSTS,
                  ContentType.MASTERCLASSES,
                  ContentType.FOLDER,
                  ContentType.WEEKLY_TIPS,
                ].includes(selectedContentType)
                  ? onSend
                  : undefined
              }
              onCopy={
                selectedContentType === ContentType.MACRO ? onCopy : undefined
              }
            />
          </Box>
        )}
      </Main>
    </Box>
  );
};

export const LibraryList = memo(LibraryListRaw);
