import { useState } from 'react';
import { ReferenceField, useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import {
  Consultation,
  ConsultationWithRelationIds,
} from '@boTypes/consultation';
import { User } from '@boTypes/user';
import { AvatarField } from '@components/fields';
import { AutocompleteStaffSelectInput } from '@components/forms/staffSelectInput';
import { DialogTitle } from '@components/generic/Dialog';
import { useMutation, useQuery } from '@hooks/queryWrappers';
import { useAllStaffUsersSorted } from '@hooks/useAllStaffUsers';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';

export const useStaffUsers = () => {
  return useQuery<User[], any, User[]>(['users'], () => ({
    method: 'GET',
    url: '/api/users',
    params: { filters: JSON.stringify({ active: true }) },
  }));
};

export const useConsultationStaffUserUpdate = () => {
  return useMutation<
    Consultation,
    any,
    { consultationId: number; staffUserId: number }
  >(
    ['consultationStaffUserUpdate'],
    ({
      consultationId,
      staffUserId,
    }: {
      consultationId: number;
      staffUserId: number;
    }) => ({
      method: 'PUT',
      url: `/api/consultations/${consultationId}/changeStaffUser`,
      data: { staffUserId },
    }),
  );
};

export const OtherActionsConsultation = ({
  consultation,
}: {
  consultation: ConsultationWithRelationIds;
}) => {
  const translate = useTranslate();
  const [displayAttributionDialog, setDisplayAttributionDialog] =
    useState(false);
  const notify = useNotify();

  const { mutateAsync: consultationStaffUserUpdate } =
    useConsultationStaffUserUpdate();

  const { data: staffUsers, isLoading: isLoadingStaff } =
    useAllStaffUsersSorted();

  const onSubmit = async () => {
    // Get the staff user id from the form
    const values = getValues();
    const staffUserId = values.staffUserId;
    await consultationStaffUserUpdate({
      consultationId: consultation.id,
      staffUserId,
    });
    setDisplayAttributionDialog(false);
    notify('consultations.attributionSuccess', { type: 'success' });
  };

  const { control, getValues, formState } = useForm<{ staffUserId: number }>({
    defaultValues: { staffUserId: consultation.staffUserId },
  });

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={() => {
          setDisplayAttributionDialog(true);
        }}
        startIcon={
          <ReferenceField
            source="staffUserId"
            record={consultation}
            reference="users"
            link={false}
          >
            <AvatarField />
          </ReferenceField>
        }
      >
        {translate('consultations.attribution')}
      </Button>
      <Dialog
        open={displayAttributionDialog}
        onClose={() => setDisplayAttributionDialog(false)}
      >
        <DialogTitle onClose={() => setDisplayAttributionDialog(false)}>
          {translate('consultations.attribution')}
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>
              {translate('consultations.attributionWarningTitle')}
            </AlertTitle>
            {translate('consultations.attributionWarningText')}
          </Alert>
          <AutocompleteStaffSelectInput
            staffUsers={staffUsers}
            fullWidth
            isLoadingStaff={isLoadingStaff}
            label={translate('consultations.staffUser')}
            control={control}
            name="staffUserId"
          />
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSubmit()}
            disabled={formState.isSubmitting}
          >
            {translate('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
